import React from 'react'
import InputTextWithLabel from '@components/display/InputTextWithLabel'

function Details({ details = {}, setDetails }) {
  const { name, address } = details
  return (
    <div className="flex flex-column">
      <InputTextWithLabel
        label="Name"
        value={name || ''}
        inputId="name"
        name="name"
        onChange={({ target: { value } }) => setDetails({ name: value })}
      />
      <InputTextWithLabel
        label="Address"
        value={address || ''}
        inputId="address"
        name="address"
        onChange={({ target: { value } }) => setDetails({ address: value })}
      />
    </div>
  )
}

export default Details
