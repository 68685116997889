import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { DetailsForm } from '@components/sites/SiteForm'
import FormWrapper from '@components/display/Form/FormWrapper'
import { useCurrentOrganization } from '@components/App'
import { useCreateSiteMutation } from '@services/hooks/sitesHooks'

function CreateSite({ useSiteHeader }) {
  const statusMessage = useRef(null)
  const navigate = useNavigate()
  const { setHeader } = useSiteHeader()
  const organization = useCurrentOrganization()

  const [details, setDetails] = useState({
    name: '',
    address: '',
  })

  const {
    mutateAsync: createSite,
    isLoading: createIsLoading,
  } = useCreateSiteMutation(statusMessage)

  const handleCreateSite = () => {
    createSite({ site: { ...details, organizationId: organization.id } }, {
      onSuccess: (data) => {
        navigate(`/admin/sites/${data.id}`)
      },
    })
  }

  const pageTitle = {
    label: (
      <span>
        <i className="text-xl pi pi-users mr-2" />
        Sites
      </span>
    ),
    linkTo: '/admin/sites',
  }

  const breadcrumbs = [
    {
      label: 'Create',
      template: <span>Create</span>,
    },
  ]

  useEffect(() => {
    setHeader({ title: pageTitle, breadcrumbs, primaryAction: null })
  }, [])

  return (
    <div id="create-site" className="flex flex-column">
      <div className="flex flex-row flex-wrap">
        <Toast ref={statusMessage} />
        <div className="col-6">
          <FormWrapper title="Create Site" fullHeight>
            <DetailsForm
              details={details}
              setDetails={(data) => setDetails({ ...details, ...data })}
            />
            <Button
              label="Save"
              className="p-button-sm m-2"
              loading={createIsLoading}
              onClick={handleCreateSite}
            />
          </FormWrapper>
        </div>
      </div>
    </div>
  )
}

export default CreateSite
