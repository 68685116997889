import {
  map, reduce, get, groupBy, sortBy,
} from 'lodash'

export function calculateServicesTimeSpent(sessions) {
  const totals = reduce(sessions, (result, session) => {
    const newResult = result
    if (session.startAt) {
      const startAt = new Date(session.startAt)
      const endAt = new Date(session.endAt)
      const date = Math.abs(endAt - startAt)

      const minutes = Math.floor(date / 60000)
      const seconds = Math.floor(((date / 1000))) - (minutes * 60)
      newResult.minutes += minutes
      newResult.seconds += seconds
    } else {
      newResult.minutes += Math.floor((session.duration || 0) / 60)
    }

    return newResult
  }, { minutes: 0, seconds: 0 })

  return totals
}

export function calculateChargeCodeDisplay({ thresholds, complex }) {
  const chargeGroups = groupBy(thresholds, 'chargeCode')

  const result = map(chargeGroups, (group) => {
    let text = 'until billable'
    let color = '#faedc4' // yellow
    let billable = false
    let billableLimitReached = false
    let nextThreshold = 0
    let remainingMinutes = 0

    return group.reduce((_, {
      chargeCode, threshold, remainingMinutesToNextThreshold, order,
    }, index) => {
      if (remainingMinutesToNextThreshold === 0) {
        billable = true
        color = '#caf1d8' // green
        nextThreshold = get(thresholds[index + 1], 'threshold', threshold)
        remainingMinutes = get(thresholds[index + 1], 'remainingMinutesToNextThreshold', remainingMinutesToNextThreshold)
      } else {
        nextThreshold = nextThreshold || threshold
        remainingMinutes = remainingMinutes || remainingMinutesToNextThreshold
      }

      if (billable && (index + 1) === group.length) {
        billableLimitReached = !complex && true
        nextThreshold = null
      }

      if (billable && !billableLimitReached) {
        text = 'until next billable'
      } else if (billable && billableLimitReached) {
        text = 'Billable limit reached'
      }

      return {
        chargeCode,
        text,
        color,
        billable,
        billableLimitReached,
        nextThreshold,
        remaining: remainingMinutes,
        order,
      }
    }, {})
  })

  return sortBy(result, 'order')
}
