import React from 'react'
import { Tag } from 'primereact/tag'

function DoseTag({ color, text }) {
  if (!color || !text) {
    return null
  }

  return (
    <Tag className="w-fit" style={{ background: color }}>
      <span className="text-xs font-normal">{text}</span>
    </Tag>
  )
}

export default DoseTag
