import React from 'react'

function StatusTags({
  prnStatusTags, handleOnClickStatus, openPRNDialog, rowData, currentCellDate, isAdmin,
}) {
  return (
    <div
      className="flex flex-column justify-content-center align-items-center gap-1 w-full h-full cursor-pointer"
      onClick={handleOnClickStatus}
    >
      {prnStatusTags}
      <span className="flex flex-row w-full justify-content-center align-content-center cursor-pointer">
        {isAdmin && (
        <i
          className="pi pi-plus-circle text-400"
          style={{ fontSize: '1rem' }}
          onClick={() => openPRNDialog(rowData, currentCellDate)}
        />
        )}
      </span>
    </div>
  )
}

export default StatusTags
