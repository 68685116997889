import React, { useEffect } from 'react'
import { Badge } from 'primereact/badge'
import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import { Card } from 'primereact/card'
import {
  Link, NavLink, Route, Routes, useNavigate, useParams, useLocation,
} from 'react-router-dom'
import { classNames } from 'primereact/utils'
import ScheduledDoses from './ScheduledDoses/ScheduledDoses'
import DosesList from './DosesList'
import UpsertClientDoses from './UpsertClientDoses'
import ClientDose from './ClientDose/ClientDose'
import './client-doses-styles.scss'
import Exports from '../../components/patients/Exports'

function Menu() {
  const location = useLocation()

  const viewLinks = [
    {
      label: 'Schedule',
      linkTo: 'schedule',
    },
    {
      label: 'PRN',
      linkTo: 'prn',
    },
    {
      label: 'All',
      linkTo: 'all',
    },
  ]

  return (
    <div className="flex flex-row justify-space-between align-items-center pl-2 pr-4 py-3">
      <div className="flex flex-row align-items-center">
        { viewLinks.map((link) => (
          <NavLink to={link.linkTo} key={link.linkTo}>
            {
              ({ isActive }) => (
                <Button className={classNames('flex flex-row align-items-center p-button-sm p-button-outlined', { 'p-button-text': !isActive })}>
                  {!!link.badge
                    && (
                      <Badge severity={link.badge.severity} value={link.badge.value} />
                    )}
                  <span className={classNames({ 'text-600': !isActive, 'ml-2': !!link.badge })}>
                    {link.label}
                  </span>
                </Button>
              )
            }
          </NavLink>
        ))}
      </div>
      { !location.pathname.includes('needs-review') && (
        <Link to="create">
          <Button className="p-button-sm" label="Create Dose" />
        </Link>
      )}
    </div>
  )
}

function IndexPage({ clientId, maxDataViewHeight }) {
  const wrapper = (component) => (
    <div className="p-4">
      {component}
    </div>
  )
  return (
    <div className="col-12 client-doses">
      <Card className="h-full client-dose-card-body">
        <div className="flex flex-column text-base text-900">
          <Menu />
          <Divider className="my-0" />
          <Routes>
            <Route
              path="schedule"
              element={
                wrapper(
                  <ScheduledDoses clientId={clientId} maxDataViewHeight={maxDataViewHeight} />,
                )
              }
            />
            <Route
              path="prn"
              element={
                wrapper(<DosesList clientId={clientId} filters={[{ name: 'prn', value: true }]} />)
              }
            />
            <Route
              path="all"
              element={wrapper(<DosesList clientId={clientId} />)}
            />
          </Routes>
        </div>
      </Card>
    </div>
  )
}

function CreateDose({ clientId, handleSetHeader, layoutRef }) {
  return (
    <div className="col-12">
      <UpsertClientDoses
        clientId={clientId}
        handleSetHeader={handleSetHeader}
        layoutRef={layoutRef}
      />
    </div>
  )
}

function Redirect({ to }) {
  const navigate = useNavigate()
  useEffect(() => navigate(to), [])
  return null
}

function ClientDoses({
  patient, handleSetHeader, maxDataViewHeight, layoutRef,
}) {
  const { patientId: clientId } = useParams()

  const primaryActionSetup = (action) => {
    handleSetHeader({ primaryAction: action })
  }

  return (
    <>
      <Exports
        patientId={clientId}
        exportsMenuSetupCallBack={primaryActionSetup}
      />
      <Routes>
        <Route index element={<Redirect to="schedule" />} />
        <Route
          path="create"
          element={(
            <CreateDose
              clientId={clientId}
              handleSetHeader={handleSetHeader}
              layoutRef={layoutRef}
            />
        )}
        />
        <Route
          path="manage/:doseId"
          element={(
            <ClientDose
              clientId={clientId}
              patient={patient}
              handleSetHeader={handleSetHeader}
            />
        )}
        />
        <Route
          path="/*"
          element={(
            <IndexPage
              clientId={clientId}
              patient={patient}
              maxDataViewHeight={maxDataViewHeight}
              handleSetHeader={handleSetHeader}
            />
        )}
        />
      </Routes>
    </>
  )
}

export default ClientDoses
