import React from 'react'
import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useCurrentOrganization } from '@components/App'
import { useDoseFills } from '@hooks/doseFills'
import { useReportSentryError } from '@hooks/useReportSentryError'
import { momentFormats } from '@services/utils/moment'

function timeTemplate(field, timezone = 'America/New_York', placeholder = '') {
  return function tableCell(rowData) {
    const value = rowData[field]
    return (
      <div>
        { value ? moment(value).tz(timezone).format(momentFormats.datetime_12) : placeholder }
      </div>
    )
  }
}

function DoseFillsTable({ model, id, status }) {
  const { timezone } = useCurrentOrganization()
  const { data: doseFills, error, isLoading } = useDoseFills(model, id, status)
  const reportSentryError = useReportSentryError([model, id, status])

  if (error) {
    reportSentryError(`Error getting ${status} dose fills for ${model} ${id}: ${JSON.stringify(error)}`)
    return (<div>Error getting dose fills.</div>)
  }

  return (
    <DataTable value={doseFills} loading={isLoading} dataKey="id" removableSort>
      <Column
        field="id"
        header="ID"
      />
      <Column
        field="status"
        header="Status"
      />
      <Column
        field="dosesInFill"
        header="Doses in Fill"
      />
      <Column
        field="fillsLeft"
        header="Fills Left"
      />
      <Column
        body={timeTemplate('pharmacyDispensedAt', timezone)}
        field="pharmacyDispensedAt"
        header="Pharmacy Dispensed"
        sortable
      />
      <Column
        body={timeTemplate('agencyLoadedAt', timezone)}
        field="agencyLoadedAt"
        header="Agency Loaded"
        sortable
      />
      <Column
        body={timeTemplate('finishedAt', timezone)}
        field="finishedAt"
        header="Finished"
        sortable
      />
      <Column
        body={timeTemplate('expiresAt', timezone)}
        field="expiresAt"
        header="Expires"
        sortable
      />
    </DataTable>
  )
}

export default DoseFillsTable
