import React from 'react'
import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'
import { Button } from 'primereact/button'
import ChargeCodes from './ChargeCodes'
import { useCurrentOrganization } from '../App'

function PatientDashboardCard({
  setNewPationSession, patient, billableSessions, newSession, currentUserCCMEligible,
}) {
  const organization = useCurrentOrganization()
  const { standardCharges = {}, complexCharges = {} } = billableSessions

  return (
    <div className="col-12 md:col-4 fadein">
      <Card>
        <div className="flex flex-row flex-wrap align-items-center justify-content-between pb-1">
          <a href={`/admin/patients/${patient.id}/ccm_sessions`}>
            <div className="font-medium text-base text-primary">
              {`${patient.firstName} ${patient.lastName}`}
            </div>
          </a>
          {!newSession && currentUserCCMEligible
            && (
            <a href={`/admin/patients/${patient.id}/ccm_sessions/new`}>
              <Button
                className="p-button-sm p-button-outlined px-0 py-1"
                tooltip="New Session"
                tooltipOptions={{ position: 'top' }}
                icon="pi pi-stopwatch"
              />
            </a>
            )}
          {
            newSession
            && (
            <Button
              label="Select"
              className="p-button-sm px-3 py-1"
              onClick={setNewPationSession}
            />
            )
          }
        </div>
        <Divider className="my-2" />
        <ul className="list-none p-0 m-0 flex flex-column gap-2 pt-2">
          <ChargeCodes charges={standardCharges} />
          {organization.complexCcmEnabled
            && <ChargeCodes complex charges={complexCharges} />}
        </ul>
      </Card>
    </div>
  )
}

export default PatientDashboardCard
