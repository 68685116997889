import React from 'react'
import { map } from 'lodash'
import DoseTag from '@components/clientDoses/Header/DoseTag'
import SigDetails from './SigDetails'

function Title({ prescription, pharmacyMedicine }) {
  const { prescriptionNumber } = prescription
  const medicineText = pharmacyMedicine?.medicineText ?? 'Unknown Medicine'
  const title = `Rx${prescriptionNumber} - ${medicineText}`

  return (<span>{title}</span>)
}

function DoseDetails({
  doseDirectives,
  pharmacyMedicine,
  orderType,
  prescription,
  sigs,
}) {
  return (
    <div className="flex flex-column col-12 p-4 gap-2">
      <div className="flex flex-row gap-2">
        <div className="flex flex-row gap-2">
          {map(doseDirectives, (directive, index) => (
            <DoseTag key={index} color={directive.color} text={directive.text.tag} />
          ))}
          {orderType && (
            // TODO: Adopt a pattern for color configuration
            <DoseTag color="var(--blue-400)" text={orderType} />
          )}
        </div>
        <Title prescription={prescription} pharmacyMedicine={pharmacyMedicine} />
      </div>
      {map(sigs, (sig, index) => (
        <SigDetails
          key={index}
          pharmacyMedicine={pharmacyMedicine}
          administrationAmount={sig.administrationAmount}
          startAtWall={sig.startAtWall}
          endAtWall={sig.endAtWall}
          units={sig.units}
          pharmacyInstructions={sig.pharmacyInstructions}
        />
      ))}
    </div>
  )
}

export default DoseDetails
