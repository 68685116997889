import React from 'react'
import { Button } from 'primereact/button'
import DoseConsumptionsHistory from './DoseConsumptionsHistory'

const getStatusInfo = (status) => {
  if (!status) return {}
  const isConfirmed = status === 'confirmed'
  return {
    label: isConfirmed ? 'Confirmed' : 'Refused',
    iconClass: isConfirmed ? 'p-bg-circle-given' : 'p-bg-circle-refusal',
  }
}

function StatusHeader({ item, handleEdit }) {
  const { status, statusIcon, statusLabel } = item
  const { label, iconClass } = getStatusInfo(status)

  return (
    <div className="flex flex-row align-items-center">
      <div className="flex flex-row gap-2">
        {statusIcon || <div><i className={`pi pi-circle-fill ${iconClass}`} /></div>}
        <div className="text-base font-medium line-height-2">{statusLabel || label}</div>
      </div>
      { handleEdit && (
        <Button label="Edit" className="p-button-sm p-button-text text-xs p-0 ml-auto" onClick={(e) => handleEdit(e, item)} />
      )}
    </div>
  )
}

function StatusDetails({
  expectedAt, confirmedBy, confirmedAt, reason, status, isPrn, prnReason,
}) {
  const missedOrRefused = status === 'missed' || status === 'refused'
  const hasReasons = isPrn || missedOrRefused
  return (
    <div className="flex flex-column w-full text-left">
      <div className="flex flex-column mt-2 row-gap-2">
        { expectedAt && (
        <div className="flex flex-row gap-1">
          <span className="text-xs">Expected:</span>
          <span className="text-xs font-medium">{expectedAt}</span>
        </div>
        )}
        {confirmedAt && (
        <div className="flex flex-row gap-1">
          <div className="flex flex-row gap-1">
            <span className="text-xs">Confirmed:</span>
            <span className="text-xs font-medium">{confirmedAt}</span>
            <span className="text-xs">by</span>
            <span className="text-xs font-medium">{confirmedBy}</span>
          </div>
        </div>
        )}
      </div>
      {!hasReasons ? <div className="mt-2" /> : (
        <div className="flex flex-column row-gap-2 mt-3 mb-2">
          {isPrn && (
            <div className="flex flex-row gap-1">
              <span className="text-xs">PRN Reason: </span>
              <span className="text-xs font-medium">{prnReason || 'Unspecified'}</span>
            </div>
          )}
          {missedOrRefused && (
            <div className="flex flex-row gap-1">
              <span className="text-xs">Reason: </span>
              <span className="text-xs font-medium">{reason || 'Unspecified'}</span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
function StatusOverlayContent({ timezone, item, handleEdit }) {
  return (
    <div className="flex flex-column p-1">
      <StatusHeader item={item} handleEdit={handleEdit} />
      <StatusDetails {...item} />
      <DoseConsumptionsHistory
        timezone={timezone}
        historyData={item.consumptionHistory || []}
      />
    </div>
  )
}

export default React.memo(StatusOverlayContent)
