import React from 'react'
import { useParams } from 'react-router-dom'
import { Button } from 'primereact/button'
import TimeSelectionDialog from '@components/clientDoses/Schedule/TimeSelectionDialog'

function AddTimeEditor({ handleCreateSchedule }) {
  const { patientId } = useParams()

  const controls = ({ handleAddTime }) => (
    <Button
      label="Add Time"
      icon="fa-solid fa-calendar-plus"
      className="p-button-sm p-button-warning p-button-text"
      onClick={handleAddTime}
    />
  )

  return (
    <TimeSelectionDialog
      clientId={patientId}
      dose={{ prn: false }}
      render={controls}
      handleCreateSchedule={handleCreateSchedule}
    />
  )
}

export default AddTimeEditor
