import React, { useEffect, useState } from 'react'
import { map } from 'lodash'
import { Card } from 'primereact/card'
import { classNames } from 'primereact/utils'
import { fuzzySearch } from '@services/utils/search'
import { useMedicalServices } from '../../features/CCM/ccmHooks'

function SkipCard({ setService }) {
  const [highlight, setHighlight] = useState(false)

  const handleSelection = () => {
    setHighlight(true)
    setTimeout(() => {
      setService(null)
      setHighlight(false)
    }, 300)
  }

  return (
    <div className="col-12 md:col-4 xl:col-3">
      <Card className={classNames('w-full h-full provider', { highlight })} onClick={handleSelection}>
        <div className="flex flex-row justify-space-between">
          <div className="flex flex-column gap-3">
            <div className="text-sm text-900">
              Skip
            </div>
            <div className="text-xs">
              <span className="text-700">*Service can be added later</span>
            </div>
          </div>
          <i className="pi pi-forward" />
        </div>
      </Card>
    </div>
  )
}

function Service({
  updateSessionLoading, selected, id, description, setService,
}) {
  const [highlight, setHighlight] = useState(selected)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(updateSessionLoading)
  }, [updateSessionLoading])

  const handleSelection = () => {
    setHighlight(true)
    setLoading(true)
    setTimeout(() => {
      setService({ id, description })
      setHighlight(false)
    }, 300)
  }

  return (
    <div className="col-12 md:col-4 xl:col-3">
      <Card className={classNames('w-full h-full provider', { highlight })} onClick={handleSelection}>
        <div className="flex flex-row justify-space-between align-items-start">
          <div className="flex flex-column gap-3">
            <div className="text-sm text-900">
              {description}
            </div>
          </div>
          {loading && <i className="pi pi-spin pi-spinner" />}
          {!loading && <i className="pi pi-check-square" />}
        </div>
      </Card>
    </div>
  )
}

function Services({
  updateSessionLoading, selectedService, setService, searchTerm, setPlaceholder,
}) {
  const [filteredServices, setFilteredServices] = useState([])
  const { data: services, isLoading } = useMedicalServices()

  useEffect(() => {
    setPlaceholder('Search by description')
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (searchTerm) {
      setFilteredServices(fuzzySearch(services, ['description'], searchTerm, 0.4))
    } else {
      setFilteredServices(services)
    }
  }, [services, searchTerm])

  if (isLoading) {
    return 'Loading...'
  }

  return (
    <div className="providers flex flex-row flex-wrap">
      {
        map(filteredServices, ({ id, description }) => (
          <Service
            updateSessionLoading={updateSessionLoading}
            key={description}
            selected={id === selectedService?.id}
            id={id}
            description={description}
            setService={setService}
          />
        ))
      }
      <SkipCard setService={setService} />
    </div>
  )
}

export default Services
