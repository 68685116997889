import React from 'react'
import EmptyAreaTemplate from '../EmptyArea'
import StatusTag from '../statusTag'

function StatusIndicator({
  rowData, indexOfDay, dayData, isAdmin, onEmptyCellClick, showOverlay,
}) {
  if (!dayData) return null

  const { status, consumption } = dayData
  switch (status) {
    case 'not required':
      return null
    case 'not available':
      return (
        <EmptyAreaTemplate
          rowData={rowData}
          indexOfDay={indexOfDay}
          status={status}
          onClick={(e) => {
            if (isAdmin) {
              onEmptyCellClick(rowData, indexOfDay, e)
            }
          }}
        />
      )
    default:
      return (
        <div className="flex align-items-center justify-content-center">
          <StatusTag
            status={status}
            initials={consumption?.confirmedBy?.initials}
            onClick={(e) => showOverlay(consumption, e)}
          />
        </div>
      )
  }
}

export default StatusIndicator
