import React, { useState } from 'react'
import { Timeline } from 'primereact/timeline'
import moment from 'moment'
import { formatDateTimeLocale, getConsumptionsStatusColor, getConsumptionStatusLabel } from '@services/utils'

function DoseConsumptionsHistory({ historyData, timezone }) {
  const [showHistory, setShowHistory] = useState(false)
  const reversedHistoryData = [...historyData].reverse()

  const toggleHistory = () => {
    setShowHistory(!showHistory)
  }

  const customizedMarker = (item) => (
    <span
      className="w-1rem h-1rem border-circle border-solid"
      style={{ borderColor: getConsumptionsStatusColor(item.code) }}
    />
  )

  const historyContent = (item) => (
    <div>
      <div className="text-sm font-normal">{item?.subject?.fullName}</div>
      <div className="text-xs font-normal">{formatDateTimeLocale(moment(item.recordedAt), timezone)}</div>
      {
        item?.note && (
          <div className="text-xs font-normal">
            Note:
            {' '}
            {item?.note}
          </div>
        )
      }
    </div>
  )

  const historyOppositeContent = (item) => (
    <div className="w-6rem px-3 text-sm font-semibold text-left">
      {getConsumptionStatusLabel(item.code)}
    </div>
  )

  return (
    <div>
      {historyData.length > 0 && (
        <div className="flex flex-row align-items-center cursor-pointer mt-1 w-4rem" onClick={toggleHistory}>
          <div className="text-xs font-medium font-light text-primary">
            Details
          </div>
          <i className={`text-primary text-sm ${showHistory ? 'pi pi-angle-up' : 'pi pi-angle-down'}`} />
        </div>
      )}
      {showHistory && (
        <div className="card px-0">
          <Timeline
            className="flex flex-column align-items-start w-full"
            value={reversedHistoryData}
            align="left"
            opposite={historyOppositeContent}
            content={historyContent}
            marker={customizedMarker}
          />
        </div>
      )}
    </div>
  )
}

export default DoseConsumptionsHistory
