import React from 'react'
import { Dialog } from 'primereact/dialog'
import Barcode from 'react-barcode'

function BarcodeDialog({ dose, onHide, visible }) {
  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header="Barcode"
      modal
      className="p-fluid"
    >
      <div className="p-field p-2">
        <Barcode value={dose.externalId} />
      </div>
    </Dialog>
  )
}

export default BarcodeDialog
