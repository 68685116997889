import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { DetailsForm, SiteForm } from '@components/sharedDevices/SharedDeviceForm'
import FormWrapper from '@components/display/Form/FormWrapper'
import { useCurrentOrganization } from '@components/App'
import { useSites } from '@services/hooks/sitesHooks'
import { useCreateSharedDeviceMutation } from './sharedDeviceHooks'

function CreateSharedDevice({ useSharedDevicesHeader }) {
  const statusMessage = useRef(null)
  const navigate = useNavigate()
  const { setHeader } = useSharedDevicesHeader()
  const organization = useCurrentOrganization()

  const [details, setDetails] = useState({
    phone: '',
    label: '',
  })
  const [selectedSiteId, setSelectedSiteId] = useState(null)

  const {
    mutateAsync: createSharedDevice,
    isLoading: createIsLoading,
  } = useCreateSharedDeviceMutation(statusMessage)

  const { data: sites, isLoading: isSitesLoading } = useSites(organization.id, statusMessage)

  const handleCreateSharedDevice = () => {
    createSharedDevice({
      sharedDevice: {
        ...details,
        siteId: selectedSiteId,
        organizationId: organization.id,

      },
    }, {
      onSuccess: () => {
        navigate('/admin/shared_devices')
      },
    })
  }

  const handleSiteChange = (site) => {
    setSelectedSiteId(site)
  }

  const pageTitle = {
    label: (
      <span>
        <i className="text-xl pi pi-users mr-2" />
        Shared Devices
      </span>
    ),
    linkTo: '/admin/shared_devices',
  }

  const breadcrumbs = [
    {
      label: 'Create',
      template: <span>Create</span>,
    },
  ]

  useEffect(() => {
    setHeader({ title: pageTitle, breadcrumbs, primaryAction: null })
  }, [])

  return (
    <div id="create-shared-device" className="flex flex-column">
      <div className="flex flex-row flex-wrap">
        <Toast ref={statusMessage} />
        <div className="col-6">
          <FormWrapper title="Create Shared Device" fullHeight>
            <DetailsForm
              details={details}
              setDetails={(data) => setDetails({ ...details, ...data })}
            />
            <SiteForm
              sites={sites}
              selectedSite={selectedSiteId}
              setSelectedSite={handleSiteChange}
              isSitesLoading={isSitesLoading}
            />
            <Button
              label="Save"
              className="p-button-sm m-2"
              loading={createIsLoading}
              onClick={handleCreateSharedDevice}
            />
          </FormWrapper>
        </div>
      </div>
    </div>
  )
}

export default CreateSharedDevice
