import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

function DateTable({
  columns, data, isLoading, tableClassName, emptyMessage, className, sortField,
  rowGroupMode, groupRowsBy, sortMode,
}) {
  return (
    <DataTable
      value={data}
      rowGroupMode={rowGroupMode}
      groupRowsBy={groupRowsBy}
      sortMode={sortMode}
      sortField={sortField}
      showGridlines
      scrollable
      tableClassName={tableClassName}
      loading={isLoading}
      className={className}
      emptyMessage={emptyMessage}
    >
      {columns.map((col, index) => (
        <Column
          key={index}
          field={col.field}
          header={col.header}
          body={col.body || null}
          style={col.style}
        />
      ))}
    </DataTable>
  )
}

export default DateTable
