import React, { useRef, useState } from 'react'
import {
  filter, get, minBy, some,
} from 'lodash'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Tag } from 'primereact/tag'
import SlotIdentifier from '@components/inventoryManagement/SlotIdentifier'
import { dateTemplate } from '@services/utils'
import { useCurrentCapabilities } from '@components/App'
import { Messages } from 'primereact/messages'
import CreateRefillDialog from '@components/inventoryManagement/Dashboard/CreateRefillDialog'
import LoadRefillDialog from '@components/inventoryManagement/Dashboard/LoadRefillDialog'
import { FulfillmentStatus } from '../../FullfillmentStatuses'
import { useDoseFillsQuery } from '../../../../features/InventoryManagement/inventoryHooks'

function DoseFillsList({ dose, handleDoseFocus }) {
  const [showCreateRefillDialog, setShowCreateRefillDialog] = useState(false)
  const [showLoadRefillDialog, setShowLoadRefillDialog] = useState(false)
  const [doseFillToLoad, setDoseFillToLoad] = useState()

  const statusMessage = useRef(null)
  const {
    data: doseFills,
    isLoading,
  } = useDoseFillsQuery(dose.id, statusMessage)

  const capabilities = useCurrentCapabilities()

  // Ekit dose fills are assigned to only one drawer or slot
  const drawer = (doseFill) => get(doseFill, 'drawerSlots[0].simpillDrawer') || get(doseFill, 'simpillDrawers[0]')
  const drawerTemplate = (doseFill) => get(drawer(doseFill), 'position')
  const slotTemplate = (doseFill) => (<SlotIdentifier drawerSlot={get(doseFill, 'drawerSlots[0]')} />)
  const deviceTemplate = (doseFill) => get(drawer(doseFill), 'simpill.label')
  const inventoryTemplate = ({ fillsLeft, dosesInFill }) => `${fillsLeft} / ${dosesInFill}`

  const fulfillmentStatusTemplate = ({ status, finishedAt }) => {
    const finished = status === FulfillmentStatus.loadedByAgency && finishedAt
    let value = ''
    let severity = ''
    if (status === FulfillmentStatus.pending) {
      value = 'Pending'
      severity = 'warning'
    } else if (finished) {
      value = 'Finished'
      severity = 'info'
    } else {
      value = 'Loaded'
      severity = 'success'
    }
    return (
      <Tag
        className="text-small font-normal max-w-min max-h-min p-1"
        severity={severity}
        value={value}
      />
    )
  }

  const pendingDoseFills = filter(doseFills, {
    status: FulfillmentStatus.pending,
  })
  const hasPendingRefill = some(pendingDoseFills)

  // TODO: create UI for user to select which dose
  // instead of calculating it automatically like below
  const calculateDoseFillToLoad = (pendingFills) => {
    if (pendingFills.length === 1) {
      return pendingFills[0]
    }

    const someDispensed = some(
      pendingFills,
      (doseFill) => doseFill.pharmacyDispensedAt,
    )
    if (someDispensed) {
      return minBy(pendingFills, 'pharmacyDispensedAt')
    }

    const hasSomeWithExpiraiton = some(
      pendingFills,
      (doseFill) => doseFill.expiresAt,
    )
    if (hasSomeWithExpiraiton) {
      return minBy(pendingFills, 'expiresAt')
    }

    return minBy(pendingFills, 'createdAt')
  }

  const actionButton = () => {
    if (isLoading) return null
    if (capabilities.inventory.canLoadFills && hasPendingRefill) {
      return (
        <Button
          label="Load Pending Refill"
          onClick={() => {
            handleDoseFocus(dose.id)
            setDoseFillToLoad(calculateDoseFillToLoad(pendingDoseFills))
            setShowLoadRefillDialog(true)
          }}
          className="p-button-sm ml-auto p-button-success"
        />
      )
    } if (capabilities.inventory.canAddRefills) {
      return (
        <Button
          label="Prepare Refill"
          onClick={() => {
            handleDoseFocus(dose.id)
            setShowCreateRefillDialog(true)
          }}
          className="p-button-sm ml-auto p-button-warning"
        />
      )
    }
  }

  return (
    <>
      <CreateRefillDialog
        dose={dose}
        isVisible={showCreateRefillDialog}
        setIsVisible={setShowCreateRefillDialog}
      />
      <LoadRefillDialog
        dose={dose}
        doseFillToLoad={doseFillToLoad}
        doseFills={doseFills}
        isVisible={showLoadRefillDialog}
        setIsVisible={setShowLoadRefillDialog}
      />
      <div className="flex flex-column gap-3 w-full text-base text-900">
        <Messages ref={statusMessage} />
        <div className="flex flex-row align-items-center px-2">
          <span className="text-base">Dose Fills</span>
          {actionButton()}
        </div>
        <DataTable value={doseFills} dataKey="id" loading={isLoading}>
          <Column
            key="status"
            header="Status"
            field="status"
            body={fulfillmentStatusTemplate}
          />
          <Column
            key="pharmacyCreated"
            header="Pharmacy Created"
            field="pharmacyDispensedAt"
            body={({ pharmacyDispensedAt }) => (
              pharmacyDispensedAt ? dateTemplate(pharmacyDispensedAt) : null
            )}
          />
          <Column
            key="siteLoaded"
            header="Site Loaded"
            field="agencyLoadedAt"
            body={({ agencyLoadedAt }) => (agencyLoadedAt ? dateTemplate(agencyLoadedAt) : null)}
          />
          <Column key="inventory" header="Inventory" field={inventoryTemplate} />
          <Column
            key="expiresAt"
            header="Expires At"
            field="expiresAt"
            body={({ expiresAt }) => dateTemplate(expiresAt)}
          />
          <Column key="ekit" header="EKIT" field={deviceTemplate} />
          <Column key="drawer" header="Drawer" field={drawerTemplate} />
          <Column key="slot" header="Slot" field={slotTemplate} />
        </DataTable>
      </div>
    </>

  )
}

export default DoseFillsList
