import React from 'react'
import { useParams } from 'react-router-dom'
import TimeSelectionDialog from '@components/clientDoses/Schedule/TimeSelectionDialog'
import { getDisplayTimeFromMinutes } from '@services/utils/moment'

// Displays a schedule time and opens a dialog for editing the schedule when clicked.
function EditableScheduleTime({
  schedule, handleCreateSchedule, handleUpdateSchedule, handleDestroySchedule,
}) {
  const { patientId } = useParams()

  const controls = ({ handleEditorShow }) => (
    <span className="text-blue-400 cursor-pointer" onClick={() => handleEditorShow(schedule)}>
      {getDisplayTimeFromMinutes(schedule?.time)}
    </span>
  )

  return (
    <TimeSelectionDialog
      clientId={patientId}
      dose={{ prn: false }}
      render={controls}
      handleCreateSchedule={handleCreateSchedule}
      handleUpdateSchedule={handleUpdateSchedule}
      handleDestroySchedule={handleDestroySchedule}
    />
  )
}

export default EditableScheduleTime
