import React, { useState, useEffect } from 'react'
import { isEmpty, find } from 'lodash'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import PatientsSelection from '@components/ccm/PatientsSelection'
import Services from '@components/ccm/Services'
import Providers from '@components/ccm/Providers'
import Timer from '@components/ccm/Timer'
import SessionBreadcrumbs from '@components/ccm/SessionBreadcrumbs'
import ContentBlock from '@components/display/ContentBlock'
import { useCurrentOrganization } from '@components/App'
import SearchField from '@components/display/Form/SearchField'
import {
  usePatientBillableSessions,
  useCreateBillableSession,
  useUpdateBillableSession,
} from './ccmHooks'

function Menu({
  sessionId,
  searchTerm,
  setSearchTerm,
  placeholder,
  step,
  patient,
  time,
  provider,
  service,
  timeInputOptions,
}) {
  return (
    <div className="p-menubar bg-white flex flex-wrap justify-content-between align-items-center px-3 py-3">
      <div className="font-semi-bold text-900 ml-2 md:ml-0">
        <SessionBreadcrumbs
          step={step}
          patient={patient}
          time={time}
          provider={provider}
          service={service}
          isUpdate={!!sessionId}
        />
      </div>
      { !isEmpty(placeholder)
        && (
        <div className="mt-2 md:mt-0">
          <SearchField
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            placeholder={placeholder}
          />
        </div>
        )}
      { step === 'timer' && timeInputOptions }
    </div>
  )
}

function ClientSession({ patients, patientsLoading }) {
  const { sessionId, patientId } = useParams()
  const [newSessionId, setNewSessionId] = useState()
  const [stepParams, setStepParams] = useSearchParams()
  const [session, setSession] = useState()
  const [step, setStep] = useState()

  const [patient, setPatient] = useState()
  const [selectedProvider, setProvider] = useState()
  const [selectedService, setService] = useState()
  const [time, setTime] = useState()
  const [duration, setDuration] = useState()
  const [date, setDate] = useState()

  const [searchTerm, setSearchTerm] = useState('')
  const [placeholder, setPlaceholder] = useState('')
  const [timeInputOptions, setTimeInputOptions] = useState()

  const organization = useCurrentOrganization()

  const navigate = useNavigate()

  const {
    data: billableSessions,
    isLoading: billableSessionsLoading,
  } = usePatientBillableSessions(patientId)

  const {
    mutateAsync: createSession,
    isLoading: createSessionLoading,
  } = useCreateBillableSession()

  const {
    mutateAsync: updateSession,
    isLoading: updateSessionLoading,
  } = useUpdateBillableSession()

  const handleStepChange = (value) => {
    setStepParams({ step: value })
  }

  const handleCreateSession = async ({
    selectedPatient, note, timeSpan, date: newDate, duration: newDuration,
  }) => {
    const newSession = await createSession({
      billableServiceNotesAttributes: note ? [{ text: note }] : [],
      timeSpans: timeSpan ? [timeSpan] : null,
      date: newDate,
      duration: newDuration,
      patientId: selectedPatient?.id || patientId,
      medicalProviderId: selectedProvider?.id,
      medicalServiceId: selectedService?.id,
    })
    setNewSessionId(newSession?.sessionId)
    return newSession
  }

  const handleUpdateSession = ({ note, service, provider }) => updateSession({
    id: sessionId || newSessionId,
    billableServiceNotesAttributes: note ? [{ text: `Addendum: ${note}` }] : [],
    medicalProviderId: provider?.id || selectedProvider?.id,
    medicalServiceId: service?.id || selectedService?.id,
  })

  const handlePatientSelection = async (selectedPatient) => {
    setSearchTerm('')
    setPlaceholder('')
    setPatient(selectedPatient)

    const note = time?.note
    const timeSpan = time?.timeSpan
    await handleCreateSession({
      selectedPatient, note, timeSpan, date, duration,
    })

    if (organization.ccmUseDefaultProvider) {
      handleStepChange('service')
    } else {
      handleStepChange('provider')
    }
  }

  const handleProviderSelection = (provider) => {
    setSearchTerm('')
    setPlaceholder('')
    setProvider(provider)
    if (!session?.medicalService) {
      handleStepChange('service')
    } else {
      handleStepChange('timer')
    }
  }

  const handleServiceSelection = async (service) => {
    setSearchTerm('')
    setPlaceholder('')
    setService(service)

    if (service && (sessionId || newSessionId)) {
      await handleUpdateSession({ service })
    }

    if (isEmpty(time)) {
      handleStepChange('timer')
    } else if (patientId) {
      navigate(`/admin/patients/${patientId || patient?.id}/ccm_sessions`)
    } else {
      window.location.assign(`/admin/patients/${patient?.id}/ccm_sessions`)
    }
  }

  const handleSetTime = async ({
    note, timeSpan, date: newDate, duration: newDuration,
  }) => {
    setTime({ note, timeSpan })
    setDate(newDate)
    setDuration(newDuration)
    setTimeInputOptions()

    if (patientId || patient) {
      handleCreateSession({
        selectedPatient: patient, note, timeSpan, date, duration,
      })
      if (patientId) {
        navigate(`/admin/patients/${patientId}/ccm_sessions`)
      } else {
        window.location.assign(`/admin/patients/${patient?.id}/ccm_sessions`)
      }
    } else {
      handleStepChange('patient')
    }
  }

  const handleSetNote = async ({ note }) => {
    await handleUpdateSession({ note })
    navigate(`/admin/patients/${patientId}/ccm_sessions`)
  }

  useEffect(() => {
    if (stepParams.get('step')) {
      setStep(stepParams.get('step'))
    }
  }, [stepParams.get('step'), patientId])

  useEffect(() => {
    let initialStep = 'timer'
    if (patientId) {
      if (organization.ccmUseDefaultProvider) {
        initialStep = 'service'
      } else {
        initialStep = 'provider'
      }
    }
    setStepParams({ step: initialStep })
  }, [patientId])

  useEffect(() => {
    if (!isEmpty(billableSessions)) {
      const selectedSession = find(billableSessions, ({ id }) => id === sessionId)
      setSession(selectedSession)
    }
  }, [billableSessions])

  useEffect(() => {
    if (!isEmpty(session)) {
      const { medicalProvider, medicalService } = session

      if (medicalProvider) {
        const { fullName, title } = medicalProvider
        setProvider({ ...medicalProvider, name: `${!isEmpty(title) ? title : ''} ${fullName}` })
      }

      if (medicalService) {
        setService(medicalService)
      }

      if (!medicalProvider && !medicalService) {
        handleStepChange('provider')
      } else if (!medicalProvider) {
        handleStepChange('provider')
      } else if (!medicalService) {
        handleStepChange('service')
      } else {
        handleStepChange('timer')
      }
    }
  }, [session])

  return (
    <div className="flex flex-column">
      <div className="col-12">
        <Menu
          sessionId={sessionId}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          placeholder={placeholder}
          step={step}
          patient={patient}
          time={time}
          provider={selectedProvider}
          service={selectedService}
          timeInputOptions={timeInputOptions}
        />
      </div>
      <ContentBlock isLoading={patientId && (patientsLoading || billableSessionsLoading || !step)}>
        {step === 'patient'
          && (
          <PatientsSelection
            selectedPatient={patient}
            patients={patients}
            patientsLoading={patientsLoading}
            createSessionLoading={createSessionLoading}
            searchTerm={searchTerm}
            setPaitent={handlePatientSelection}
            setPlaceholder={setPlaceholder}
          />
          )}
        {step === 'provider'
          && (
          <Providers
            searchTerm={searchTerm}
            selectedProvider={selectedProvider}
            setProvider={handleProviderSelection}
            setPlaceholder={setPlaceholder}
          />
          )}
        {step === 'service'
          && (
          <Services
            searchTerm={searchTerm}
            selectedService={selectedService}
            updateSessionLoading={updateSessionLoading}
            setService={handleServiceSelection}
            setPlaceholder={setPlaceholder}
          />
          )}
        {step === 'timer'
          && (
          <Timer
            time={time}
            sessionDuration={duration}
            sessionDate={date}
            setTimeInputOptions={setTimeInputOptions}
            handleCreateSession={handleSetTime}
            handleUpdateSession={handleSetNote}
            updateLoading={createSessionLoading || updateSessionLoading}
            isUpdate={!!sessionId}
            setPlaceholder={setPlaceholder}
          />
          )}
      </ContentBlock>
    </div>
  )
}

export default ClientSession
