import React, { memo } from 'react'
import UserDirectoryServiceIcon from '@components/integrations/UserDirectoryServiceIcon'

/**
 * Displays the title for a user profile card with an icon for the user directory service in use.
 *
 * @param {object} user - The user to display the profile card title for.
 */
function UserProfileCardTitle({ user }) {
  if (!user) {
    return null
  }

  const cardIcon = user.isDirectoryManaged
    ? <UserDirectoryServiceIcon service="active-directory" />
    : <i className="pi pi-user" />

  return (
    <div className="flex flex-row">
      <div className="mr-2">
        {cardIcon}
      </div>
      <div>Profile</div>
    </div>
  )
}

export default memo(UserProfileCardTitle)
