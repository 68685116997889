import React from 'react'
import { find } from 'lodash'
import { InputTextarea } from 'primereact/inputtextarea'
import { conditionTypes } from '../config'

function ConditionNote({ conditionNote, conditionType, setConditionNote }) {
  if (!conditionType) {
    return null
  }

  const placeholder = find(conditionTypes, { code: conditionType })?.exampleNote
    || 'Example: Do not administer without a glass of water.'

  return (
    <div className="col-12 flex flex-column gap-2 text-center fadein justify-content-center align-items-center">
      <label className="text-lg font-normal mb-1" htmlFor="condition-note">
        Criteria / Vitals Note
      </label>
      <InputTextarea
        id="condition-note"
        rows={3}
        data-testid="condition-note"
        autoResize
        value={conditionNote ?? ''}
        onChange={(event) => setConditionNote(event.target.value)}
        placeholder={placeholder}
      />
    </div>
  )
}

export default ConditionNote
