import React from 'react'
import { get } from 'lodash'
import moment from 'moment'
import { useCurrentOrganization } from '@components/App'
import { formattedTimeFromDate } from '@services/utils'
import StatusOverlayContent from './StatusOverlayContent'
import ColoredCircle from './ScheduledDoses/ColoredCircle'

/**
 * Generic Overlay component that can display various types of overlays
 * by adjusting its content and styling based on the type provided.
 *
 * @param {Object} selectedCell - The selected cell containing details.
 * @param {boolean} canEdit - If the user can edit the status.
 * @param {function} showEditStatusDialog - Function to show the edit status dialog.
 * @param {string} overlayType - Type of the overlay (given, loa, missed, refused).
 * @returns {JSX.Element} - JSX element representing the overlay.
 */
function StatusOverlay({
  selectedCell,
  canEdit,
  showEditStatusDialog,
  overlayType,
}) {
  const currentOrganization = useCurrentOrganization()
  const timezone = selectedCell.timezone || currentOrganization?.timezone
  const statusLabel = overlayType.charAt(0).toUpperCase() + overlayType.slice(1)
  const expectedDate = formattedTimeFromDate(moment(selectedCell?.expectedAt), timezone)
  const confirmedDate = selectedCell?.confirmedAt
    ? formattedTimeFromDate(moment(selectedCell.confirmedAt), timezone)
    : 'Unconfirmed'
  const confirmedBy = get(selectedCell?.confirmedBy, 'fullName', 'Unspecified')

  return (
    <div className="w-22rem p-1 pb-0">
      <StatusOverlayContent
        timezone={timezone}
        item={{
          status: overlayType,
          statusLabel,
          statusIcon: <ColoredCircle type={overlayType} />,
          expectedAt: expectedDate,
          consumptionHistory: selectedCell ? selectedCell.administrationEvents : [],
          ...(overlayType !== 'missed' && { confirmedBy, confirmedAt: confirmedDate }),
          ...(selectedCell?.missedReason && overlayType === 'missed' ? { reason: selectedCell?.missedReason || 'Unspecified' } : {}),
        }}
        {...(canEdit && { handleEdit: showEditStatusDialog })}
      />
    </div>
  )
}

export default StatusOverlay
