import React, { useRef, useState } from 'react'
import { get } from 'lodash'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast'
import { hourMinuteTemplate } from '@services/utils'
import EditShiftDialog from './EditShiftDialog'
import CreateShiftDialog from './CreateShiftDialog'

function Shifts({
  site,
  isLoading,
}) {
  const statusMessage = useRef(null)
  const [selectedShift, setSelectedShift] = useState(false)
  const [showCreateShiftDialog, setShowCreateShiftDialog] = useState(false)

  const shifts = get(site, 'shifts', [])

  const sortedShifts = shifts.sort((curr, next) => {
    if (curr.startDayOfWeek === next.startDayOfWeek) {
      return moment(curr.startTime).format('H') - moment(next.startTime).format('H')
    }

    return curr.startDayOfWeek - next.startDayOfWeek
  })

  return (
    <div>
      {
        selectedShift && (
          <EditShiftDialog
            onHide={() => setSelectedShift(null)}
            shift={selectedShift}
            statusMessage={statusMessage}
            visible
          />
        )
      }
      {
        showCreateShiftDialog
        && (
        <CreateShiftDialog
          onHide={() => setShowCreateShiftDialog(false)}
          site={site}
          statusMessage={statusMessage}
          visible
        />
        )
      }
      <Toast ref={statusMessage} />
      <Card className="h-full">
        <div className="flex flex-column gap-3 text-base text-lg">
          <div className="flex flex-row justify-content-between align-items-center">
            <div>
              Shifts
            </div>
            <Button
              label="Add Shift"
              icon="fa-solid fa-calendar-plus"
              className="p-button-sm p-button-info"
              onClick={() => setShowCreateShiftDialog(true)}
            />
          </div>
        </div>
        <DataTable
          dataKey="id"
          value={sortedShifts}
          isLoading={isLoading}
          className="mt-2"
          rowClassName="fadein"
          selectionMode="single"
          onSelectionChange={(e) => setSelectedShift(e.value)}
        >
          <Column
            key="startDayOfWeekName"
            field="startDayOfWeekName"
            header="Start Day"
          />
          <Column
            key="startTime"
            field="startTime"
            header="Start Time"
            body={((rowData) => hourMinuteTemplate(rowData.startTimeHour, rowData.startTimeMinute))}
          />
          <Column
            key="endDayOfWeekName"
            field="endDayOfWeekName"
            header="End Day"
          />
          <Column
            key="endTime"
            field="endTime"
            header="End Time"
            body={((rowData) => hourMinuteTemplate(rowData.endTimeHour, rowData.endTimeMinute))}
          />
        </DataTable>
      </Card>
    </div>
  )
}

export default Shifts
