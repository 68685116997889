import { RRule } from 'rrule'

export const patternViews = ['Daily', 'Weekly', 'Monthly', 'Advanced']

export const frequencies = [
  {
    label: 'Days',
    value: RRule.DAILY,
  },
  {
    label: 'Weeks',
    value: RRule.WEEKLY,
  },
  {
    label: 'Months',
    value: RRule.MONTHLY,
  },
]

export const daysOfWeek = [
  {
    label: 'S',
    title: 'Sunday',
    value: RRule.SU,
    className: 'day-of-week-button justify-content-center',
  },
  {
    label: 'M',
    title: 'Monday',
    value: RRule.MO,
    className: 'day-of-week-button justify-content-center',
  },
  {
    label: 'T',
    title: 'Tuesday',
    value: RRule.TU,
    className: 'day-of-week-button justify-content-center',
  },
  {
    label: 'W',
    title: 'Wednesday',
    value: RRule.WE,
    className: 'day-of-week-button justify-content-center',
  },
  {
    label: 'T',
    title: 'Thursday',
    value: RRule.TH,
    className: 'day-of-week-button justify-content-center',
  },
  {
    label: 'F',
    title: 'Friday',
    value: RRule.FR,
    className: 'day-of-week-button justify-content-center',
  },
  {
    label: 'S',
    title: 'Saturday',
    value: RRule.SA,
    className: 'day-of-week-button justify-content-center',
  },
]

export default {}
