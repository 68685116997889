import { displayErrorMessages } from '@services/errorHandler'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { map, sortBy } from 'lodash'
import { useMemo } from 'react'
import { RRule } from 'rrule'
import { dateFromHoursMinutes } from '@services/utils/time'

const formatSchedules = (scheduleData) => map(scheduleData, (schedule) => {
  const eventTime = schedule?.eventTime
  let rrule
  if (schedule.rrule) {
    rrule = new RRule(RRule.parseString(schedule.rrule))
  }
  return {
    ...schedule,
    time: dateFromHoursMinutes(schedule.timeHours, schedule.timeMinutes),
    text: rrule ? rrule.toText() : '',
    eventTime: eventTime ? {
      ...eventTime,
      startTime: dateFromHoursMinutes(
        eventTime.startTimeHours,
        eventTime.startTimeMinutes,
      ),
      endTime: dateFromHoursMinutes(
        eventTime.endTimeHours,
        eventTime.endTimeMinutes,
      ),
    } : null,
  }
})

// eslint-disable-next-line import/prefer-default-export
export function useDoseSigsQuery({ patientId, statusMessage }) {
  const query = useQuery({
    queryKey: ['doseSigs', patientId],
    queryFn: async () => ApiClient.get(`/patients/${patientId}/dose_sigs`),
    onError: (error) => statusMessage.current && displayErrorMessages(error, statusMessage),
  })

  return useMemo(() => ({
    ...query,
    data: sortBy(map(query?.data, (doseSig) => (
      {
        ...doseSig,
        schedules: formatSchedules(doseSig.schedules),
      }
    )), 'dose.medicine.name'),
  }), [query.data])
}

export function useUpdateDoseSigMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ doseSig }) => ApiClient.put(`/dose_sigs/${doseSig.id}`, doseSig),
    onSuccess: () => {
      queryClient.invalidateQueries(['doseSigs'])
    },
  })
}
