import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton'
import { Messages } from 'primereact/messages'
import AttributeCard, { Attribute } from '@components/display/AttributeCard'
import UserProfileCardTitle from '@components/user/UserProfileCardTitle'
import { dateTemplate } from '@services/utils'
import { useCurrentOrganization } from '@components/App'
import SiteSelection from '../sites/SiteSelection'
import { ExportConsumptionsButton } from '../../features/ExportConsumptionsButton/ExportConsumptionsButton'
import { useOrgSitesQuery } from '../sites/sitesHooks'
import { useUpdateAdminMutation, useUpdateAdminSettingsMutation } from '../../features/Admins/adminsHooks'
import NotificationTestButton from '../display/UserDevices/NotificationTestButton'

function Profile({ admin, isLoading }) {
  const title = { body: (<UserProfileCardTitle user={admin} />) }

  const navigate = useNavigate()
  const {
    email, upn, phone, createdAt, timezone, customerId,
  } = admin || {}

  const valueTemplate = (value) => (
    isLoading ? <Skeleton className="inline-flex" width="5rem" height="1rem" /> : value
  )

  const editClick = () => {
    navigate(`/admin/admins/${admin.id}/update`)
  }

  const action = { label: 'Edit', command: editClick }

  return (
    <AttributeCard title={title} direction="horizontal" action={action}>
      { email
        && (
        <Attribute
          label="Email:"
          value={valueTemplate(email || 'Empty')}
        />
        )}
      { upn
        && (
          <Attribute
            label="UPN:"
            value={valueTemplate(upn || 'Empty')}
          />
        )}
      { phone
        && (
        <Attribute
          label="Phone:"
          value={valueTemplate(phone || 'Empty')}
        />
        )}
      { customerId
        && (
        <Attribute
          label="Custom ID:"
          value={valueTemplate(customerId || 'Empty')}
        />
        )}
      <Attribute
        label="Timezone:"
        value={valueTemplate(timezone || 'Empty')}
      />
      <Attribute
        label="Created At:"
        value={valueTemplate(dateTemplate(createdAt))}
      />
    </AttributeCard>
  )
}

function AdminSites({
  adminId, admin, organization, statusMessage, isLoading, maxDataViewHeight,
}) {
  const {
    data: availableSites,
    isLoading: isLoadingSites,
  } = useOrgSitesQuery(organization.id, statusMessage)

  const {
    mutateAsync: updateAdmin,
    isLoading: isUpdatingAdmin,
  } = useUpdateAdminMutation(adminId, statusMessage)

  const {
    mutateAsync: updateAdminSettings,
    isLoading: isUpdatingAdminSettings,
  } = useUpdateAdminSettingsMutation(adminId, statusMessage)

  const handleCaregiverSitesChange = (sites) => {
    updateAdmin({
      siteAdminSiteAccessIds: sites.map((site) => site.id),
    })
  }

  const handleAllSitesAccessChange = (hasAllSitesAcccess) => {
    updateAdminSettings({
      siteAccess: hasAllSitesAcccess ? 'all' : 'assigned',
    })
  }

  if (isLoading) {
    return (
      <div className="col-12">
        <Skeleton className="inline-flex" width="5rem" height="1rem" />
      </div>
    )
  }

  return (
    <SiteSelection
      availableSites={availableSites}
      userSites={admin.sites}
      onUserSitesChange={(sites) => handleCaregiverSitesChange(sites)}
      isLoading={isLoadingSites || isUpdatingAdmin}
      showAllSitesAccess
      maxDataViewHeight={maxDataViewHeight}
      allSitesAccessLoading={isUpdatingAdminSettings}
      onAllSitesAccessChange={
        (hasAllSitesAcccess) => handleAllSitesAccessChange(hasAllSitesAcccess)
      }
      hasAllSitesAccess={admin.adminSettings.siteAccess === 'all'}
      editEnabled={!organization.deviceBasedAccess && !admin.isDirectoryManaged}
    />
  )
}

function AdminDashboard({
  adminId, admin, adminLoading, handleSetHeader, isCurrentUserAdmin, maxDataViewHeight,
}) {
  const statusMessage = useRef()
  const organization = useCurrentOrganization()
  const canViewSites = organization.hasSites && !organization.deviceBasedAccess

  const primaryAction = isCurrentUserAdmin && (
    <div className="flex flex-row gap-2 pb-2">
      <NotificationTestButton userId={adminId} />
      {!organization.ccmEnabled
        && (
        <ExportConsumptionsButton
          userId={adminId}
          // eslint-disable-next-line
          role="admin"
        />
        )}
    </div>
  )

  useEffect(() => handleSetHeader({ primaryAction }), [admin])

  return (
    <div className="flex flex-row flex-wrap">
      <Messages ref={statusMessage} />
      <div className="col-12" data-testid="admin-profile-card">
        <Profile admin={admin} isLoading={adminLoading} />
      </div>
      <div className="col-12" data-testid="admin-sites-card">
        {
          canViewSites && (
            <AdminSites
              adminId={adminId}
              admin={admin}
              organization={organization}
              statusMessage={statusMessage}
              maxDataViewHeight={maxDataViewHeight}
              isLoading={adminLoading}
            />
          )
        }
      </div>
    </div>
  )
}

export default AdminDashboard
