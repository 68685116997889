import moment from 'moment'
import { maxBy } from 'lodash'
import { medicineDisplayNameWithStrength } from '@components/clientDoses/doseUtils'
import { refusedReasonArray } from '../adherenceDashboardUtils'

const OVERRIDE_STATUS_MAP = {
  CREATE_CONFIRMED: 'OVERRIDE_CONFIRMED',
  CREATE_REFUSED: 'OVERRIDE_REFUSED',
}

const formatTimeWithTimezone = (confirmedAt, timezone) => {
  const dateTimeInUTC = moment.utc(confirmedAt)
  const dateTimeInLocal = dateTimeInUTC.clone().tz(timezone)
  return dateTimeInLocal.format('h:mm A')
}

function mapStatusDetails(consumption, timezone) {
  const latestConsumptionEvent = maxBy(consumption.administrationEvents, 'recordedAt')
  return {
    confirmedAt: consumption.confirmedAt
      ? formatTimeWithTimezone(consumption.confirmedAt, timezone)
      : null,
    confirmedBy: consumption.confirmedBy.fullName,
    prnReason: consumption.prnReason,
    reason: latestConsumptionEvent?.reason || 'Unspecified',
    consumptionId: consumption.id,
    status: consumption.status,
    consumptionHistory: consumption.administrationEvents,
    isPrn: true,
  }
}

/**
 * Transforms an array of dose records into a structured format that includes
 * dose details along with a nested breakdown of consumption events organized
 * by date and status.
 */
const transformDataForTable = (data, timezone) => {
  const result = new Map()

  data.forEach((entry) => {
    const medicineName = medicineDisplayNameWithStrength(entry)
    const { id: doseId } = entry

    // TODO: This is a hack that will temporarily work, but it is not correct. The sustainable
    //       solution would require a significant refactor of the adherence dashboards, but this
    //       PR is way too big as is and we don't have time to do that now.
    const doseSig = entry?.sigs?.find((sig) => sig.prn)

    if (!result.has(medicineName)) {
      result.set(medicineName, {
        medicineName,
        pharmacyInstructions: doseSig?.pharmacyInstructions,
        doseId,
        doseSig,
        dose: entry,
        dates: {},
      })
    }

    const medicine = result.get(medicineName)

    entry.consumptions.forEach((consumption) => {
      const dateKey = `consumptions.${moment.utc(consumption.confirmedAt).tz(timezone).format('MMM-Do').toLowerCase()}`
      const { status } = consumption

      if (!medicine.dates[dateKey]) {
        medicine.dates[dateKey] = {}
      }

      const dateGroup = medicine.dates[dateKey]

      if (!dateGroup[status]) {
        dateGroup[status] = []
      }

      dateGroup[status].push(mapStatusDetails(consumption, timezone))
    })
  })

  const sortedResult = new Map([...result.entries()].sort((a, b) => a[0].localeCompare(b[0])))

  return Array.from(sortedResult.values()).map((med) => ({
    ...med,
    ...med.dates,
  }))
}

const generateDateColumns = (dateRange, bodyTemplate, headerTemplate, marApprovals) => {
  const dateColumns = []

  const startDate = moment(dateRange.startDate)
  const endDate = moment(dateRange.endDate)

  let dateIner = startDate.clone()
  while (dateIner.isSameOrBefore(endDate)) {
    const dateInerClone = dateIner.clone()
    const dayMarApprovals = marApprovals.filter((mar) => mar.days.includes(dateInerClone.format('YYYY-MM-DD')))
    dateColumns.push({
      header: headerTemplate(dateInerClone, dateInerClone.format('MMM Do'), dayMarApprovals),
      field: `consumptions.${dateIner.format('MMM-Do').toLowerCase()}`,
      body: (rowData, column) => bodyTemplate(rowData, column, dateInerClone),
      style: { minWidth: '7rem' },
      align: 'center',
    })
    dateIner = dateIner.add(1, 'days')
  }
  return dateColumns
}

const buildDateAndTimeWithTimeZone = ({ date, time, timezone }) => {
  const dateTimeString = `${moment(date).format('YYYY-MM-DD')} ${moment(time, 'hh:mm A').format('HH:mm:ss')}`
  const dateTimeInTimeZone = moment.tz(dateTimeString, timezone)
  return dateTimeInTimeZone.toISOString()
}

const createConsumptionPayload = ({
  formData,
  isAddingPrn,
  isOverriding,
  selectedDate,
  organization: { timezone, patientLabelSingular },
  dose,
  doseSig,
  patientId,
}) => {
  const {
    administeredBy, status, dateTime, time, prnReason, reason, note,
  } = formData

  const basePayload = {
    code: isOverriding ? OVERRIDE_STATUS_MAP[status] : status,
    subject_id: administeredBy.id,
    prn: true,
    prn_reason: prnReason,
    reason: refusedReasonArray(patientLabelSingular)
      .find((r) => r.value === reason)?.label,
    note,
    patientId,
  }

  if (isAddingPrn) {
    return {
      consumption: {
        ...basePayload,
        confirmed_at: buildDateAndTimeWithTimeZone(
          { date: selectedDate, time, timezone },
        ),
        doseId: dose.id,
        doseSigId: doseSig?.id,
      },
    }
  }

  if (isOverriding) {
    return {
      ...basePayload,
      timestamp: buildDateAndTimeWithTimeZone(
        { date: dateTime, time: dateTime, timezone },
      ),
    }
  }

  return null
}

const convertToOrgTimezone = (date, timezone) => moment.tz(date, timezone).toISOString()

export {
  generateDateColumns,
  transformDataForTable,
  createConsumptionPayload,
  buildDateAndTimeWithTimeZone,
  convertToOrgTimezone,
}
