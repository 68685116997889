import React from 'react'
import { Button } from 'primereact/button'
import { getEnvironment } from '../App'

function MedicationsDueNotification({ patient }) {
  const onClickView = () => {
    const { mobileUrl } = getEnvironment()
    window.open(mobileUrl, '_blank')
  }

  let medicationsLabel = ' medications are '
  if (patient.allowedConsumptions === 1) {
    medicationsLabel = ' medication is '
  }

  return (
    <div className="d-flex flex-1 align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <span className="pi pi-info-circle mr-2 text-2xl text-blue-600" />
        <span className="text-base text-blue-600 font-normal">
          {patient.allowedConsumptionsCount}
          {medicationsLabel}
          currently due
        </span>
      </div>
      <Button
        label="View"
        onClick={onClickView}
        icon="pi pi-arrow-right"
        iconPos="right"
        className="p-button-outlined p-button-sm h-2rem w-9rem white-space-nowrap"
      />
    </div>
  )
}

export default MedicationsDueNotification
