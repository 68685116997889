import React, { useCallback } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

function PRNConsumptionTable({
  consumptions,
  isLoadingPrnDose,
  maxDataViewHeight,
  medColumn = [],
  dateColumns = [],
}) {
  const renderMedColumns = useCallback(() => medColumn.map((col) => (
    <Column key={col.field} {...col} />), [medColumn]))

  const renderDateColumns = useCallback(() => dateColumns.map(({
    field, header, align, style, body,
  }) => (
    <Column
      key={field}
      header={header}
      align={align}
      field={field}
      style={style}
      body={body}
    />
  )), [dateColumns])

  return (
    <div className="flex flex-column gap-3 text-base text-900">
      <DataTable
        className="fadein"
        dataKey="id"
        loading={isLoadingPrnDose}
        value={consumptions}
        scrollable
        scrollHeight={maxDataViewHeight - 40}
        showGridlines
      >
        {renderMedColumns()}
        {renderDateColumns()}
      </DataTable>
    </div>
  )
}

export default PRNConsumptionTable
