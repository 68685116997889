import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

function PRNDialog({
  isVisible, setIsVisible, handleDosePrnChange, isLoading, toPrnValue = true,
}) {
  const handleSetDoseAsPrn = async () => {
    await handleDosePrnChange(toPrnValue)
    setIsVisible(false)
  }

  const prnConfirmationTemplate = () => (
    <div className="flex flex-column gap-2">
      <div className="flex flex-row gap-4 justify-content-center align-items-center">
        <i className="pi pi-exclamation-triangle text-4xl" />
        <div className="flex flex-column text-base text-900">
          <span>
            {
              toPrnValue
                ? 'This will remove existing schedules and set the dose sig to be taken on an as needed basis.'
                : 'This will remove the PRN status and set the dose sig to be taken on a configurable schedule.'
            }
          </span>
          <span>Are you sure you want to proceed?</span>
        </div>
      </div>
    </div>
  )

  const footer = (
    <div className="flex flex-row gap-2 justify-content-end">
      <Button
        label="No"
        className="p-button-text p-button-sm"
        onClick={() => setIsVisible(false)}
        disabled={isLoading}
      />
      <Button
        label="Yes"
        className="p-button-sm"
        onClick={handleSetDoseAsPrn}
        loading={isLoading}
      />
    </div>
  )

  return (

    <Dialog
      visible={isVisible}
      onHide={() => setIsVisible(false)}
      header={toPrnValue ? 'Change to PRN' : 'Change to Schedule'}
      footer={footer}
      icon="pi pi-exclamation-triangle"
    >
      {prnConfirmationTemplate()}
    </Dialog>

  )
}

export default PRNDialog
