import React, { useEffect, useRef } from 'react'
import { Messages } from 'primereact/messages'
import { useMySitesQuery } from '../sites/sitesHooks'
import SiteSelection from '../sites/SiteSelection'
import {
  useUpdateCaregiverMutation,
} from '../../features/Caregivers/caregiversHooks'

function CaregiverSites({
  organization, caregiver, handleSetHeader, maxDataViewHeight,
}) {
  const statusMessage = useRef()

  const {
    data: availableSites,
    isLoading: isLoadingSites,
  } = useMySitesQuery(organization.id)

  const {
    mutateAsync: updateCaregiver,
    isLoading: isUpdatingCaregiver,
  } = useUpdateCaregiverMutation(caregiver?.id, statusMessage)

  useEffect(() => {
    handleSetHeader({ primaryAction: null })
  }, [caregiver])

  const handleCaregiverSitesChange = (sites) => {
    updateCaregiver({
      caregiverSiteAccessIds: sites.map((site) => site.id),
    })
  }

  if (!caregiver) return null

  return (
    <div className="col-12">
      <Messages ref={statusMessage} />
      <SiteSelection
        availableSites={availableSites}
        userSites={caregiver.sites}
        maxDataViewHeight={maxDataViewHeight}
        onUserSitesChange={(sites) => handleCaregiverSitesChange(sites)}
        isLoading={isLoadingSites || isUpdatingCaregiver}
        editEnabled={!organization.deviceBasedAccess && !caregiver.isDirectoryManaged}
      />
    </div>
  )
}

export default CaregiverSites
