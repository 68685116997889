import React from 'react'
import { formattedTimeFromMinutes } from '@services/utils'
import { sigHeader } from '@services/utils/sig'
import { rrulestr } from 'rrule'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

function DoseConditionSchedulesCell({ hasSchedules, schedules }) {
  if (!hasSchedules) {
    return (
      <div className="flex flex-column gap-2 justify-content-center">All Schedules</div>
    )
  }

  const sortedSchedules = schedules.sort((s1, s2) => s1.time - s2.time)

  return (
    <div className="flex flex-column gap-2 justify-content-center">
      <ul>
        {sortedSchedules.map((schedule) => (
          <li key={schedule.id}>
            {formattedTimeFromMinutes(schedule.time)}
            {' '}
            {rrulestr(schedule.rrule).toText()}
          </li>
        ))}
      </ul>
    </div>
  )
}

function DoseConditionSigCell({ doseSig }) {
  let sigText = 'All Sigs'

  if (doseSig) {
    sigText = sigHeader(doseSig.sigNumber)
  }

  return (
    <div className="flex flex-column gap-2 justify-content-center">{sigText}</div>
  )
}

function DoseConditionActionsCell({ actions }) {
  let actionsText = `${actions.length} actions`

  if (actions.length === 1) {
    actionsText = '1 action'
  }

  return (
    <div className="flex flex-column gap-2 justify-content-center">{actionsText}</div>
  )
}

function DoseConditionsTable(dose, setSelectedDoseCondition) {
  const { conditions: doseConditions, prn: dosePrn } = dose

  return (
    <DataTable
      id="dose-conditions-list"
      dataKey="id"
      value={doseConditions}
      className="mt-2"
      rowClassName="fadein"
      selectionMode="single"
      sortField="conditionName"
      sortOrder={1}
      onSelectionChange={(e) => setSelectedDoseCondition(e.value)}
    >
      <Column
        key="condition_name"
        field="conditionName"
      />
      <Column
        key="dose_sig"
        field="doseSig"
        body={DoseConditionSigCell}
      />
      <Column
        key="condition_note"
        field="note"
      />
      {
        !dosePrn && (
        <Column
          key="condition_schedules"
          field="schedules"
          body={DoseConditionSchedulesCell}
        />
        )
      }
      <Column
        key="condition_actions"
        field="actions"
        body={DoseConditionActionsCell}
      />
    </DataTable>
  )
}

export default DoseConditionsTable
