import React from 'react'
import {
  Routes, Route,
} from 'react-router-dom'
import { withApp } from '@components/App'
import PageLayout, { usePageOutlet } from '@components/display/PageLayout'
import OnboardUsers from './OnboardUsers'
import OnboardUser from './OnboardUser'
import './integrations.scss'

function Integrations(props) {
  const useOnboardUsersHeader = () => usePageOutlet()

  return (
    <div className="integrations">
      <Routes>
        <Route path="/admin/pending_users" element={<PageLayout rootPath="/admin/pending_users" rootLabel="Integrations" />}>
          <Route
            index
            element={<OnboardUsers useOnboardUsersHeader={useOnboardUsersHeader} {...props} />}
          />
          <Route path="/admin/pending_users/:pendingUserId" element={<OnboardUser useOnboardUsersHeader={useOnboardUsersHeader} {...props} />} />
        </Route>
      </Routes>
    </div>
  )
}

export default withApp(Integrations)
