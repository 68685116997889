import React, { useEffect, useRef, useState } from 'react'
import { compact } from 'lodash'
import { Link, useParams } from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton'
import { Toast } from 'primereact/toast'
import { TabView, TabPanel } from 'primereact/tabview'
import { useCurrentOrganization } from '@components/App'
import { Card } from 'primereact/card'
import OnboardUserProfile from './OnboardUserProfile'
import { usePendingUser, useOnboardPendingUser, usePatientsAvailableToOnboard } from './onboardUsersHooks'
import './integrations.scss'
import OnboardExistingUser from './OnboardExistingUser'

function OnboardUser({ useOnboardUsersHeader }) {
  const organization = useCurrentOrganization()
  const statusMessage = useRef(null)
  const { setHeader } = useOnboardUsersHeader()
  const { pendingUserId } = useParams()
  const { data: pendingUser } = usePendingUser({ pendingUserId })
  const [activeIndex, setActiveIndex] = useState(0)

  const {
    data: patients,
    isLoading: isPatientsLoading,
  } = usePatientsAvailableToOnboard({
    organizationId: organization.id,
    pendingUserId,
    statusMessage,
  })

  const {
    mutateAsync: onboardPendingUser, isLoading: onboardUserLoading,
  } = useOnboardPendingUser(statusMessage)

  const handleOnboardPendingUser = async (profile) => {
    const patient = await onboardPendingUser({
      ...profile,
      id: pendingUserId,
    })

    window.location.href = `/admin/patients/${patient.id}/orders/needs-review`
  }

  const handleOnboardToExistingPatient = async (patientId) => {
    await onboardPendingUser({
      id: pendingUserId,
      patientId,
    }, {
      onSuccess: () => {
        window.location.href = `/admin/patients/${patientId}/orders/needs-review`
      },
    })
  }

  useEffect(() => {
    const title = {
      label: (
        <div className="flex gap-2 p-3 align-items-center">
          <i className="text-xl pi pi-users mr-2" />
          <span>
            {pendingUser ? `${pendingUser.firstName} ${pendingUser.lastName}` : <Skeleton width="6rem" height="2rem" /> }
          </span>
        </div>
      ),
    }

    setHeader({
      title,
      breadcrumbs: compact([
        {
          label: 'Pending Users',
          template: <Link to="/admin/pending_users">Pending Users</Link>,
        },
        {
          label: 'Profile',
          template: <span>Profile</span>,
        },
      ]),
    })
  }, [pendingUser])

  return (
    <Card className="fadein flex flex-column gap-2 m-2">
      <Toast ref={statusMessage} />
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel header={`Create ${organization.patientLabelSingular}`}>
          <OnboardUserProfile
            statusMessage={statusMessage}
            onSuccess={handleOnboardPendingUser}
            onboardUserLoading={onboardUserLoading}
            viewMatchCallBack={() => setActiveIndex(1)}
            pendingUser={pendingUser}
            patients={patients}
            isPatientsLoading={isPatientsLoading}
          />
        </TabPanel>
        <TabPanel header={`Use Existing ${organization.patientLabelSingular}`}>
          <OnboardExistingUser
            pendingUser={pendingUser}
            onPatientSelect={handleOnboardToExistingPatient}
            onboardUserLoading={onboardUserLoading}
            patients={patients}
            isPatientsLoading={isPatientsLoading}
          />
        </TabPanel>
      </TabView>
    </Card>
  )
}

export default OnboardUser
