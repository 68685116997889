import React, { useState, useEffect } from 'react'
import { useCurrentOrganization } from '@components/App'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { isEqual, orderBy } from 'lodash'

function PatientTable({
  pendingUser, patients, onPatientSelect, isLoading,
}) {
  const {
    patientBirthSexOptions: birthSexOptions,
  } = useCurrentOrganization()

  const matchColumnTemplate = (rowData) => {
    if (rowData.matchScore === 4) {
      return <i className="pi pi-check-circle text-green-500" />
    }
  }

  const matchingValueTemplate = (value) => (
    <div className="flex flex-row gap-2">
      <i className="pi pi-check text-green-500" />
      <span className="font-semibold">{value}</span>
    </div>
  )

  const fieldColumnTemplate = (patientValue, pendingUserValue, displayValue) => {
    if (!patientValue || !pendingUserValue) return displayValue

    const matchValueWithPendingUser = isEqual(
      patientValue.toUpperCase(),
      pendingUserValue.toUpperCase(),
    )

    if (matchValueWithPendingUser) return matchingValueTemplate(displayValue)

    return displayValue
  }

  return (
    <DataTable
      value={patients}
      selectionMode="single"
      onSelectionChange={(e) => onPatientSelect(e.value)}
      dataKey="id"
      loading={isLoading}
      sortField="matchScore"
      sortOrder={-1}
    >
      <Column body={matchColumnTemplate} header="" style={{ width: '3%' }} />
      <Column
        header="First Name"
        body={(patient) => fieldColumnTemplate(
          patient.firstName,
          pendingUser.firstName,
          patient.firstName,
        )}
        style={{ width: '15%' }}
      />
      <Column
        header="Last Name"
        body={(patient) => fieldColumnTemplate(
          patient.lastName,
          pendingUser.lastName,
          patient.lastName,
        )}
        style={{ width: '15%' }}
      />
      <Column
        header="Date of Birth"
        body={(patient) => fieldColumnTemplate(
          patient.dateOfBirth,
          pendingUser.dateOfBirth,
          patient.dateOfBirth,
        )}
        style={{ width: '10%' }}
      />
      <Column
        header="Birth Sex"
        body={(patient) => {
          const patientSexDisplay = birthSexOptions.find(
            (option) => option.value === patient.birthSex,
          )?.label
          return fieldColumnTemplate(patient.birthSex, pendingUser.birthSex, patientSexDisplay)
        }}
        style={{ width: '10%' }}
      />
      <Column
        field="site.name"
        header="Site"
        style={{ width: '10%' }}
      />
    </DataTable>
  )
}

function ConfirmMatchDialog({ pendingUser, patient, organization }) {
  const selectedUsers = [pendingUser, patient]
  return (
    <div className="flex flex-column w-full gap-3">
      <div>
        <i className="pi pi-exclamation-triangle" />
        <span className="ml-2">Are you sure you want to match the following two users?</span>
      </div>
      <DataTable value={selectedUsers} showGridlines tableStyle={{ minWidth: '50rem' }}>
        <Column field="type" header="" />
        <Column field="firstName" header="First Name" />
        <Column field="lastName" header="Last Name" />
        <Column field="dateOfBirth" header="Date of Birth" />
        <Column header="Birth Sex" body={(user) => organization.patientBirthSexOptions.find((option) => option.value === user.birthSex)?.label} />
      </DataTable>
    </div>
  )
}

function OnboardExistingUser({
  pendingUser, onPatientSelect, onboardUserLoading, patients, isPatientsLoading,
}) {
  const organization = useCurrentOrganization()
  const [patientsWithMatchScore, setPatientsWithMatchScore] = useState([])

  // add match score to each patient
  useEffect(() => {
    if (!patients || !pendingUser) return
    setPatientsWithMatchScore(orderBy(patients, ['matchScore', 'lastName'], ['desc']))
  }, [isPatientsLoading, pendingUser])

  const onPatientRowClick = (patient) => {
    const seletedPatient = { ...patient, type: `Existing ${organization.patientLabelSingular}` }
    const typeAddedPendingUser = { ...pendingUser, type: 'Pending User' }
    confirmDialog({
      message: <ConfirmMatchDialog
        pendingUser={typeAddedPendingUser}
        patient={seletedPatient}
        organization={organization}
      />,
      header: 'Confirmation',
      accept: () => onPatientSelect(patient.id),
    })
  }

  return (
    <div>
      <ConfirmDialog />
      <div className="flex flex-column gap-2">
        <span className="text-700 text-base">
          Select a
          {' '}
          {organization.patientLabelSingular}
          {' '}
          to onboard
        </span>
        <PatientTable
          patients={patientsWithMatchScore}
          onPatientSelect={onPatientRowClick}
          isLoading={isPatientsLoading || onboardUserLoading}
          pendingUser={pendingUser}
        />
      </div>
    </div>
  )
}

export default OnboardExistingUser
