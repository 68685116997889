import { Column } from '@components/display/Box/Box.stories'
import { momentFormats } from '@services/utils/moment'
import moment from 'moment'
import { DataTable } from 'primereact/datatable'
import React, { memo, useState } from 'react'
import { Button } from 'primereact/button'
import DetailsDialog from './DetailsDialog'

const Table = memo(({
  integrationData,
  isLoading,
  showFailed = true,
  showIgnored = true,
  showProcessed = true,
  onRowSelect,
  scrollHeight,
}) => {
  const dateTemplate = (rowData, column) => {
    const date = rowData[column.field]
    return (date ? moment(date).format(momentFormats.datetime_12) : '')
  }

  const failedColumns = [
    <Column field="errorCode" header="Error Code" key="errorCode" />,
    <Column field="failedAt" header="Failed" body={dateTemplate} key="failedAt" sortable />,
    <Column
      field="failureMessage"
      header="Fail Message"
      key="failureMessage"
      style={{
        width: '300px', maxWidth: '300px', wordWrap: 'break-word', minWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis',
      }}
    />,
  ]

  const ignoredColumns = [
    <Column field="ignoreCode" header="Ignore Code" key="ignoreCode" />,
    <Column field="ignoredAt" header="Ignored" body={dateTemplate} key="ignoredAt" sortable />,
  ]

  const detailsButtonTemplate = (rowData) => (
    <Button
      label="View Data"
      className="p-button-text p-button-sm"
      onClick={() => onRowSelect(rowData.id)}
    />
  )

  return (
    <DataTable
      value={integrationData}
      loading={isLoading}
      sortField="createdAt"
      sortOrder={-1}
      sortMode="single"
      virtualScrollerOptions={{ itemSize: 50 }}
      scrollable
      scrollHeight={scrollHeight}
    >
      <Column field="id" header="ID" />
      <Column field="patientExternalId" header="Patient ID" />
      <Column field="messageCode" header="Type" />
      {showProcessed && <Column field="processedAt" header="Processed" body={dateTemplate} sortable />}
      {showFailed && failedColumns}
      {showIgnored && ignoredColumns}
      <Column field="messageDatetime" header="Message Date" body={dateTemplate} sortable />
      <Column field="createdAt" header="Created" body={dateTemplate} sortable />
      <Column body={detailsButtonTemplate} header="" />
    </DataTable>
  )
})

function IntegrationDataTable({
  integrationData,
  isLoading,
  showFailed = true,
  showIgnored = true,
  showProcessed = true,
  scrollHeight = 'calc(100vh - 600px)',
}) {
  const [selectedIntegrationDataId, setSelectedIntegrationDataId] = useState(null)

  const onRowSelect = (rowData) => {
    setSelectedIntegrationDataId(rowData)
  }

  return (
    <>
      <DetailsDialog
        isVisible={selectedIntegrationDataId !== null}
        hide={() => setSelectedIntegrationDataId(null)}
        integrationDataId={selectedIntegrationDataId}
      />
      <Table
        integrationData={integrationData}
        isLoading={isLoading}
        showFailed={showFailed}
        showIgnored={showIgnored}
        showProcessed={showProcessed}
        onRowSelect={onRowSelect}
        scrollHeight={scrollHeight}
      />
    </>
  )
}

export default IntegrationDataTable
