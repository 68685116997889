import React, { useState, useEffect, useRef } from 'react'
import { Divider } from 'primereact/divider'
import { Toast } from 'primereact/toast'
import { Card } from 'primereact/card'
import MedicationFieldset from '@components/clientDoses/Medication/Fieldset'
import DosingFieldset from '@components/clientDoses/Dosing/Fieldset'
import ScheduleFieldset from '@components/clientDoses/Schedule/Fieldset'
import AdditionalInfoFieldset from '@components/clientDoses/AdditionalInfo/Fieldset'
import PrescribingPhysician from '@components/clientDoses/PrescribingPhysician/Fieldset'
import DoseConditionsFieldset from '@components/clientDoses/DoseCondition/Fieldset'
import { get, isEmpty } from 'lodash'
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'
import {
  usePatientDose,
  useCreateMedicine,
  useUpsertDose,
  useCreateDoseSchedule,
  useDestroyDoseSchedule,
  useUpdateDoseSchedule,
  useDoseSchedules,
} from './clientDosesHooks'

function UpsertClientDoses({ clientId, layoutRef }) {
  const medicationStatusMessage = useRef(null)
  const doseStatusMessage = useRef(null)
  const scheduleStatusMessage = useRef(null)
  const navigate = useNavigate()
  const [activeField, setActiveField] = useState('medication')
  const [medication, setMedication] = useState({})
  const [dose, setDose] = useState({})
  const [doseSig, setDoseSig] = useState({})
  const [schedule, setSchedule] = useState({})
  const [additionalInfo, setAdditionalInfo] = useState({})
  const [prescribingPhysician, setPrescribingPhysician] = useState({})

  const {
    mutateAsync: createMedicine,
  } = useCreateMedicine(medicationStatusMessage)

  const {
    mutateAsync: upsertDose,
  } = useUpsertDose({
    patientId: clientId,
    statusMessage: doseStatusMessage,
  })

  const {
    mutateAsync: createSchedule,
  } = useCreateDoseSchedule(scheduleStatusMessage)

  const {
    mutateAsync: destroySchedule,
  } = useDestroyDoseSchedule(scheduleStatusMessage)

  const {
    mutateAsync: updateSchedule,
  } = useUpdateDoseSchedule(scheduleStatusMessage)

  const { data: scheduleData } = useDoseSchedules(get(dose, 'id'))

  const query = usePatientDose({ clientId, doseId: get(dose, 'id') })

  useEffect(() => {
    if (query.data) {
      const {
        medicine,
        schedules,
        pharmacyName,
        pharmacyPhone,
        walkInRx,
        physicianName,
        physicianPhone,
        ...rest
      } = query.data
      const pharmacyInfo = { name: pharmacyName, phone: pharmacyPhone }
      const physicianInfo = { name: physicianName, phone: physicianPhone }
      setMedication(medicine)
      setDose(rest)
      setDoseSig(rest.sigs[0])
      setSchedule(schedules)
      setAdditionalInfo({ pharmacyInfo, walkInRx })
      setPrescribingPhysician({ physicianInfo })
    }
  }, [query.data])

  useEffect(() => {
    setSchedule(scheduleData)
  }, [scheduleData])

  const handleMedicationCompletion = async (medicineFields) => {
    if (medicineFields.id) {
      setMedication(medicineFields)
    } else {
      const result = await createMedicine(medicineFields)
      // Have to propagate this to ensure purpose gets saved on the dose
      result.purpose = medicineFields.purpose
      setMedication(result)
    }

    setTimeout(() => (setActiveField('dosing')), 1000)
  }

  const handleDosingCompletion = async (doseFields) => {
    const result = await upsertDose({
      ...doseFields, id: dose.id, medicineId: medication.id, purpose: medication.purpose,
    })
    setDose(result)
    setTimeout(() => (setActiveField('schedule')), 1000)
  }

  const handleAdditionalInfoCompletion = async (additionalInfoFields) => {
    upsertDose({
      id: dose.id, ...additionalInfoFields,
    }, {
      onSuccess: () => {
        setTimeout(() => (setActiveField('prescribingPhysician')), 1000)
      },
    })
  }

  const handleScheduleCompletion = async (schedulesFields) => {
    if (schedulesFields.prn) {
      await upsertDose({
        id: dose.id,
        schedules: [],
        prn: true,
        ...schedulesFields,
      })
    }

    setTimeout(() => (setActiveField('additionalInfo')), 1000)
  }

  const handleDoseCalendarCompletion = async (schedulesFields) => {
    await createSchedule(schedulesFields)
  }

  const handlePrescribingPhysicianCompletion = async (prescribingPhysicianFields) => {
    upsertDose({
      id: dose.id, ...prescribingPhysicianFields,
    })
  }

  const handleNavigation = () => {
    setTimeout(() => navigate(`../manage/${dose.id}`), 1000)
  }

  const scheduleStepComplete = (!isEmpty(schedule) || dose.prn)

  return (
    <Card className="h-full xl:col-10">
      <div className="flex flex-column gap-3 text-base text-900">
        <div className="flex flex-row justify-space-between align-items-center px-1">
          <span className="text-lg">
            Create Dose
          </span>
        </div>
      </div>
      <Divider />
      <div className="flex flex-column gap-4">
        <Toast ref={medicationStatusMessage} />
        <MedicationFieldset
          clientId={clientId}
          medication={medication}
          setActiveField={setActiveField}
          isEdit={activeField === 'medication'}
          handleCompletion={handleMedicationCompletion}
        />
        <Toast ref={doseStatusMessage} />
        <DosingFieldset
          layoutRef={layoutRef}
          clientId={clientId}
          dose={dose}
          medicine={medication}
          setActiveField={setActiveField}
          isEdit={activeField === 'dosing'}
          handleCompletion={handleDosingCompletion}
        />
        <Toast ref={scheduleStatusMessage} />
        <ScheduleFieldset
          clientId={clientId}
          dose={dose}
          doseSig={doseSig}
          schedules={schedule}
          setActiveField={setActiveField}
          isEdit={activeField === 'schedule'}
          handleCompletion={handleScheduleCompletion}
          handleUpdateSchedule={updateSchedule}
          handleDestroySchedule={destroySchedule}
          handleDoseCalendarCompletion={handleDoseCalendarCompletion}
        />
        <AdditionalInfoFieldset
          dose={dose}
          schedule={schedule}
          setActiveField={setActiveField}
          isEdit={activeField === 'additionalInfo'}
          additionalInfo={additionalInfo}
          handleCompletion={handleAdditionalInfoCompletion}
          scheduleStepComplete={scheduleStepComplete}
        />
        <PrescribingPhysician
          dose={dose}
          schedule={schedule}
          setActiveField={setActiveField}
          isEdit={activeField === 'prescribingPhysician'}
          prescribingPhysician={prescribingPhysician}
          handleCompletion={handlePrescribingPhysicianCompletion}
          scheduleStepComplete={scheduleStepComplete}
        />
        <DoseConditionsFieldset
          doseSchedules={schedule}
          dose={dose}
          scheduleStepComplete={scheduleStepComplete}
        />
      </div>
      <div className="flex flex-row justify-content-end mt-4">
        <Button
          label="Done"
          className="p-button-sm"
          onClick={handleNavigation}
          disabled={!scheduleStepComplete}
        />
      </div>
    </Card>
  )
}

export default UpsertClientDoses
