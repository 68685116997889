import React from 'react'
import { filter } from 'lodash'
import { SelectButton } from 'primereact/selectbutton'
import { Section } from './SharedComponents'
import { ExistingDoseRow } from './DoseComponents'

function OverviewSection({
  doseDirectives,
  patientId,
  prescription,
  selectedDirective,
  setSelectedDirectiveCode,
}) {
  if (!doseDirectives || doseDirectives.length === 0 || !prescription || !selectedDirective) {
    return null
  }

  const foo = filter(doseDirectives, (directive) => directive.text.option)

  const dropdownOptions = foo.map((directive) => ({
    label: directive.text.option,
    value: directive.code,
  }))

  const text = `Choose how to handle the prescription changes.
    The default was chosen based on the current doses.
  `

  return (
    <Section header="Overview" helpText={text}>
      <div className="flex flex-row justify-content-center">
        <SelectButton
          allowEmpty={false}
          onChange={(e) => setSelectedDirectiveCode(e.value)}
          options={dropdownOptions}
          placeholder="Select an Action"
          value={selectedDirective.code}
        />
      </div>
      <div className="flex flex-row justify-content-center">
        <span className="opacity-70">{selectedDirective.text.info}</span>
      </div>
      {
        selectedDirective.matchId && (
          <ExistingDoseRow
            patientId={patientId}
            doseId={selectedDirective.matchId}
          />
        )
      }
    </Section>
  )
}

export default OverviewSection
