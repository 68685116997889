import React, { useEffect, useRef, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useNavigate } from 'react-router-dom'
import { Messages } from 'primereact/messages'
import { Card } from 'primereact/card'
import { useCurrentOrganization } from '@components/App'
import SearchField from '@components/display/Form/SearchField'
import { fuzzySearch } from '@services/utils/search'
import { useUnmappedIntegrationFacilitiesQuery } from '../Facilities/facilitiesHooks'

function Title({
  searchTerm, setSearchTerm, isLoading,
}) {
  return (
    <div className="flex flex-row gap-4 px-4 pb-2 pt-2 align-items-center">
      <i className="text-xl pi pi-building" />
      <div className="flex flex-column gap-1">
        <span>
          Unmapped Facilities
        </span>
        <span className="text-sm">
          Last 3 months
        </span>
      </div>
      <SearchField
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder="Search Facility by external ID or Pharmacy name"
        isLoading={isLoading}
      />
    </div>
  )
}

function UnmappedFacilitiesTable({ useFacilityHeader }) {
  const organization = useCurrentOrganization()
  const navigate = useNavigate()
  const statusMessage = useRef(null)

  const { setHeader, maxDataViewHeight } = useFacilityHeader()

  const [searchLoading, setSearchLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredUnmappedFacilities, setFilteredUnmappedFacilities] = useState([])

  const {
    data: unmappedFacilities,
    isLoading,
  } = useUnmappedIntegrationFacilitiesQuery(statusMessage, organization.id)

  useEffect(() => {
    setSearchLoading(true)
    if (searchTerm) {
      setFilteredUnmappedFacilities(fuzzySearch(unmappedFacilities, ['facilityExternalId', 'pharmacyExternalId']), searchTerm)
    } else {
      setFilteredUnmappedFacilities(unmappedFacilities)
    }
    setSearchLoading(false)
  }, [unmappedFacilities, searchTerm])

  const title = {
    label: (
      <Title
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isLoading={searchLoading}
      />
    ),
  }

  const footTemplate = () => (
    <span className="text-sm ml-1 my-0 py-0">
      {!isLoading ? `Facilities Count: ${filteredUnmappedFacilities.length}` : ''}
    </span>
  )

  useEffect(() => {
    setHeader({
      title, tabs: [], breadcrumbs: [],
    })
  }, [searchTerm, searchLoading])

  return (
    <div className="col-12">
      <Messages ref={statusMessage} />
      <Card className="h-full" footer={footTemplate}>
        <div className="flex flex-column gap-3 text-base text-900">
          <DataTable
            dataKey="id"
            loading={isLoading}
            value={filteredUnmappedFacilities}
            selectionMode="single"
            onSelectionChange={(e) => navigate(`/admin/integrations/map/${e.value.facilityExternalId}?pharmacyExternalId=${e.value.pharmacyExternalId}`)}
            sortField="lastName"
            sortOrder={1}
            className="mt-1"
            rowClassName="fadein facility-row"
            scrollable
            scrollHeight={maxDataViewHeight ? (maxDataViewHeight - 10) : null}
          >
            <Column header="External ID" field="facilityExternalId" sortable />
            <Column header="Pharmacy External ID" field="pharmacyExternalId" sortable />
            <Column header="Messages #" field="messageCount" sortable />
          </DataTable>
        </div>
      </Card>
    </div>
  )
}

export default UnmappedFacilitiesTable
