import React, { useEffect, useState } from 'react'
import { map } from 'lodash'
import { Card } from 'primereact/card'
import { classNames } from 'primereact/utils'
import { exactSearch } from '@services/utils/search'

function Patient({
  createSessionLoading, selected, name, id, setPaitent,
}) {
  const [highlight, setHighlight] = useState(selected)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(createSessionLoading)
  }, [createSessionLoading])

  const handleSelection = () => {
    setHighlight(true)
    setLoading(true)
    setTimeout(() => {
      setPaitent({ name, id })
      setHighlight(false)
    }, 300)
  }

  return (
    <div className="col-12 md:col-4 xl:col-3">
      <Card className={classNames('w-full h-full provider', { highlight })} onClick={handleSelection}>
        <div className="flex flex-row justify-space-between">
          <div className="flex flex-column gap-3">
            <div className="text-sm text-900">
              {name}
            </div>
          </div>
          { loading && <i className="pi pi-spin pi-spinner" /> }
          { !loading && <i className="pi pi-check-square" /> }
        </div>
      </Card>
    </div>
  )
}

function PatientsSelection({
  selectedPatient,
  patients,
  patientsLoading,
  createSessionLoading,
  setPaitent,
  searchTerm,
  setPlaceholder,
}) {
  const [filteredPatients, setFilteredPatients] = useState([])

  useEffect(() => {
    setPlaceholder('Search by name')
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (searchTerm) {
      setFilteredPatients(exactSearch(patients, ['firstName', 'lastName'], searchTerm))
    } else {
      setFilteredPatients(patients)
    }
  }, [patients, searchTerm])

  if (patientsLoading) {
    return 'Loading...'
  }

  return (
    <div className="providers flex flex-row flex-wrap">
      {
        map(filteredPatients, ({ firstName, lastName, id }) => (
          <Patient
            key={id}
            createSessionLoading={createSessionLoading}
            selected={id === selectedPatient?.id}
            id={id}
            name={`${firstName} ${lastName}`}
            setPaitent={setPaitent}
          />
        ))
      }
    </div>
  )
}

export default PatientsSelection
