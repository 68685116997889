import React from 'react'
import PharmacyOrdersTable from '@components/pharmacyOrders/Table/PharmacyOrdersTable'
import { useDosePharmacyOrders } from '../../../features/ClientDoses/PharmacyOrders/pharmacyOrderHooks'

function DosePharmacyOrders({ doseId }) {
  const {
    data: pharmacyOrders,
    isLoading,
  } = useDosePharmacyOrders(doseId)

  return (
    <PharmacyOrdersTable
      pharmacyOrders={pharmacyOrders}
      isLoading={isLoading}
      showDoseId={false}
    />
  )
}

export default DosePharmacyOrders
