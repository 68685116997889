import React, { useEffect, useRef, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Link, useNavigate } from 'react-router-dom'
import { Messages } from 'primereact/messages'
import { Card } from 'primereact/card'
import { useCurrentOrganization } from '@components/App'
import SearchField from '@components/display/Form/SearchField'
import { exactSearch } from '@services/utils/search'
import { useIntegrationFacilitiesQuery } from './facilitiesHooks'

function Title({
  searchTerm, setSearchTerm, isLoading,
}) {
  return (
    <div className="flex flex-row gap-4 px-4 pb-2 pt-2 align-items-center">
      <span>
        <i className="text-xl pi pi-building mr-2" />
        Facilities
      </span>
      <SearchField
        className="w-24rem"
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder="Search by external ID or Pharmacy"
        isLoading={isLoading}
      />
    </div>
  )
}

function FacilitiesTable({ useFacilityHeader }) {
  const organization = useCurrentOrganization()
  const navigate = useNavigate()
  const statusMessage = useRef(null)

  const { setHeader, maxDataViewHeight } = useFacilityHeader()

  const [searchLoading, setSearchLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredFacilities, setFilteredFacilities] = useState([])

  const {
    data: facilities,
    isLoading,
  } = useIntegrationFacilitiesQuery(statusMessage, organization.id)

  useEffect(() => {
    setSearchLoading(true)
    if (searchTerm) {
      setFilteredFacilities(exactSearch(facilities, ['externalId', 'pharmacyName'], searchTerm))
    } else {
      setFilteredFacilities(facilities)
    }
    setSearchLoading(false)
  }, [facilities, searchTerm])

  const title = {
    label: (
      <Title
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isLoading={searchLoading}
      />
    ),
  }

  const primaryAction = (
    <Link to="/admin/facilities/create">
      <Button className="p-button-sm" label="Create" />
    </Link>
  )

  const footTemplate = () => (
    <span className="text-sm ml-1 my-0 py-0">
      {!isLoading ? `Facilities Count: ${filteredFacilities.length}` : ''}
    </span>
  )

  useEffect(() => {
    setHeader({
      title, tabs: [], breadcrumbs: [], primaryAction,
    })
  }, [searchTerm, searchLoading])

  return (
    <div className="col-12">
      <Messages ref={statusMessage} />
      <Card className="h-full" footer={footTemplate}>
        <div className="flex flex-column gap-3 text-base text-900">
          <DataTable
            dataKey="id"
            loading={isLoading}
            value={filteredFacilities}
            selectionMode="single"
            onSelectionChange={(e) => navigate(`/admin/facilities/${e.value.id}`)}
            sortField="lastName"
            sortOrder={1}
            className="mt-1"
            rowClassName="fadein facility-row"
            scrollable
            scrollHeight={maxDataViewHeight ? (maxDataViewHeight - 10) : null}
          >
            <Column header="ID" field="id" sortable />
            <Column header="External ID" field="externalId" sortable />
            <Column header="Pharmacy" field="pharmacyName" sortable />
            <Column header="Message Count" field="integrationDataCount" sortable />
          </DataTable>
        </div>
      </Card>
    </div>
  )
}

export default FacilitiesTable
