import React, { useState } from 'react'
import { find, get, isEmpty } from 'lodash'
import { Button } from 'primereact/button'
import { SplitButton } from 'primereact/splitbutton'
import { Divider } from 'primereact/divider'
import { classNames } from 'primereact/utils'
import Calendar, { formatSchedulesForDisplay } from '@components/display/Calendar/Calendar'
import TimeSelectionDialog from '@components/clientDoses/Schedule/TimeSelectionDialog'
import PRNDialog from '@components/clientDoses/Schedule/PRNDialog'
import { formatAdministrationAmount, hasScheduleSig } from '@components/clientDoses/doseUtils'
import { timeTemplate } from '@services/utils'

const eventContent = ({ event }) => {
  const { dose, doseSig, expectedAt } = event.extendedProps

  const administrationAmountDisplay = formatAdministrationAmount({
    // TODO: Change to doseSig?.administrationAmount after backfilling dose sigs
    administrationAmount: get((doseSig || dose), 'administrationAmount'),
    medicine: get(dose, 'medicine'),
    units: get(doseSig || dose, 'units'),
  })

  return (
    <div className="flex flex-column gap-1 text-xs font-normal">
      <div className="flex flex-row gap-1 align-items-center">
        <i className="fa-regular fa-clock" />
        <span>{timeTemplate(expectedAt)}</span>
      </div>
      <span>{administrationAmountDisplay}</span>
    </div>
  )
}

function DoseCalendar({
  headingTextClassName = 'text-lg',
  controlButtonClassName = '',
  clientId,
  schedules,
  dose,
  doseSig,
  handleSetPrn,
  handleSetPrnLoading,
  handleCreateSchedule,
  handleUpdateSchedule,
  handleDestroySchedule,
  showAddTimeControls = true,
}) {
  const events = formatSchedulesForDisplay({ schedules, dose, includeDuration: false })
  const [dosePrnDialogVisible, setDosePrnDialogVisible] = useState(false)
  const isPrn = !hasScheduleSig(dose)

  const prnControls = ({ handleShowDialog }) => (
    <Button
      label="Mark as PRN"
      icon="fa-solid fa-triangle-exclamation"
      className="p-button-sm p-button-warning p-button-text w-full"
      onClick={handleShowDialog}
    />
  )

  const timeSelectionDialog = (controls) => (
    <TimeSelectionDialog
      clientId={clientId}
      dose={dose}
      doseSig={doseSig}
      handleCreateSchedule={handleCreateSchedule}
      handleUpdateSchedule={handleUpdateSchedule}
      handleDestroySchedule={handleDestroySchedule}
      render={controls}
    />
  )

  const dateNavigationControls = (calendar) => {
    const titleTemplate = get(calendar, 'data.viewTitle')

    return (
      <div className="flex flex-row gap-2 align-items-center">
        {
          !isPrn && (
            <div className="flex flex-row">
              <Button
                icon="pi pi-chevron-left"
                onClick={calendar.handlePrevious}
                className="p-button-text p-button-sm p-button-secondary"
              />
              <Button
                icon="pi pi-chevron-right"
                onClick={calendar.handleNext}
                className="p-button-text p-button-sm p-button-secondary"
              />
            </div>
          )
        }
        <span className={classNames('font-normal ml-1', { [headingTextClassName]: headingTextClassName })}>
          {titleTemplate}
        </span>
      </div>
    )
  }

  const controls = (calendar) => {
    const ControlComponent = !handleSetPrn ? Button : SplitButton

    const addTimeControls = ({ handleAddTime }) => (
      <ControlComponent
        label="Add Schedule Time"
        icon="fa-solid fa-calendar-plus"
        className={classNames('p-button-sm', { [controlButtonClassName]: controlButtonClassName })}
        onClick={handleAddTime}
        model={
          handleSetPrn
          && [{ template: prnControls({ handleShowDialog: () => setDosePrnDialogVisible(true) }) }]
        }
      />
    )

    return (
      <div className="flex flex-column gap-3 text-base text-900">
        <div className="flex flex-row justify-content-between align-items-center">
          <span className={classNames('flex flex-row gap-2 align-items-center', { [headingTextClassName]: headingTextClassName })}>
            {handleSetPrn && isPrn ? 'PRN' : handleSetPrn && !isPrn && 'Schedule'}
            {!isEmpty(events) && dateNavigationControls(calendar)}
          </span>
          {
            showAddTimeControls && (
              <div className="flex flex-row gap-2">
                <div>
                  {timeSelectionDialog(addTimeControls)}
                </div>
              </div>
            )
          }
        </div>
        {!isEmpty(events) && <Divider className="my-0" />}
      </div>
    )
  }

  const eventSelectionHandler = (handleEditorShow) => ({ event }) => {
    const eventSchedule = find(schedules, (schedule) => schedule.id === Number(event.id))
    handleEditorShow(eventSchedule)
  }

  return timeSelectionDialog(({ handleEditorShow }) => (
    <div data-testid="dose-calendar">
      {
        handleSetPrn
        && (
        <PRNDialog
          isVisible={dosePrnDialogVisible}
          setIsVisible={setDosePrnDialogVisible}
          handleDosePrnChange={handleSetPrn}
          isLoading={handleSetPrnLoading}
        />
        )
      }
      <Calendar
        showCalendar={!isEmpty(events)}
        initialView="dayGridWeek"
        eventContent={eventContent}
        events={events}
        renderControls={controls}
        handleEventSelection={eventSelectionHandler(handleEditorShow)}
      />
      {
        isPrn && (
          <span className="mt-4">
            This dose is a PRN and is administered on an as needed basis.
          </span>
        )
      }
    </div>
  ))
}

export default DoseCalendar
