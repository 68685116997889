import React, { useState, useEffect } from 'react'
import {
  first, isEmpty, get, startCase,
} from 'lodash'
import { useNavigate } from 'react-router-dom'
import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Divider } from 'primereact/divider'
import DoseTag from '@components/clientDoses/Header/DoseTag'
import { calculateDoseStatus, calculateDoseType, formatAdministrationAmount } from '@components/clientDoses/doseUtils'
import { csaScheduleLabel } from '@components/medicines/csaScheduleLabel'
import SearchField from '@components/display/Form/SearchField'
import { momentFormats, momentoToLocalWithoutChangingTime } from '@services/utils/moment'
import moment from 'moment'
import { useCurrentOrganization } from '@components/App'
import { fuzzySearch } from '@services/utils/search'
import { useClientDoses } from './clientDosesHooks'

const dateCellTemplate = (datestring, timezone) => (
  <div className="flex flex-column gap-2">
    {datestring ? moment(datestring).tz(timezone).format(momentFormats.dateYear) : null}
  </div>
)

function DosesList({ clientId, maxDataViewHeight, filters = [] }) {
  const navigate = useNavigate()
  const [doses, setDoses] = useState([])
  const [searchTerm, setSearchTerm] = useState()
  const organization = useCurrentOrganization()
  const { timezone } = organization

  const appliedFilters = filters.reduce((result, filter) => {
    const newResult = result
    newResult[filter.name] = {
      value: filter.value,
      matchMode: FilterMatchMode.EQUALS,
    }
    return newResult
  }, {})

  const query = useClientDoses(clientId)

  const medicineTemplate = ({ orderText, medicine }) => (
    <div className="flex flex-column gap-2">
      { !isEmpty(orderText) ? <span>{orderText}</span> : (
        <>
          <span>{medicine.name}</span>
          <span>{medicine.strength}</span>
        </>
      )}
    </div>
  )

  const administrationAmountTemplate = ({ administrationAmount, units, medicine }) => {
    const display = formatAdministrationAmount({ administrationAmount, units, medicine }).split('(')
    return (
      <div className="flex flex-column gap-2">
        <span>{first(display)}</span>
        { get(display, 1)
          && <span>{`(${get(display, 1)}`}</span>}
      </div>
    )
  }

  const doseTypeTemplate = (dose) => {
    const doseType = calculateDoseType(dose)
    return (
      <DoseTag color={doseType.color} text={doseType.text} />
    )
  }

  const expirationDateTemplate = ({ expiration }) => {
    if (!expiration) {
      return 'N/A'
    }
    return momentoToLocalWithoutChangingTime(moment(expiration)).format(momentFormats.monthDay)
  }

  const statusTemplate = ({ status }) => (
    <DoseTag color={status.color} text={startCase(status.text)} />
  )

  const attributesTemplate = ({ medicine }) => (
    <div className="flex flex-column gap-2">
      {medicine.csaSchedule
        && csaScheduleLabel(medicine.csaSchedule)}
    </div>
  )

  useEffect(() => {
    if (!isEmpty(query.data)) {
      const tableDoses = query.data.map((dose) => ({
        ...dose,
        status: calculateDoseStatus(dose),
        activatedAt: !!dose.activatedAt,
        daysScheduled: Object.keys(dose.schedules).length,
      }))
      setDoses(fuzzySearch(tableDoses, ['medicine.name'], searchTerm))
    }
  }, [query.data, searchTerm])

  return (
    <div className="flex flex-column gap-3 fadein">
      <SearchField
        className="w-19rem"
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder="Search Doses by Medicine name"
      />
      <Divider className="my-0" />
      <DataTable
        dataKey="id"
        loading={query.isLoading}
        value={doses}
        filters={appliedFilters}
        sortField="status.order"
        sortOrder={1}
        selectionMode="single"
        onSelectionChange={({ value }) => navigate(`../manage/${value.id}`)}
        autoLayout
        scrollable
        scrollHeight={maxDataViewHeight + 35}
      >
        <Column
          header="Status"
          field="status"
          sortField="status.order"
          sortable
          body={statusTemplate}
        />
        <Column
          header="Medicine"
          field="medicine"
          sortable
          sortField="medicine.name"
          style={{ minWidth: '180px', maxWidth: '180px' }}
          body={medicineTemplate}
        />
        <Column
          header="Administration Amount"
          field="administrationAmount"
          style={{ minWidth: '180px', maxWidth: '180px' }}
          body={administrationAmountTemplate}
        />
        <Column
          header="End At"
          field="endAt"
          body={({ endAt }) => dateCellTemplate(endAt, timezone)}
        />
        <Column
          header="Expiration Date"
          field="expiration"
          body={expirationDateTemplate}
        />
        <Column
          header="Days Scheduled"
          field="daysScheduled"
        />
        <Column
          header="Dose Type"
          field="doseType"
          body={doseTypeTemplate}
        />
        <Column
          header="Attributes"
          field="medicine"
          style={{ minWidth: '180px', maxWidth: '180px' }}
          body={attributesTemplate}
        />
        <Column
          hidden
          field="activatedAt"
        />
      </DataTable>
    </div>
  )
}

export default DosesList
