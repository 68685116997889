import { useMemo } from 'react'
import { map, get } from 'lodash'
import { useQuery, useMutation } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

export function usePatientMedicalConditions(patientId, statusMessage) {
  const query = useQuery({
    queryKey: ['patientMedicalConditions', patientId],
    queryFn: () => ApiClient.get(`/patients/${patientId}/medical_conditions`),
    onError: (error) => {
      statusMessage.current.show([
        {
          severity: 'error',
          sticky: true,
          summary: `Unable to load medical conditions at this time. ${error.message}`,
        },
      ])
    },
  })
  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data'), (medicalCondition) => (medicalCondition)),
  }), [query.data])
}

export function useUpdatePatientMedicalConditionMutation(patientId, statusMessage) {
  return useMutation({
    mutationFn: (medicalCondition) => ApiClient.put(`/patients/${patientId}/medical_conditions/${medicalCondition.id}`, medicalCondition),
    onError: (error) => {
      statusMessage.current.show([
        {
          severity: 'error',
          sticky: true,
          summary: `Unable to update medical condition at this time. ${error.message}`,
        },
      ])
    },
    onSuccess: () => {
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Medical condition updated successfully.',
        },
      ])
    },
  })
}

export function useCreatePatientMedicalConditionMutation(patientId, statusMessage) {
  return useMutation({
    mutationFn: (medicalCondition) => ApiClient.post(`/patients/${patientId}/medical_conditions`, medicalCondition),
    onError: (error) => {
      statusMessage.current.show([
        {
          severity: 'error',
          sticky: true,
          summary: `Unable to create medical condition at this time. ${error.message}`,
        },
      ])
    },
    onSuccess: () => {
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Medical condition created successfully.',
        },
      ])
    },
  })
}

export function useDeletePatientMedicalConditionMutation(patientId) {
  return useMutation({ mutationFn: (medicalConditionId) => ApiClient.delete(`/patients/${patientId}/medical_conditions/${medicalConditionId}`) })
}
