import React, { useState, useEffect, useRef } from 'react'
import { map, get } from 'lodash'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import PatientDashboardCard from '@components/ccm/PatientDashboardCard'
import ContentBlock from '@components/display/ContentBlock'
import ExportBillingReportDialog from '@components/ccm/ExportBillingReportDialog'
import { usePageOutlet } from '@components/display/PageLayout'
import { useCurrentUser, useCurrentOrganization } from '@components/App'
import { Link } from 'react-router-dom'
import ExportClinicalReportDialog from '@components/ccm/ExportClinicalReportDialog'
import SearchField from '@components/display/Form/SearchField'
import { exactSearch } from '@services/utils/search'
import {
  useExportBillingReport,
  useExportClinicalReport,
} from './ccmHooks'

function PatientsMenu({ searchTerm, setSearchTerm }) {
  const { patientLabelPlural } = useCurrentOrganization()

  return (
    <div className="p-menubar bg-white flex flex-wrap justify-content-between align-items-center px-3 py-3 md:py-2">
      <div className="font-semi-bold text-lg text-900 ml-2 md:ml-0">
        <i className="pi pi-users mr-2" />
        {patientLabelPlural}
      </div>
      <div className="mt-2 md:mt-0">
        <SearchField
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          placeholder="Search by name"
        />
      </div>
    </div>
  )
}

function Patients({ patients, sessionsByPatient, isLoading }) {
  const menu = useRef(null)
  const [filteredPatients, setFilteredPatients] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [exportBillingDialogVisible, setExportBillingDialogVisible] = useState(false)
  const [exportClinicalDialogVisible, setExportClinicalDialogVisible] = useState(false)
  const { maxDataViewHeight, setHeader } = usePageOutlet()
  const currentUser = useCurrentUser()

  const isCurrentUserAdmin = ['superadmin', 'site_admin'].includes(currentUser.role)

  const {
    mutateAsync: exportBillingReport,
    isLoading: exportBillingLoading,
  } = useExportBillingReport()

  const {
    mutateAsync: exportClinicalReport,
    isLoading: exportClinicalLoading,
  } = useExportClinicalReport()

  const handleExportBilling = async (selectedMonth) => exportBillingReport({ selectedMonth })
  const handleExportClinical = async (patientId, selectedMonth) => (
    exportClinicalReport({ patientId, selectedMonth })
  )

  const actionsMenuItems = [
    {
      label: 'Export Billing Report',
      icon: 'pi pi-file-excel',
      command: () => {
        setExportBillingDialogVisible(true)
      },
    },
    {
      label: 'Export Clinical Report',
      icon: 'pi pi-file-pdf',
      command: () => {
        setExportClinicalDialogVisible(true)
      },
    },
  ]

  const primaryAction = (
    <div className="flex flex-row gap-3">
      <div>
        { isCurrentUserAdmin
          && (
          <>
            <Menu model={actionsMenuItems} popup ref={menu} id="actions-menu" />
            <Button
              className="p-button-sm p-button-text"
              icon="pi pi-file-export"
              onClick={(event) => menu.current.toggle(event)}
              label="Exports"
            />
          </>
          )}
      </div>
      <Link to="/admin/ccm_time_tracking/new">
        <Button
          className="p-button-sm p-button-info"
          icon="pi pi-stopwatch"
          label="New Session"
        />
      </Link>
    </div>
  )

  useEffect(() => {
    setHeader({ primaryAction })
  }, [isLoading])

  useEffect(() => {
    if (searchTerm) {
      setFilteredPatients(exactSearch(patients, ['firstName', 'lastName'], searchTerm))
    } else {
      setFilteredPatients(patients)
    }
  }, [patients, searchTerm])

  return (
    <div className="flex flex-column">
      <ExportBillingReportDialog
        visible={exportBillingDialogVisible}
        setVisible={setExportBillingDialogVisible}
        exportIsLoading={exportBillingLoading}
        handleExport={handleExportBilling}
      />
      <ExportClinicalReportDialog
        visible={exportClinicalDialogVisible}
        setVisible={setExportClinicalDialogVisible}
        exportIsLoading={exportClinicalLoading}
        handleExport={handleExportClinical}
        patients={patients}
      />
      <div className="col-12">
        <PatientsMenu searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </div>
      <div className="flex flex-row flex-wrap" style={{ height: maxDataViewHeight }}>
        <ContentBlock isLoading={isLoading} emptyPlaceholder="No CCM Eligible Patients">
          {map(filteredPatients, (patient) => (
            <PatientDashboardCard
              key={patient.id}
              patient={patient}
              currentUserCCMEligible={currentUser.ccmEligible}
              newSession={false}
              billableSessions={get(sessionsByPatient, patient.id, [])}
              setNewPationSession={() => { }}
              setSelectedClient={() => { }}
            />
          ))}
        </ContentBlock>
      </div>
    </div>
  )
}

export default Patients
