import React from 'react'
import {
  capitalize, get, isEmpty,
} from 'lodash'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { formattedTextsFromSchedules, medicineDisplayName } from '@components/clientDoses/doseUtils'
import { usePatientDose } from '../../../../features/ClientDoses/clientDosesHooks'

function ExistingDoseRow({ patientId, doseId }) {
  const {
    data: dose,
    loading: doseIsLoading,
  } = usePatientDose({ clientId: patientId, doseId })

  const schedulesTemplate = (schedules) => {
    const formattedScheduleTexts = formattedTextsFromSchedules(schedules)

    return (
      <div className="flex flex-column gap-2">
        {
          formattedScheduleTexts.map((text) => (
            <span key={text}>
              {capitalize(text)}
            </span>
          ))
        }
      </div>
    )
  }

  const medicineTemplate = (row) => medicineDisplayName(row)
  const strengthTemplate = (row) => row.medicine.strength
  const scheduleHeaderTemplate = () => {
    if (isEmpty(dose)) return 'Schedule'
    return dose.prn ? 'PRN' : 'Schedule'
  }
  const scheduleBodyTemplate = (row) => (get(row, 'prn') ? 'PRN' : schedulesTemplate(row.schedules))
  const pharmacyInstructionsTemplate = (row) => get(row, 'sigs[0].pharmacyInstructions')

  const data = isEmpty(dose) ? [] : [dose]
  return (
    <DataTable
      value={data}
      loading={doseIsLoading}
      className="client-dose"
      rowClassName="surface-ground existing-dose-row"
    >
      <Column
        field="medicine"
        header="Medicine"
        body={medicineTemplate}
      />
      <Column
        field="strength"
        header="Strength"
        body={strengthTemplate}
      />
      <Column
        field="prn"
        header={scheduleHeaderTemplate}
        body={scheduleBodyTemplate}
      />
      <Column
        field="pharmacyInstructions"
        header="Pharmacy Instructions"
        body={pharmacyInstructionsTemplate}
      />
    </DataTable>
  )
}

export default ExistingDoseRow
