import { useMemo } from 'react'
import { get, map, sortBy } from 'lodash'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

// eslint-disable-next-line import/prefer-default-export
export function useCanAdministerConsumption({ statusMessage, patientId, willConfirmAt }) {
  const query = useQuery(
    {
      queryKey: ['can_administer_consumption', patientId, willConfirmAt],
      queryFn: () => ApiClient.get(`/users/can_administer_consumption?patient_id=${patientId}&will_confirm_at=${willConfirmAt}`),
      enabled: !!patientId && !!willConfirmAt,
      onError: (error) => statusMessage?.current && displayErrorMessages(error, statusMessage),
    },
  )

  return useMemo(() => ({
    ...query,
    data: sortBy(map(get(query, 'data'), (user) => user)),
  }), [query.data])
}
