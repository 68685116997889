import React, { useEffect } from 'react'
import { map, flatMap } from 'lodash'
import { Routes, Route, Link } from 'react-router-dom'
import { useCurrentOrganization } from '@components/App'
import { usePageOutlet } from '@components/display/PageLayout'
import { formatedCCMTime } from '@services/utils'
import Patients from './Patients'
import ClientSession from './ClientSession'
import { calculateServicesTimeSpent } from './billableSessionUtils'
import {
  usePatients,
  usePatientsBillableSessions,
} from './ccmHooks'

function PageTitle({ patients, billableSessions }) {
  const { patientLabelPlural } = useCurrentOrganization()
  const totals = calculateServicesTimeSpent(billableSessions || [])
  const hours = Math.floor(totals.minutes / 60)
  const minutes = totals.minutes - (Math.floor(totals.minutes / 60) * 60)
  const patientsCount = (patients || []).length

  return (
    <div className="flex flex-column gap-2 md:px-4 md:pb-2 md:pt-2">
      <Link to="/admin/ccm_time_tracking">
        <span className="text-900 font-normal">
          Chronic Care Management
        </span>
      </Link>
      <div className="flex align-items-center text-700 text-base flex-wrap">
        <div className="mr-3 flex align-items-center mt-2">
          <i className="pi pi-users mr-2" />
          <span>
            {patientsCount}
            {' '}
            {patientLabelPlural}
            {' '}
            Under Management
          </span>
        </div>
        <div className="flex align-items-center mt-2">
          <i className="pi pi-clock mr-2" />
          <span>
            {formatedCCMTime(hours, minutes)}
          </span>
        </div>
      </div>
    </div>
  )
}

function CCMDashboard({ organizationId }) {
  const { setHeader } = usePageOutlet()

  const {
    data: patients,
    isLoading: patientsLoading,
  } = usePatients(organizationId)

  const {
    data: sessionsByPatient,
    isLoading: sessionsLoading,
  } = usePatientsBillableSessions({ patientIds: map(patients, ({ id }) => id), ccmEnabled: true })

  const title = {
    label: (
      <PageTitle
        patients={patients}
        billableSessions={flatMap(sessionsByPatient, 'billableServiceSessions')}
        isLoading={patientsLoading || sessionsLoading}
      />
    ),
  }

  useEffect(() => {
    setHeader({ title, tabs: [], breadcrumbs: [] })
  }, [patientsLoading, sessionsLoading])

  return (
    <Routes>
      <Route
        path="new"
        element={(
          <ClientSession
            patients={patients}
            patientsLoading={patientsLoading}
          />
        )}
      />
      <Route
        index
        element={(
          <Patients
            patients={patients}
            sessionsByPatient={sessionsByPatient}
            isLoading={patientsLoading || sessionsLoading}
          />
        )}
      />
    </Routes>
  )
}

export default CCMDashboard
