import React from 'react'
import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'
import { useCurrentOrganization } from '@components/App'
import ChargeCodes from '../ccm/ChargeCodes'

function BillableSessionsOverview({ standardCharges, complexCharges, isLoading }) {
  if (isLoading) {
    return 'Loading...'
  }

  const organization = useCurrentOrganization()

  return (
    <Card className="h-full">
      <div className="flex flex-column gap-3 text-base text-900">
        <div className="flex flex-row justify-content-between align-items-center">
          <span className="text-lg">
            <i className="pi pi-chart-bar mr-2" />
            Billable Sessions Overview
          </span>
        </div>
        <Divider className="my-0" />
        <div className="flex flex-column gap-3">
          <ChargeCodes charges={standardCharges} />
          {organization.complexCcmEnabled
            && <ChargeCodes complex charges={complexCharges} />}
        </div>
      </div>
    </Card>
  )
}

export default BillableSessionsOverview
