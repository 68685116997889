import React from 'react'
import LoadingGrid from '../LoadingContent/Grid'

function ContentBlock({
  isLoading, isEmpty, children, emptyPlaceholder = 'No results',
}) {
  if (isLoading) {
    return <LoadingGrid />
  } if (isEmpty) {
    return <span className="px-3 py-2 text-lg fadein">{emptyPlaceholder}</span>
  }
  return children
}

export default ContentBlock
