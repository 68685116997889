import React, { useState, useEffect } from 'react'
import moment from 'moment'
import {
  compact, find, isEmpty, map, get,
} from 'lodash'
import { classNames } from 'primereact/utils'
import { Dropdown } from 'primereact/dropdown'
import { Skeleton } from 'primereact/skeleton'
import { generateTimeIncrements, titleCase } from '@services/utils'
import { useEventTimes } from '../../../../features/Schedules/eventTimesHooks'

const timeIncrements = generateTimeIncrements({ minuteIncrement: 15, durationHours: 24 })

function TimeSelector({ selectedTime, setSelectedTime, patientId }) {
  const [invalid, setInvalid] = useState(false)
  const { data: eventTimes = [], isLoading } = useEventTimes({ patientId })

  const timeOptions = compact([
    !isEmpty(eventTimes) && {
      label: 'Event Times',
      items: map(eventTimes, ({ id, name, startTime }) => {
        const time = moment(startTime)
        time.set({
          hour: time.hours(),
          minute: time.minutes(),
          second: '0',
          millisecond: '0',
        })
        return {
          label: `${titleCase(name)}: ${time.format('h:mm A')}`,
          value: { eventTimeId: id, time: time.valueOf() },
        }
      }),
    },
    {
      label: 'Custom Times',
      items: map(timeIncrements, ({ label, value }) => ({ label, value: { time: value } })),
    },
  ])

  const validateInput = () => {
    if (selectedTime?.eventTimeId) {
      setInvalid(false)
      return true
    }

    if (find(timeIncrements, (increment) => increment.value === selectedTime?.time)) {
      setInvalid(false)
      return true
    }

    const time = moment(selectedTime, ['h:mm A'])
    if (time.isValid()) {
      time.set({ second: 0, millisecond: 0 })
      timeIncrements.push({
        label: time.format('h:mm A'),
        value: time.valueOf(),
      })
      setSelectedTime({ time: time.valueOf() })
      setInvalid(false)
      return true
    }

    if (!selectedTime?.time) {
      setInvalid(true)
      return false
    }

    setInvalid(true)
  }

  useEffect(() => {
    if (get(selectedTime, 'time')) {
      const timeLabel = moment(get(selectedTime, 'time')).format('h:mm A')
      const existsInIncrements = find(timeIncrements, ({ label }) => label === timeLabel)
      const time = moment(timeLabel, ['h:mm A'])
      if (time.isValid() && !existsInIncrements) {
        time.set({ second: 0, millisecond: 0 })
        timeIncrements.push({
          label: time.format('h:mm A'),
          value: time.valueOf(),
        })
        setInvalid(false)
      }
    }
  }, [])

  return (
    <div className="flex flex-column gap-3 text-center w-full">
      <label className="text-lg font-normal mb-1" htmlFor="dose-schedule-time">
        What is the expected time of administration?
      </label>
      {
        invalid && (
          <span className="text-xs text-red-500">
            Time must be in 12hr format. Example: 12:30 PM
          </span>
        )
      }
      {
        isLoading && (
          <Skeleton width="100%" height="2.5rem" />
        )
      }
      {
        !isLoading && (
          <Dropdown
            aria-labelledby="dose-schedule-time"
            inputId="dose-schedule-time"
            className={classNames('time-selection', { 'p-invalid': invalid })}
            value={selectedTime}
            options={timeOptions}
            onChange={({ value }) => setSelectedTime(value)}
            onBlur={() => validateInput()}
            onHide={() => validateInput()}
            onMouseLeave={() => validateInput()}
            filter
            editable
            optionGroupLabel="label"
            optionGroupChildren="items"
            filterBy="label"
            filterMatchMode="startsWith"
            placeholder="Select a time for this event"
            data-testid="time-selector"
          />
        )
      }
    </div>
  )
}

export default TimeSelector
