import React from 'react'
import { Link } from 'react-router-dom'

function SessionBreadcrumbs({
  step, patient, provider, service, isUpdate,
}) {
  return (
    <div className="flex flex-column gap-2 md:flex-row md:align-items-center">
      <div className="text-lg flex flew-row gap-1 align-items-center">
        <i className="pi pi-book text-900" />
        <span className="font-medium text-900">
          {isUpdate ? 'Session' : 'New Session'}
        </span>
      </div>
      {step === 'patient'
        && (
        <div className="text-base flex flew-row gap-1 align-items-center">
          <i className="pi pi-angle-right text-900" />
          <span className="font-bold">
            Select a Patient
          </span>
        </div>
        )}
      {patient && step !== 'patient'
        && (
        <div className="text-base flex flew-row gap-1 align-items-center">
          <i className="pi pi-angle-right text-900" />
          <span className="text-600">
            <Link to="?step=patient">
              {patient.name}
            </Link>
          </span>
        </div>
        )}
      {step === 'provider'
        && (
        <div className="text-base flex flew-row gap-1 align-items-center">
          <i className="pi pi-angle-right text-900" />
          <span className="font-bold">
            Select a Provider
          </span>
        </div>
        )}
      {provider && step !== 'provider'
        && (
        <div className="text-base flex flew-row gap-1 align-items-center">
          <i className="pi pi-angle-right text-900" />
          <span className="text-600">
            <Link to="?step=provider">
              {provider.name}
            </Link>
          </span>
        </div>
        )}
      {step === 'service'
        && (
        <div className="text-base flex flew-row gap-1 align-items-center">
          <i className="pi pi-angle-right text-900" />
          <span className="font-bold">
            Select a Service
          </span>
        </div>
        )}
      {service && step !== 'service'
        && (
        <div className="text-base flex flew-row gap-1 align-items-center md:max-w-15rem xl:max-w-30rem">
          <i className="pi pi-angle-right text-900" />
          <span
            className="text-600"
            title={service.description}
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            <Link to="?step=service">
              {service.description}
            </Link>
          </span>
        </div>
        )}
      {step === 'timer'
        && (
        <div className="text-base flex flew-row gap-1 align-items-center">
          <i className="pi pi-angle-right text-900" />
          <span className="font-bold">
            {isUpdate ? 'Note' : 'Timer'}
          </span>
        </div>
        )}
    </div>
  )
}

export default SessionBreadcrumbs
