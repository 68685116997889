import { startCase } from 'lodash'

export function errorMessageFromError(error, defaultMessage = 'Something went wrong') {
  if (error.response) {
    const { data } = error.response
    const message = data.error || defaultMessage
    return {
      severity: 'error',
      summary: `${startCase(message)}`,
      sticky: true,
    }
  }
  return {
    severity: 'error',
    summary: defaultMessage,
    detail: error.message,
    sticky: true,
  }
}

export function formErrorsFromError(error) {
  if (error.response) {
    const { data } = error.response
    const errors = data.form_errors || []
    return errors.map((e) => ({
      severity: 'error',
      summary: `${startCase(e)}`,
      sticky: true,
    }))
  }
  return []
}

export function displayErrorMessages(error, statusMessage) {
  if (!statusMessage) return

  // Create a set to store unique error messages
  const uniqueMessages = new Set()

  // Add error message from the main error
  uniqueMessages.add(errorMessageFromError(error).summary)

  // Add error messages from form errors
  formErrorsFromError(error).forEach((e) => uniqueMessages.add(e.summary))

  // Show unique error messages
  const uniqueErrorMessages = Array.from(uniqueMessages).map((message) => ({
    severity: 'error',
    summary: message,
    sticky: true,
  }))

  statusMessage.current.replace(uniqueErrorMessages)
}
