import React from 'react'
import { Tag } from 'primereact/tag'

function ConsumptionTag({ count, type }) {
  return (
    <Tag
      rounded
      className={`white-space-nowrap text-xs font-light
      center-tag bg-${type}
      ${type === 'confirmed' ? 'text-green-900' : 'text-white'}`}
    >
      {`${count} ${type === 'confirmed' ? 'administered' : 'refusal'}`}
    </Tag>
  )
}

export default ConsumptionTag
