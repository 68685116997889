import React, { useState, useEffect, useRef } from 'react'
import { map, get, isEmpty } from 'lodash'
import { AutoComplete } from 'primereact/autocomplete'
import { Divider } from 'primereact/divider'
import { Calendar } from 'primereact/calendar'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'
import { Skeleton } from 'primereact/skeleton'
import { Messages } from 'primereact/messages'
import { dateTemplate } from '../../../services/utils'
import { useAvailableSiteDoses, useAssignmentMutation, useLastUsedDoseFillQuery } from './doseAssignmentHooks'

function LoadingContent() {
  return (
    <>
      <div className="mb-4">
        <Skeleton className="mb-2" width="8rem" height="2rem" />
        <Skeleton width="100%" height="3rem" />
      </div>
      <div className="mb-4">
        <Skeleton className="mb-2" width="8rem" height="2rem" />
        <Skeleton width="100%" height="3rem" />
      </div>
    </>
  )
}

function DoseAssignment({
  ekitId, siteId, drawerId, slot, onSuccess,
}) {
  const statusMessage = useRef(null)
  const [selectedDose, setSelectedDose] = useState()
  const [expirationDate, setExpirationDate] = useState()
  const [dosesInFill, setDosesInFill] = useState()
  const [filteredDoses, setFilteredDoses] = useState([])
  const {
    data: siteDoses,
    isLoading: siteDosesQueryLoading,
  } = useAvailableSiteDoses(siteId, statusMessage)
  const doses = map(siteDoses, (item) => item)
  const mutation = useAssignmentMutation(ekitId, slot, statusMessage)

  const {
    data: lastUsedDoseFill,
    isLoading: lastUsedDoseFillQueryLoading,
  } = useLastUsedDoseFillQuery(get(selectedDose, 'id'))

  useEffect(() => {
    if (!isEmpty(siteDoses)) {
      setFilteredDoses(doses)
    }
  }, [siteDoses])

  useEffect(() => {
    if (!isEmpty(lastUsedDoseFill)) {
      setDosesInFill(lastUsedDoseFill.dosesInFill)
    }
  }, [lastUsedDoseFill])

  const handleAssignmentConfirmation = async (event) => {
    await mutation.mutateAsync({
      simpillDrawerId: drawerId,
      drawerSlotId: slot?.id,
      doseFill: {
        dosesInFill,
        expiresAt: expirationDate,
        doseId: selectedDose.id,
      },
    })
    event.preventDefault()
    onSuccess()
  }

  const searchDoses = (event) => {
    setTimeout(() => {
      let filtered
      if (!event.query.trim().length) {
        filtered = [...doses]
      } else {
        filtered = doses.filter((dose) => (
          dose.medicine.name.toLowerCase().startsWith(event.query.toLowerCase())
        ))
      }
      setFilteredDoses(filtered)
    }, 250)
  }

  const itemTemplate = (item) => (
    <span>
      {item.medicine.name}
      ,
      {' '}
      {item.medicine.strength}
    </span>
  )

  return (
    <>
      <Messages ref={statusMessage} />
      <div className="flex flex-column gap-2 w-20rem">
        { siteDosesQueryLoading
          && <LoadingContent />}
        { !siteDosesQueryLoading
          && (
          <>
            <label className="text-lg font-normal mb-1">Select a Dose to Assign</label>
            <AutoComplete
              value={selectedDose}
              suggestions={filteredDoses}
              completeMethod={searchDoses}
              field={({ medicine }) => `${medicine.name}, ${medicine.strength}`}
              dropdown
              itemTemplate={itemTemplate}
              onChange={(e) => setSelectedDose(e.value)}
              aria-label="Doses"
              dropdownarialabel="Select Dose"
            />
            {
              // while the user is writing the dose name `selectedDose` is a string
              // when the user select the actual dose `selectedDose` will contain a medicine object
              // we want to check if the selected dose is an object to not show the loading content
              selectedDose && selectedDose.medicine && (
                lastUsedDoseFillQueryLoading ? <LoadingContent />
                  : (
                    <>
                      <label className="text-lg font-normal mb-1" htmlFor="dosesInFill">Doses in Fill</label>
                      <InputNumber
                        inputId="dosesInFill"
                        inputClassName="py-4"
                        value={dosesInFill}
                        onChange={(e) => setDosesInFill(e.value)}
                      />
                      <label className="text-lg font-normal mb-1" htmlFor="expirationDate">Expiration Date</label>
                      <Calendar
                        inputId="expirationDate"
                        value={expirationDate}
                        showIcon
                        minDate={new Date()}
                        onChange={(e) => setExpirationDate(e.value)}
                        ariaLabelledBy="expirationDate"
                      />
                      <Divider />
                    </>
                  )
              )
            }
          </>
          )}
        { selectedDose && expirationDate && doses.includes(selectedDose)
          && (
          <>
            <div className="flex flex-column gap-2">
              <h5 className="text-base font-bold mb-2">Dose</h5>
              <span className="text-sm">
                Name:
                {get(selectedDose, 'medicine.name')}
              </span>
              <span className="text-sm">
                Strength:
                {get(selectedDose, 'medicine.strength')}
              </span>
              <span className="text-sm">
                Doses in fill:
                {dosesInFill}
              </span>
              <span className="text-sm">
                Expiration Date:
                {dateTemplate(expirationDate)}
              </span>
            </div>
            <Button
              disabled={mutation.isLoading}
              className="mt-5"
              label="Confirm Assignment"
              onClick={handleAssignmentConfirmation}
            />
          </>
          )}
      </div>
    </>
  )
}

export default DoseAssignment
