import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { RRule } from 'rrule'
import {
  get,
  map,
  filter,
  isEmpty,
  includes,
} from 'lodash'
import ApiClient from '@services/ApiClient'
import { getTimeFromMinutes, titleCase } from '@services/utils'
import { getOrderMedicine, getOrderSigs } from '@services/utils/pharmacyIntegration'
import { errorMessageFromError, formErrorsFromError } from '@services/errorHandler'
import { useCurrentOrganization } from '@components/App'
import { doseDirectiveValues } from '@components/pharmacyOrders/utils/directives'
import { useMemo } from 'react'
import { momentFromIso8601Str } from '@services/utils/moment'

export function usePatientPharmacyOrders(patientId, statusMessage) {
  const query = useQuery({
    queryKey: ['patientPharmacyOrders', patientId],
    queryFn: () => ApiClient.get(`/patients/${patientId}/pharmacy_orders`),
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to fetch pharmacy orders at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })

  return useMemo(() => ({
    ...query,
    isLoading: query.isLoading,
    data: get(query, 'data.pharmacyOrders'),
  }), [query.data])
}

export function useDosePharmacyOrders(doseId, statusMessage) {
  const query = useQuery({
    queryKey: ['dosePharmacyOrders', doseId],
    queryFn: () => ApiClient.get(`/doses/${doseId}/pharmacy_orders`),
    enabled: !!doseId,
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to fetch pharmacy orders at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })

  return useMemo(() => ({
    ...query,
    isLoading: query.isLoading,
    data: get(query, 'data.pharmacyOrders'),
  }), [query.data])
}

export function useOrderIntegrationData(pharmacyOrderId, statusMessage) {
  return useQuery({
    queryKey: ['orderIntegrationData', pharmacyOrderId],
    queryFn: () => ApiClient.get('/integration_data/for_pharmacy_order', { params: { pharmacy_order_id: pharmacyOrderId } }),
    enabled: !!pharmacyOrderId,
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to fetch pharmacy order integration data at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })
}

function formatOrderSchedulesForDisplay(order, flexibility) {
  const schedulesWithRrule = filter(order.schedules, ({ rrule }) => !!rrule)

  return map(schedulesWithRrule, (schedule) => {
    const time = getTimeFromMinutes(schedule.time)
    const rruleOptions = RRule.parseString(schedule.rrule)
    const rruleForDose = new RRule(rruleOptions)

    return {
      id: schedule.id,
      time,
      timeInMinutes: schedule.time,
      flexibility,
      text: titleCase(rruleForDose.toText()),
      rruleForDose: rruleForDose.toString(),
      rrule: schedule.rrule,
    }
  })
}

export function useIgnoreOrders(statusMessage, patientId) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['ignoreOrders'],
    mutationFn: ({ orders }) => (
      ApiClient.post(`/patients/${patientId}/pharmacy_orders/ignore`, { orders })
    ),
    onSuccess: () => {
      statusMessage.current.show([
        { severity: 'warn', summary: 'Orders Ignored' },
      ])
      queryClient.invalidateQueries('pharmacyOrders')
    },
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to process pharmacy order at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })
}

export function useAcknowledgeNewDose(statusMessage, patientId) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['acknowledgeNewDose'],
    mutationFn: ({ dose, orders }) => (
      ApiClient.post(`/patients/${patientId}/pharmacy_orders/acknowledge_dose_create`, { dose, orders })
    ),
    onSuccess: () => {
      statusMessage.current.show([
        { severity: 'warn', summary: 'New Dose Successfully Confirmed!' },
      ])
      queryClient.invalidateQueries('pharmacyOrders')
    },
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to process pharmacy order at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })
}

export function useAcknowledgeDoseUpdate(statusMessage, patientId) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['acknowledgeDoseUpdate'],
    mutationFn: ({ dose, orders }) => (
      ApiClient.post(`/patients/${patientId}/pharmacy_orders/acknowledge_dose_update`, { dose, orders })
    ),
    onSuccess: () => {
      statusMessage.current.show([
        { severity: 'warn', summary: 'Dose Successfully Updated!' },
      ])
      queryClient.invalidateQueries('pharmacyOrders')
    },
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to process pharmacy order at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })
}

export function useAcknowledgeDoseCancel(statusMessage, patientId) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['acknowledgeDoseCancel'],
    mutationFn: ({ dose, orders }) => (
      ApiClient.post(`/patients/${patientId}/pharmacy_orders/acknowledge_dose_cancel`, { doseId: dose.id, orders })
    ),
    onSuccess: () => {
      statusMessage.current.show([
        { severity: 'warn', summary: 'Dose Successfully Cancelled!' },
      ])
      queryClient.invalidateQueries('pharmacyOrders')
    },
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to process pharmacy order at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })
}

export function useAcknowledgeDoseDiscontinue(statusMessage, patientId) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['acknowledgeDoseDiscontinue'],
    mutationFn: ({ dose, orders }) => (
      ApiClient.post(`/patients/${patientId}/pharmacy_orders/acknowledge_dose_discontinue`, { doseId: dose.id, orders })
    ),
    onSuccess: () => {
      statusMessage.current.show([
        { severity: 'warn', summary: 'Dose Successfully Discontinued!' },
      ])
      queryClient.invalidateQueries('pharmacyOrders')
    },
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to process pharmacy order at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })
}

export function useAcknowledgeDoseMatch(statusMessage, patientId) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['acknowledgeDoseMatch'],
    mutationFn: ({ dose, orders }) => (
      ApiClient.post(`/patients/${patientId}/pharmacy_orders/acknowledge_dose_match`, { dose, orders })
    ),
    onSuccess: () => {
      statusMessage.current.show([
        { severity: 'warn', summary: 'Dose Successfully Matched!' },
      ])
      queryClient.invalidateQueries('pharmacyOrders')
    },
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to process pharmacy order at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })
}

/**
 * @param {MutableRefObject} statusMessage
 * @param {String} patientId
 */
export function useReviewablePharmacyOrders(statusMessage, patientId) {
  const { flexibility } = useCurrentOrganization()

  const acknowledgeNewDose = useAcknowledgeNewDose(statusMessage, patientId)
  const acknowledgeDoseUpdate = useAcknowledgeDoseUpdate(statusMessage, patientId)
  const acknowledgeDoseCancel = useAcknowledgeDoseCancel(statusMessage, patientId)
  const acknowledgeDoseDiscontinue = useAcknowledgeDoseDiscontinue(statusMessage, patientId)
  const acknowledgeDoseMatch = useAcknowledgeDoseMatch(statusMessage, patientId)
  const acknowledgeIgnoreOrders = useIgnoreOrders(statusMessage, patientId)

  return useQuery({
    queryKey: ['pharmacyOrders', patientId],
    queryFn: () => ApiClient.get(`/patients/${patientId}/reviewable_pharmacy_orders`),
    select: ({ reviewableOrders }) => {
      const orders = filter(reviewableOrders, ({ directives }) => !isEmpty(directives))
      return map(orders, (order) => {
        const { compositeOrder } = order
        const doseSchedules = get(order, 'dose.schedules', [])
        const isPRN = includes(compositeOrder?.hl7ScheduleCode, 'PRN')
        const schedules = isPRN ? { schedules: [] } : { schedules: doseSchedules }

        const sigs = getOrderSigs(compositeOrder)
          .map((sig) => {
            const sigStartAt = sig.startAtWall ? momentFromIso8601Str(sig.startAtWall) : null
            const sigEndAt = sig.endAtWall ? momentFromIso8601Str(sig.endAtWall) : null
            return ({
              ...sig,
              startAt: sigStartAt,
              endAt: sigEndAt,
              schedules: isPRN ? [] : formatOrderSchedulesForDisplay(sig, flexibility),
              invalidSchedules: filter(sig.schedules, ({ eventType }) => eventType === 'unsupported'),
            })
          })

        return {
          ...order,
          doseDirectives: doseDirectiveValues(
            order.directives,
            {
              acknowledgeNewDose,
              acknowledgeDoseUpdate,
              acknowledgeDoseCancel,
              acknowledgeDoseDiscontinue,
              acknowledgeDoseMatch,
              acknowledgeIgnoreOrders,
            },
          ),
          compositeOrder: {
            ...compositeOrder,
            sigs,
            prn: !!compositeOrder.prn,
            schedules: formatOrderSchedulesForDisplay(schedules, flexibility),
            medicine: getOrderMedicine(compositeOrder),
          },
          sourceOrders: Array.isArray(order.sourceOrders)
            ? order.sourceOrders.map((sourceOrder) => ({
              ...sourceOrder,
              medicine: getOrderMedicine(sourceOrder),
              sigs: getOrderSigs(sourceOrder),
            }))
            : [],
        }
      })
    },
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to fetch pharmacy orders at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })
}

export default {}
