import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import {
  NavLink, Route, Routes,
} from 'react-router-dom'
import { classNames } from 'primereact/utils'
import { Divider } from 'primereact/divider'
import Demographic from '../../components/patients/HRST/Demographic'
import HRSTDoses from '../../components/patients/HRST/HRSTDoses'

function Menu() {
  const viewLinks = [
    {
      label: 'Demographics',
      linkTo: 'demographics',
    },
    {
      label: 'Doses',
      linkTo: 'doses',
    },
  ]

  return (
    <div className="flex flex-row justify-space-between align-items-center px-1">
      <div className="flex flex-row">
        {viewLinks.map((link) => (
          <NavLink to={link.linkTo} key={link.linkTo}>
            {
              ({ isActive }) => (
                <Button className={classNames('flex flex-row align-items-baseline p-button-sm p-button-outlined', { 'p-button-text': !isActive })}>
                  <span className={classNames({ 'text-600': !isActive, 'ml-2': !!link.badge })}>
                    {link.label}
                  </span>
                </Button>
              )
            }
          </NavLink>
        ))}
      </div>
    </div>
  )
}

function HRST({ patient, handleSetHeader }) {
  const [searchTerm, setSearchTerm] = useState()

  useEffect(() => {
    handleSetHeader({ primaryAction: null })
  }, [patient])

  return (
    <div className="col-12">
      <Card className="h-full">
        <div className="flex flex-column gap-3 text-base text-900">
          <Menu />
          <Divider className="my-0" />
          <Routes>
            <Route
              path="demographics"
              element={(
                <Demographic
                  patient={patient}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
              )}
            />
            <Route
              path="doses"
              element={
                <HRSTDoses patient={patient} handleSetHeader={handleSetHeader} />
              }
            />
          </Routes>
        </div>
      </Card>
    </div>
  )
}

export default HRST
