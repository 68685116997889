import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { withApp } from '@components/App'
import PageLayout from '@components/display/PageLayout'
import CCMDashboard from './CCMDashboard'

import './ccm-styles.scss'

function CCM(props) {
  return (
    <div className="ccm-management">
      <Routes>
        <Route path="/admin/*" element={<PageLayout rootPath="/admin/ccm_time_tracking" />}>
          <Route path="ccm_time_tracking/*" element={<CCMDashboard {...props} />} />
        </Route>
      </Routes>
    </div>
  )
}

export default withApp(CCM)
