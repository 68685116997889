import moment from 'moment'
import { initials } from '@services/utils'
import { medicineDisplayNameWithStrength } from '../../clientDoses/doseUtils'
import { statusLabels } from './adherenceDashboardConfig'

export const refusedReasonArray = (patientLabel) => [
  { value: 'PATIENT_REFUSED', label: `${patientLabel} refused` },
  { value: 'COULD_NOT_LOCATE', label: 'Could not locate' },
  { value: 'CONDITION_CHECK', label: 'Doesn\'t meet condition check' },
]

export const createStatusArray = [
  { value: 'CREATE_CONFIRMED', label: 'Given', disabled: false },
  { value: 'CREATE_MISSED', label: 'Missed', disabled: false },
  { value: 'CREATE_REFUSED', label: 'Refused', disabled: false },
]

export const overrideStatusArray = [
  { value: 'OVERRIDE_CONFIRMED', label: 'Given', disabled: false },
  { value: 'OVERRIDE_MISSED', label: 'Missed', disabled: false },
  { value: 'OVERRIDE_REFUSED', label: 'Refused', disabled: false },
]

export const getUserInitialsByFullName = (fullName) => {
  if (!fullName) return '??'

  return initials(fullName)
}

export const getFormattedDoseConsumptions = ({
  consumptions,
  timezone,
  startDate,
  totalDays,
}) => {
  // Group consumptions by their date
  const consumptionsByDate = consumptions.reduce((acc, consumption) => {
    if (consumption.prn) return acc
    const dayKey = moment.tz(consumption.expectedAt, timezone).startOf('day').format()
    if (!acc[dayKey]) acc[dayKey] = []
    acc[dayKey].push(consumption)
    return acc
  }, {})

  const formattedConsumptions = {}
  for (let i = 0; i < totalDays; i += 1) {
    const currentDate = startDate.clone().add(i, 'days')
    const consumptionsOfDate = consumptionsByDate[currentDate.format()] || []
    consumptionsOfDate.sort((a, b) => moment(a.expectedAt).diff(moment(b.expectedAt)))

    consumptionsOfDate.forEach((consumption) => {
      const medicine = medicineDisplayNameWithStrength(consumption.dose)
      const pharmacyInstructions = consumption.doseSig?.pharmacyInstructions
      const expectedAt = moment.tz(consumption.expectedAt, timezone)
      const timeOfDay = expectedAt.format('h:mm A')
      const key = `${medicine}_${timeOfDay}`

      if (!formattedConsumptions[key]) {
        formattedConsumptions[key] = {
          doseId: consumption.dose.id,
          doseSigId: consumption.doseSig?.id,
          medicine,
          pharmacyInstructions,
          time: timeOfDay,
          days: Array.from({ length: totalDays }, (_, dayIndex) => {
            const expectedAtInDate = startDate.clone().add(dayIndex, 'days').set({
              hour: expectedAt.get('hour'),
              minute: expectedAt.get('minute'),
              second: expectedAt.get('second'),
            })
            const status = expectedAtInDate.isBefore(moment()) ? 'not available' : 'not required'
            return { status }
          }),
        }
      }
      formattedConsumptions[key].days[i] = {
        status: consumption.status,
        consumption,
      }
    })
  }

  return Object.values(formattedConsumptions)
}

export const shouldShowAdministeredBy = (status) => ['CREATE_REFUSED', 'OVERRIDE_REFUSED', 'CREATE_CONFIRMED', 'OVERRIDE_CONFIRMED'].includes(status)

export const unknownStatusConsumptions = (consumptions) => {
  const consumptionsWithUnknownStatus = []
  const unknownStatus = []
  consumptions.forEach((consumption) => {
    let hasUnknownStatus = false
    consumption.administrationEvents.forEach((event) => {
      const isUnknown = statusLabels[event.code] === undefined
      if (isUnknown && !unknownStatus.includes(event.code)) {
        hasUnknownStatus = true
        unknownStatus.push(event.code)
      }
    })
    if (hasUnknownStatus) consumptionsWithUnknownStatus.push(consumption.id)
  })

  return { consumptionsWithUnknownStatus, unknownStatus }
}
