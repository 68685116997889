import React, { useState, useEffect } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import { Calendar } from 'primereact/calendar'
import { convertToTimeZoneDate } from '@services/utils'
import moment from 'moment'

function PatientPausingDialog({
  patient,
  visible,
  onHide,
  onConfirm,
  isLoading,
  leaveOfAbsenceToEdit,
}) {
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [note, setNote] = useState('')

  useEffect(() => {
    if (leaveOfAbsenceToEdit) {
      setStartDate(convertToTimeZoneDate(leaveOfAbsenceToEdit.startAt, patient.timezone))
      setEndDate(leaveOfAbsenceToEdit.endAt
        ? convertToTimeZoneDate(leaveOfAbsenceToEdit.endAt, patient.timezone)
        : '')
      setNote(leaveOfAbsenceToEdit.note)
    } else {
      setStartDate(convertToTimeZoneDate(moment.now(), patient?.timezone))
    }
    return () => {
      setStartDate('')
      setEndDate('')
      setNote('')
    }
  }, [leaveOfAbsenceToEdit, patient])

  const footer = (
    <div className="flex flex-row gap-2 justify-content-center">
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-outlined p-button-sm"
        onClick={onHide}
        disabled={isLoading}
      />
      <Button
        label="Confirm"
        icon="pi pi-check"
        className="p-button-sm"
        onClick={() => onConfirm({ startDate, endDate, note })}
        loading={isLoading}
      />
    </div>
  )

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header="Leave Of Absence"
      footer={footer}
      modal
      className="p-fluid w-3"
      closable={!isLoading}
    >
      <div className="p-field p-2">
        <label htmlFor="pause-start-date">Start Date</label>
        <Calendar
          id="startDateTime"
          name="pause-start-date"
          className="mt-2 border-round-md"
          value={startDate}
          stepMinute={15}
          onChange={(e) => {
            setStartDate(e.value)
          }}
          showTime
          hourFormat="12"
          showIcon
          icon={<i className="pi pi-clock" />}
          hideOnDateTimeSelect
        />
      </div>
      <div className="p-field p-2">
        <label htmlFor="pause-end-date">End Date</label>
        <Calendar
          id="endDateTime"
          name="pause-end-date"
          className="mt-2 border-round-md"
          value={endDate}
          stepMinute={15}
          onChange={(e) => {
            setEndDate(e.value)
          }}
          showTime
          hourFormat="12"
          showIcon
          icon={<i className="pi pi-clock" />}
          hideOnDateTimeSelect
        />
      </div>
      <div className="p-field p-2">
        <label htmlFor="pause-patient-note">
          Note for this leave of absence
        </label>
        <InputTextarea
          id="pause-patient-note"
          className="mt-2 border-round-md"
          rows={3}
          cols={30}
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </div>
    </Dialog>
  )
}

export default PatientPausingDialog
