import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import ClientSession from './ClientSession'
import PatientSessions from './PatientSessions'

function Patient({ patient, handleSetHeader }) {
  useEffect(() => {
    handleSetHeader({ primaryAction: null })
  }, [patient])

  return (
    <Routes>
      <Route index element={<PatientSessions patient={patient} />} />
      <Route
        path="new"
        element={<ClientSession patient={patient} handleSetHeader={handleSetHeader} />}
      />
      <Route
        path=":sessionId/update"
        element={<ClientSession patient={patient} handleSetHeader={handleSetHeader} />}
      />
    </Routes>
  )
}

export default Patient
