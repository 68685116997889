import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Slider } from 'primereact/slider'

const FifteenMinutes = 15
const TwoHourFifteenMinuteIncrements = 16
const FourHourFifteenMinuteIncrements = 32

function ConsumptionThresholds({ time, flexibility, setFlexibility }) {
  const [rangeBoundaries, setRangeBoundaries] = useState([0, FourHourFifteenMinuteIncrements])
  const [recommendedRange, setRecommendedRange] = useState([{}, {}])

  const calculateStartMinutes = (increment) => (
    (TwoHourFifteenMinuteIncrements - increment) * FifteenMinutes
  )

  const calculateEndMinutes = (increment) => (
    (increment - TwoHourFifteenMinuteIncrements) * FifteenMinutes
  )

  const calculateTimesForRange = (range) => {
    const rangeStartMinutes = calculateStartMinutes(range[0])
    const rangeEndMinutes = calculateEndMinutes(range[1])

    const expectedAt = moment(time)
    const start = expectedAt
      .clone()
      .subtract(rangeStartMinutes, 'minutes')
    const end = expectedAt
      .clone()
      .add(rangeEndMinutes, 'minutes')

    if (end.isBefore(expectedAt) || start.isAfter(expectedAt)) {
      return recommendedRange
    }

    if (end.diff(start, 'minutes') < 30) {
      return recommendedRange
    }

    return [
      {
        label: start.format('h:mm A'),
        value: range[0],
      },
      {
        label: end.format('h:mm A'),
        value: range[1],
      },
    ]
  }

  const handleRecommendedChange = (event) => {
    const range = calculateTimesForRange(event.value)
    setRecommendedRange(range)
  }

  const setFlexibilityAttributes = (event) => {
    setFlexibility({
      ...flexibility,
      recommendedStart: calculateStartMinutes(event.value[0]),
      recommendedEnd: calculateEndMinutes(event.value[1]),
      maxStart: calculateStartMinutes(event.value[0]),
      maxEnd: calculateEndMinutes(event.value[1]),
    })
  }

  useEffect(() => {
    if (time && flexibility) {
      setRangeBoundaries([0, 32])
      const { recommendedStart, recommendedEnd } = flexibility
      const range = calculateTimesForRange(
        [
          TwoHourFifteenMinuteIncrements - (recommendedStart / FifteenMinutes),
          TwoHourFifteenMinuteIncrements + (recommendedEnd / FifteenMinutes),
        ],
      )
      setRecommendedRange(range)
    }
  }, [time, flexibility])

  return (
    <div className="flex flex-column justify-content-center gap-5 w-full">
      <div className="flex flex-column gap-2 text-center w-full">
        <label className="text-lg font-normal mb-1">
          What is the allowed time window of administration?
          <p className="font-bold text-base">
            {recommendedRange[0]?.label}
            <span className="font-normal"> to </span>
            {recommendedRange[1]?.label}
          </p>
        </label>
        <Slider
          range
          min={rangeBoundaries[0]}
          max={rangeBoundaries[1]}
          value={recommendedRange.map((point) => point.value)}
          onChange={handleRecommendedChange}
          onSlideEnd={setFlexibilityAttributes}
        />
      </div>
    </div>
  )
}

export default ConsumptionThresholds
