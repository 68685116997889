import React, { useState, useEffect, useRef } from 'react'
import {
  map, first, isEmpty, get,
} from 'lodash'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import ContentBlock from '@components/display/ContentBlock'
import { Menu } from 'primereact/menu'
import ExportClinicalReportDialog from '@components/ccm/ExportClinicalReportDialog'
import SessionCard from '@components/ccm/SessionCard'
import { fuzzySearch } from '@services/utils/search'
import { useCurrentUser } from '@components/App'
import { generatePastTwelveMonths } from '@services/utils'
import { usePatientBillableSessions, useExportClinicalReport } from './ccmHooks'

import './ccm-styles.scss'

function PageMenu({
  searchBy,
  setSearchBy,
  searchTerm,
  setSearchTerm,
  selectedMonth,
  setSelectedMonth,
  setExportDialogVisible,
}) {
  const searchMenu = useRef(null)
  const [months, setMonths] = useState()
  const currentUser = useCurrentUser()

  const isCurrentUserAdmin = ['superadmin', 'site_admin'].includes(currentUser.role)

  const searchMenuItems = [
    {
      label: 'Provider',
      command: () => setSearchBy({ name: 'provider', fields: ['medicalProvider.fullName'] }),
    },
    {
      label: 'Service',
      command: () => setSearchBy({ name: 'service', fields: ['medicalService.description', 'medicalService.icdCode'] }),
    },
  ]

  const selectedMonthTemplate = (option) => (
    <>
      <i className="pi pi-calendar mr-2" />
      <span>{option?.label}</span>
    </>
  )

  useEffect(() => {
    const pastTwelveMonths = generatePastTwelveMonths()
    setMonths(pastTwelveMonths)
    setSelectedMonth(first(pastTwelveMonths).value)
  }, [])

  return (
    <div className="p-menubar bg-white flex flex-wrap justify-content-between align-items-center py-3 px-3">
      <div className="flex flex-row gap-3">
        <Dropdown
          className="w-14rem"
          options={months}
          onChange={(e) => setSelectedMonth(e.value)}
          value={selectedMonth}
          valueTemplate={selectedMonthTemplate}
        />
        <div className="p-inputgroup w-17rem">
          <Menu model={searchMenuItems} popup ref={searchMenu} id="search-menu" />
          <span className="p-inputgroup-addon p-0 bg-white">
            <Button
              className="p-button-sm p-button-text p-button-secondary"
              icon="pi pi-bars"
              onClick={(event) => searchMenu.current.toggle(event)}
              aria-controls="search-menu"
              aria-haspopup
            />
          </span>
          <InputText
            value={searchTerm || ''}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={`Search by ${searchBy.name}`}
          />
        </div>
      </div>
      <div className="flex flex-row gap-1">
        { isCurrentUserAdmin
          && (
          <Button
            className="p-button-sm p-button-text"
            label="Export"
            icon="pi pi-file-pdf"
            onClick={() => setExportDialogVisible(true)}
          />
          )}
        <Link to="new">
          <Button
            className="p-button-sm"
            label="New Session"
            icon="pi pi-stopwatch"
          />
        </Link>
      </div>
    </div>
  )
}

function PatientSessions({ patient, currentUserCCMEligible = true }) {
  const [selectedMonth, setSelectedMonth] = useState()
  const [searchBy, setSearchBy] = useState({ name: 'provider', fields: ['medicalProvider.fullName'] })
  const [searchTerm, setSearchTerm] = useState()
  const [filteredSessions, setFilteredSessions] = useState([])
  const [exportDialogVisible, setExportDialogVisible] = useState(false)

  const {
    data: billableSessions,
    isLoading,
    isSuccess,
  } = usePatientBillableSessions(patient?.id, selectedMonth)

  const {
    mutateAsync: exportCCMReport,
    isLoading: isExporting,
  } = useExportClinicalReport()

  const handleExport = async (patientId, month) => (
    exportCCMReport({ patientId, selectedMonth: month })
  )

  useEffect(() => {
    if (searchTerm) {
      setFilteredSessions(fuzzySearch(billableSessions, searchBy.fields, searchTerm))
    } else {
      setFilteredSessions(billableSessions)
    }
  }, [billableSessions, searchTerm, searchBy])

  return (
    <>
      <ExportClinicalReportDialog
        visible={exportDialogVisible}
        setVisible={setExportDialogVisible}
        patientId={get(patient, 'id')}
        selectedMonth={selectedMonth}
        handleExport={handleExport}
        exportIsLoading={isExporting}
      />
      <div className="col-12">
        <PageMenu
          searchBy={searchBy}
          setSearchBy={setSearchBy}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
          setExportDialogVisible={setExportDialogVisible}
        />
      </div>
      <div className="flex flex-row flex-wrap">
        <ContentBlock isLoading={isLoading || !patient} isEmpty={isSuccess && isEmpty(billableSessions)} emptyPlaceholder="No Sessions this Month">
          {map(filteredSessions, (session) => (
            <SessionCard
              key={session.id}
              patientId={patient?.id}
              session={session}
              currentUserCCMEligible={currentUserCCMEligible}
            />
          ))}
        </ContentBlock>
      </div>
    </>
  )
}

export default PatientSessions
