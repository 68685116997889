import React, { useEffect, useState } from 'react'
import {
  get,
} from 'lodash'
import { RRule } from 'rrule'
import { Dropdown } from 'primereact/dropdown'
import DaysOfWeekControls from './DaysOfWeekControls'
import MonthlyControls from './MonthlyControls'
import AdvancedPatternControls from './AdvancedControls'
import { patternViews } from './config'

function SchedulePattern({ scheduleRrule, setScheduleRrule }) {
  const [patternView, setPatternView] = useState('Daily')
  const [frequency, setFrequency] = useState(RRule.DAILY)
  const [interval, setInterval] = useState(1)
  const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState()

  const handlePatternViewChange = ({ value }) => {
    setInterval(1)
    setSelectedDaysOfWeek([])

    if (value === 'Daily') {
      setFrequency(RRule.DAILY)
    } else if (value === 'Weekly') {
      setFrequency(RRule.WEEKLY)
    } else if (value === 'Monthly') {
      setFrequency(RRule.MONTHLY)
      setSelectedDaysOfWeek([RRule.SU.nth(1)])
    } else if (value === 'Advanced') {
      setInterval(2)
      setFrequency(RRule.DAILY)
    }

    setPatternView(value)
  }

  const rrule = new RRule({
    freq: frequency,
    interval,
    byweekday: selectedDaysOfWeek,
  }).toString()

  useEffect(() => { if (scheduleRrule !== rrule) setScheduleRrule(rrule) }, [rrule])

  useEffect(() => {
    if (scheduleRrule) {
      const options = get(RRule.fromString(scheduleRrule), 'origOptions')

      setFrequency(options.freq)
      setInterval(options.interval)
      setSelectedDaysOfWeek(options.byweekday)

      if (options.interval > 1) {
        setPatternView('Advanced')
      } else if (options.freq === RRule.WEEKLY) {
        setPatternView('Weekly')
      } else if (options.freq === RRule.MONTHLY) {
        setPatternView('Monthly')
      }
    }
  }, [])

  return (
    <div className="flex flex-column gap-5 align-items-center w-full">
      <div className="flex flex-column gap-3 text-center w-full">
        <label className="text-lg font-normal mb-1" htmlFor="schedule-pattern-view">
          What days should this medication be administered on?
        </label>
        <div className="p-inputgroup">
          <Dropdown
            inputId="schedule-pattern-view"
            aria-labelledby="schedule-pattern-view"
            className="schedule-pattern"
            value={patternView}
            options={patternViews}
            onChange={handlePatternViewChange}
          />
        </div>
        {
          patternView === 'Weekly' && (
            <DaysOfWeekControls
              selectedDaysOfWeek={selectedDaysOfWeek}
              setSelectedDaysOfWeek={setSelectedDaysOfWeek}
            />
          )
        }
        {
          patternView === 'Monthly' && (
            <MonthlyControls
              selectedDaysOfWeek={selectedDaysOfWeek}
              setSelectedDaysOfWeek={setSelectedDaysOfWeek}
            />
          )
        }
        {
          patternView === 'Advanced' && (
            <AdvancedPatternControls
              interval={interval}
              setInterval={setInterval}
              frequency={frequency}
              setFrequency={setFrequency}
              selectedDaysOfWeek={selectedDaysOfWeek}
              setSelectedDaysOfWeek={setSelectedDaysOfWeek}
            />
          )
        }
      </div>
    </div>
  )
}

export default SchedulePattern
