import React, {
  createContext, useContext, useMemo,
} from 'react'
import { get } from 'lodash'
import { Button } from 'primereact/button'
import { Skeleton } from 'primereact/skeleton'
import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'
import { classNames } from 'primereact/utils'
import { Tag } from 'primereact/tag'
import { Tooltip } from 'primereact/tooltip'
import { useEllipsisTooltip } from '@services/hooks'

const AttributeContext = createContext()

function useattributeContext() {
  const attributeContext = useContext(AttributeContext)
  if (attributeContext === undefined) {
    throw new Error('<Attribute /> must be used inside and <AttributeCard />')
  }
  return attributeContext
}

export function Attribute({
  label, value, tooltipContent = null, allowMultiline = false,
}) {
  const [spanRef, isOverflowing] = useEllipsisTooltip()
  const { direction, isLoading } = useattributeContext()

  const valueTemplate = () => (
    isLoading ? <Skeleton className="inline-flex" width="5rem" height="1rem" /> : value
  )

  const tooltipText = tooltipContent || value

  return (
    <>
      <Tooltip target=".text-overflow-ellipsis" />
      <div className="flex flex-row align-items-center">
        <span className={classNames('p-0 mr-1 text-600', { 'col-5': direction === 'vertical' })}>
          {label}
        </span>
        <span
          ref={spanRef}
          className={classNames(
            {
              'w-full': direction === 'vertical',
              'white-space-nowrap overflow-hidden text-overflow-ellipsis': !allowMultiline,
            },
          )}
          data-pr-tooltip={isOverflowing && !allowMultiline ? tooltipText : null}
        >
          {valueTemplate()}
        </span>
      </div>
    </>
  )
}

function AttributeCard({
  children, title, action, isLoading, className = 'h-full', direction = 'vertical', tags = [], ActionComponent,
}) {
  const context = useMemo(() => ({ direction, isLoading }), [direction, isLoading])
  return (
    <AttributeContext.Provider value={context}>
      <Card className={className}>
        <div className="flex flex-column gap-3">
          <div className="flex flex-row justify-content-between align-items-center text-900">
            <div className="flex gap-2 align-items-center">
              <span className="text-lg">
                {title.body}
                {title.icon && <i className={`pi ${title.icon} mr-2`} />}
                {title.label}
              </span>
              {get(tags, 'length') > 0
                && (
                  tags.map((tag, idx) => (
                    <Tag key={`${tag.value}-${idx}`} className="mr-1 text-xs" rounded {...tag} data-testid={`${tag.value.toLowerCase().split(' ').join('-')}-tag`} />
                  ))
                )}
            </div>
            {action
              && (
              <Button
                className="p-button-sm p-button-text py-1 px-1 px-2"
                label={action.label}
                onClick={action.command}
              />
              )}
            {ActionComponent && (<ActionComponent />)}
          </div>
          <Divider className="my-0" />
          <div className={
            classNames('flex gap-3 text-base text-900', {
              'flex-row flex-wrap': direction === 'horizontal',
              'flex-column': direction === 'vertical',
            })
          }
          >
            {children}
          </div>
        </div>
      </Card>
    </AttributeContext.Provider>
  )
}

export default AttributeCard
