import React, { useState, useEffect } from 'react'
import { first, map } from 'lodash'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { generatePastTwelveMonths } from '@services/utils'

function ExportClinicalReportDialog({
  visible, setVisible, handleExport, exportIsLoading, patients, patientId,
}) {
  const [months, setMonths] = useState()
  const [selectedMonth, setSelectedMonth] = useState()
  const [selectedPatientId, setSelectedPatientId] = useState()

  const selectedMonthTemplate = (option) => (
    <>
      <i className="pi pi-calendar mr-2" />
      <span>{option?.label}</span>
    </>
  )

  const handleSubmission = async () => {
    await handleExport(selectedPatientId, selectedMonth)
    setVisible(false)
  }

  useEffect(() => {
    const pastTwelveMonths = generatePastTwelveMonths()
    setMonths(pastTwelveMonths)
    setSelectedMonth(first(pastTwelveMonths).value)
  }, [])

  useEffect(() => {
    if (patientId) {
      setSelectedPatientId(patientId)
    }
  }, [patientId])

  return (
    <Dialog
      header="Export Clinical Report"
      visible={visible}
      onHide={() => setVisible(false)}
    >
      <div className="flex flex-column gap-3 text-base font-normal mt-2">
        <div className="flex flex-row gap-2 align-items-center mb-2">
          <span className="font-normal col-4">Export month:</span>
          <Dropdown
            className="w-14rem col-8"
            options={months}
            onChange={(e) => setSelectedMonth(e.value)}
            value={selectedMonth}
            valueTemplate={selectedMonthTemplate}
          />
        </div>
        {
            !patientId && (
              <div className="flex flex-row gap-2 align-items-center mb-2">
                <span className="font-normal col-4">Patient:</span>
                <Dropdown
                  className="w-14rem col-8"
                  options={map(patients, (patient) => ({ label: `${patient.firstName} ${patient.lastName}`, value: patient.id }))}
                  onChange={(e) => setSelectedPatientId(e.value)}
                  value={selectedPatientId}
                  placeholder="Select a patient"
                  showClear
                />
              </div>
            )
          }
        {
            !patientId && !selectedPatientId && (
              <span className="text-sm text-gray-500">
                If you do not select a patient, the export will include all patients.
              </span>
            )
          }
        <Button
          className="p-button-sm"
          label="Export"
          onClick={handleSubmission}
          loading={exportIsLoading}
        />
      </div>
    </Dialog>
  )
}

export default ExportClinicalReportDialog
