import React from 'react'
import { Divider } from 'primereact/divider'
import { Skeleton } from 'primereact/skeleton'
import { Card } from 'primereact/card'

function LoadingGrid() {
  return Array.from({ length: 6 }).map((_, index) => (
    <div className="col-4 fadein" key={index}>
      <Card className="h-full">
        <div className="flex flex-column gap-2">
          <Skeleton className="inline-flex" width="100%" height="3rem" />
          <Divider className="my-1" />
          <Skeleton className="inline-flex" width="100%" height="10rem" />
        </div>
      </Card>
    </div>
  ))
}

export default LoadingGrid
