import { get, map } from 'lodash'
import moment from 'moment'
import { administrationRoutes } from '@components/clientDoses/config'
import { momentToIso8601Str, momentToIso8601StrUtc } from '@services/utils/moment'

/**
 * This utility function builds the dose portion of the request payload to acknowledge a dose.
 * @param {Object} reviewableOrderState The current reviewable order state
 * @param {Integer} matchedDoseId The matched dose id
 * @returns {Object}
 */
export const buildDoseParams = (
  reviewableOrderState,
  matchedDoseId,
) => {
  let administrationRoute = reviewableOrderState?.administrationRoute
  if (!administrationRoutes[administrationRoute]) administrationRoute = null
  const startAt = reviewableOrderState?.startAt
    ? momentToIso8601StrUtc(reviewableOrderState?.startAt) : null
  const endAt = reviewableOrderState?.endAt
    ? momentToIso8601StrUtc(reviewableOrderState?.endAt) : null

  const firstSigPRN = get(reviewableOrderState, 'sigs[0].prn') || false

  const doseParams = {
    ...reviewableOrderState,
    startAt,
    endAt,
    administrationRoute,
    prn: firstSigPRN,
    doseFills: map(
      reviewableOrderState?.doseFills,
      // TODO: Consider passing pharmacyOrderId to associate pharmacy orders with dose fills. This
      //       would also tell us if the order is RE or RF to configure the dose fill accordingly.
      ({ fillsLeft, dosesInFill, pharmacyDispensedAt }) => ({
        expiresAt: endAt || moment().add(1, 'year').toISOString(),
        dosesInFill,
        pharmacyDispensedAt,
        startingInventory: fillsLeft,
      }),
    ),
    doseType: reviewableOrderState?.orderType,
    pharmacyMedicineAttributes: {
      id: reviewableOrderState?.medicine?.id,
      csaSchedule: reviewableOrderState?.medicine?.csaSchedule,
    },
  }

  doseParams.doseSigs = reviewableOrderState?.sigs.map((sig) => ({
    // We only want to set the id to the dose sig's id if it exists
    ...(sig.doseSigId ? { id: sig.doseSigId } : {}),
    administrationAmount: sig.administrationAmount,
    endAt: sig.endAt ? momentToIso8601StrUtc(sig.endAt) : null,
    endAtWall: sig.endAtWall ? momentToIso8601Str(sig.endAt) : null,
    hl7ScheduleCodes: sig.hl7ScheduleCode && sig.hl7ScheduleCode.trim() !== '' ? sig.hl7ScheduleCode.split(' ') : [],
    hl7SetId: sig.hl7SetId,
    note: sig.note,
    pharmacyInstructions: sig.pharmacyInstructions,
    prn: sig.prn,
    schedules: sig.schedules?.map((schedule) => ({
      ...(schedule.id ? { id: schedule.id } : {}),
      time_hh_mm: schedule.time_hh_mm,
      rrule: schedule.rrule,
      flexibilityAttributes: schedule.flexibility,
      eventTime: schedule.eventTime,
      eventTimeDirective: schedule.eventTimeDirective,
    })),
    startAt: sig.startAt ? momentToIso8601StrUtc(sig.startAt) : null,
    startAtWall: sig.startAtWall ? momentToIso8601Str(sig.startAt) : null,
    units: sig.units,
  }))

  // Unnecessary fields from reviewableOrderState that are not needed in the payload
  delete doseParams.doseFills
  delete doseParams.medicine
  delete doseParams.orderType
  delete doseParams.schedules
  delete doseParams.sigs

  if (matchedDoseId) {
    doseParams.id = matchedDoseId
  }

  return doseParams
}

export default {}
