import { useMemo } from 'react'
import { map, get } from 'lodash'
import { useQuery, useMutation } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '../../services/errorHandler'

export function usePermissionRequests({ statusMessage, dateRange }) {
  const query = useQuery({
    queryKey: ['permissionRequests', dateRange],
    queryFn: () => {
      const [startAt, endAt] = dateRange
      const params = { startDate: startAt, endDate: endAt }
      return ApiClient.get('/permission_requests', { params })
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(query.data, (permissionRequests) => permissionRequests),
  }), [query])
}

export function useHighRiskImpacts({ dateRange, siteIds, statusMessage }) {
  const query = useQuery({
    queryKey: ['highRiskImpacts', dateRange, siteIds],
    queryFn: () => {
      const [startAt, endAt] = dateRange
      const params = { startDate: startAt, endDate: endAt, siteIds }
      return ApiClient.get('/impact_checks/high_risk', { params })
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.impactChecks', []), (impact) => impact),
  }), [query])
}

export function useHighRiskConsumptions({ dateRange, siteIds, statusMessage }) {
  const query = useQuery({
    queryKey: ['highRiskConsumptions', dateRange, siteIds],
    queryFn: () => {
      const [startAt, endAt] = dateRange
      const params = { startDate: startAt, endDate: endAt, siteIds }
      return ApiClient.get('/dashboard_consumptions/high_risk', { params })
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(query.data, (consumption) => consumption),
  }), [query])
}

export function useOnTimeConsumptions({ dateRange, siteIds, statusMessage }) {
  const query = useQuery({
    queryKey: ['onTimeConsumptions', dateRange, siteIds],
    queryFn: () => {
      const [startAt, endAt] = dateRange
      const params = { startDate: startAt, endDate: endAt, siteIds }
      return ApiClient.get('/dashboard_consumptions/on_time', { params })
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(query.data, (consumption) => consumption),
  }), [query])
}

export function useConsumptionReminder(statusMessage) {
  return useMutation({
    mutationKey: ['consumptionReminder'],
    mutationFn: (consumption) => ApiClient.post(`/consumptions/${consumption.id}/remind_caregivers`),
    onSuccess: ({ success = [], error = [] }) => {
      statusMessage.current.clear()

      error.forEach((message) => statusMessage.current.show([
        { severity: 'error', summary: message, sticky: true },
      ]))

      success.forEach((message) => statusMessage.current.show([
        { severity: 'success', summary: message },
      ]))
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}
