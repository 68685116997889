import React from 'react'

import SuperAdminCard from '@components/display/SuperAdminCard'
import DosePharmacyOrders from '@components/clientDoses/Orders/DosePharmacyOrders'

function Orders({ doseId }) {
  return (
    <div className="col-12">
      <SuperAdminCard title="Orders">
        <DosePharmacyOrders doseId={doseId} />
      </SuperAdminCard>
    </div>
  )
}

export default Orders
