import React from 'react'
import { NavLink } from 'react-router-dom'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import { Badge } from 'primereact/badge'
import { useCurrentOrganization } from '@components/App'

function Menu() {
  const currentOrganization = useCurrentOrganization()
  const viewLinks = []
  if (!currentOrganization.ekitsEnabled) {
    viewLinks.push({
      label: 'Schedule',
      linkTo: 'schedule',
    })
  }
  viewLinks.push({
    label: 'PRN',
    linkTo: 'prn',
  })

  return (
    <div className="flex flex-row justify-space-between align-items-center pr-4">
      <div className="flex flex-row align-items-center">
        { viewLinks.map((link) => (
          <NavLink to={link.linkTo} key={link.linkTo}>
            {
                ({ isActive }) => (
                  <Button className={classNames('flex flex-row align-items-center p-button-sm p-button-outlined', { 'p-button-text': !isActive })}>
                    {!!link.badge
                      && (
                        <Badge severity={link.badge.severity} value={link.badge.value} />
                      )}
                    <span className={classNames({ 'text-600': !isActive, 'ml-2': !!link.badge })}>
                      {link.label}
                    </span>
                  </Button>
                )
              }
          </NavLink>
        ))}
      </div>
    </div>
  )
}
export default Menu
