import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { get, map } from 'lodash'
import { useMemo } from 'react'

export function useSites(organizationId, statusMessage) {
  const query = useQuery({
    queryKey: ['sites', organizationId],
    queryFn: () => ApiClient.get(`/sites?organization_id=${organizationId}`),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.sites'), (site) => (site)),
  }), [query.data])
}

export function useSite(siteId) {
  return useQuery({ queryKey: ['site', siteId], queryFn: () => ApiClient.get(`/sites/${siteId}`), enabled: !!siteId })
}

export function useSiteWifis(siteId, statusMessage) {
  const query = useQuery({
    queryKey: ['siteWifi', siteId],
    queryFn: () => ApiClient.get(`/sites/${siteId}/wifis`),
    enabled: !!siteId,
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.siteWifis'), (wifi) => (wifi)),
  }), [query.data])
}

export function useCreateSiteMutation(statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (site) => ApiClient.post('/sites', site),
    onSuccess: () => {
      queryClient.invalidateQueries('sites')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useUpdateSiteMutation(statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (site) => {
      await ApiClient.put(`/sites/${site.id}`, site)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries('sites')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useDeleteSiteMutation(statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (siteId) => ApiClient.delete(`/sites/${siteId}`),
    onSuccess: () => {
      queryClient.invalidateQueries('sites')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useUpsertSiteWifiMutation(siteId, statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (wifi) => {
      const wifiId = get(wifi, 'siteWifi.id')
      if (wifiId) {
        return ApiClient.put(`/sites/${siteId}/wifis/${wifiId}`, wifi)
      }
      return ApiClient.post(`/sites/${siteId}/wifis`, wifi)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['siteWifi', siteId])
      statusMessage.current.clear()
      statusMessage.current.show([{
        severity: 'success',
        detail: 'Wi-Fi saved successfully',
        sticky: true,
      }])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}
