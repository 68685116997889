import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Tooltip } from 'primereact/tooltip'
import { InputTextarea } from 'primereact/inputtextarea'
import { InputNumber } from 'primereact/inputnumber'
import { SelectButton } from 'primereact/selectbutton'
import { Calendar } from 'primereact/calendar'
import './ccm-styles.scss'

function Timer({
  handleCreateSession,
  handleUpdateSession,
  updateLoading,
  isUpdate,
  time,
  sessionDate,
  sessionDuration,
  setTimeInputOptions,
  setPlaceholder,
}) {
  const [seconds, setSeconds] = useState(0)
  const [start, setStart] = useState(false)
  const [counter, setCounter] = useState()
  // for manual time
  const [date, setDate] = useState(sessionDate || new Date())
  const [duration, setDuration] = useState((sessionDuration ? sessionDuration / 60 : 0) || 0)
  // for timer
  const [startTime, setStartTime] = useState(get(time, 'timeSpan.startAt'))
  const [endTime, setEndTime] = useState(get(time, 'timeSpan.endAt'))
  const [note, setNote] = useState(get(time, 'note'))
  const [timeView, setTimeView] = useState('manual')

  const timerOptions = [
    {
      label: 'Manual',
      value: 'manual',
      icon: 'pi pi-file-edit',
      className: 'p-button-sm px-3 py-2',
    },
    {
      label: 'Stopwatch',
      value: 'stopwatch',
      icon: 'pi pi-stopwatch',
      className: 'p-button-sm px-3 py-2',
    },
  ]

  const timerSelectTemplate = (option) => (
    <>
      <Tooltip target={`.${option.value}`} content={option.label} position="top" />
      <i className={`${option.icon} ${option.value}`} />
    </>
  )

  const timeInputOptions = (
    <SelectButton
      value={timeView}
      options={timerOptions}
      itemTemplate={timerSelectTemplate}
      onChange={(e) => setTimeView(e.value)}
    />
  )

  const timer = () => {
    const interval = setInterval(() => {
      setSeconds((s) => s + 1)
    }, 1000)
    setCounter(interval)
  }

  const formatTime = (timeToFormat) => {
    if (timeToFormat.toString().length === 1) {
      return `0${timeToFormat}`
    }
    return timeToFormat
  }

  const handleStart = () => {
    setStart(true)
    setStartTime(new Date())
    timer()
  }

  const handleStop = () => {
    setStart(false)
    setEndTime(new Date())
    clearInterval(counter)
  }

  const handleCreate = () => {
    const startAt = new Date(startTime)
    const endAt = new Date(endTime)
    endAt.setFullYear(startAt.getFullYear(), startAt.getMonth(), startAt.getDate())

    const timeSpan = time ? time.timeSpan : {
      startAt,
      endAt,
    }

    handleCreateSession({
      note,
      timeSpan: timeView === 'stopwatch' ? timeSpan : null,
      duration: timeView === 'manual' ? (duration || 0) * 60 : null,
      date: timeView === 'manual' ? date : null,
    })
  }

  const handleUpdate = () => {
    handleUpdateSession({ note })
  }

  const clear = () => {
    clearInterval(counter)
    setStart(false)
    setStartTime()
    setEndTime()
    setDuration()
    setSeconds(0)
  }

  useEffect(() => {
    clear()
    if (time && !isUpdate) {
      setTimeView('manual')
    }
  }, [time])

  useEffect(() => {
    clear()
    if (!isUpdate) {
      setTimeInputOptions(timeInputOptions)
      if (time) {
        setStartTime(get(time, 'timeSpan.startAt'))
        setEndTime(get(time, 'timeSpan.endAt'))
      }
      if (sessionDuration) {
        setDuration(sessionDuration / 60)
      }
    }
  }, [timeView])

  useEffect(() => {
    setPlaceholder()
    window.scrollTo({ top: 0, behavior: 'smooth' })
    return () => {
      clearInterval(counter)
      setStart(false)
      setStartTime()
      setEndTime()
      setDuration()
    }
  }, [])

  return (
    <div className="ccm-timer flex flex-row flex-wrap">
      { timeView === 'manual' && !isUpdate
        && (
        <div className="col-12 md:col-5">
          <Card className="h-full">
            <div className="col-12 flex flex-column gap-2">
              <label htmlFor="date-input">Date</label>
              <Calendar
                inputId="date-input"
                maxDate={new Date()}
                value={date}
                onChange={(e) => setDate(e.value)}
              />
            </div>
            <div className="col-12 flex flex-column gap-2">
              <label htmlFor="duration-input">Duration (minutes)</label>
              <InputNumber
                id="duration-input"
                value={duration}
                onChange={(e) => setDuration(e.value)}
                mode="decimal"
                min={0}
                max={1440}
              />
            </div>
            {date && duration > 0
              && (
              <div className="col-12">
                <Button
                  label="Save"
                  icon="pi pi-save"
                  onClick={handleCreate}
                  loading={updateLoading}
                  className="p-button-sm font-light p-button-success w-full"
                />
              </div>
              )}
          </Card>
        </div>
        )}
      { timeView === 'stopwatch' && !isUpdate && !time
        && (
        <div className="col-12 md:col-5">
          <Card className="h-full">
            <div className="grid justify-space-between">
              <div className="col-5 md:col-4 flex flex-column gap-3">
                {!start && seconds === 0
                  && (
                  <Button
                    label="Start"
                    icon="pi pi-hourglass"
                    onClick={handleStart}
                    className="p-button-sm font-light p-button-primary"
                  />
                  )}
                {!start && seconds !== 0
                  && (
                  <Button
                    label="Save"
                    icon="pi pi-save"
                    onClick={handleCreate}
                    loading={updateLoading}
                    className="p-button-sm font-light p-button-success"
                  />
                  )}
                {start
                  && (
                  <Button
                    label="Stop"
                    icon="pi pi-pause"
                    onClick={handleStop}
                    className="p-button-sm font-light p-button-secondary p-button-outlined"
                  />
                  )}
                <Button
                  label="Reset"
                  onClick={clear}
                  disabled={start || seconds === 0}
                  className="p-button-sm p-button-warning p-button-text font-light"
                />
              </div>
              <div className="col-6 md: col:8 flex flex-row p-4 border-300 border-solid border-round text-4xl justify-content-center align-items-center mr-2">
                <span>
                  {formatTime(Math.floor(seconds / 60))}
                </span>
                <span>:</span>
                <span style={{ minWidth: '40px' }}>
                  {formatTime(Math.round(seconds % 60))}
                </span>
              </div>
            </div>
          </Card>
        </div>
        )}
      <div className="col-12 md:col-7">
        <Card className="h-full">
          <InputTextarea
            placeholder="Use this text area to provide any notes about the session."
            rows={7}
            cols={30}
            value={note}
            autoResize
            onChange={(e) => setNote(e.target.value)}
          />
          {(isUpdate)
            && (
            <Button
              label="Save"
              icon="pi pi-save"
              onClick={isUpdate ? handleUpdate : handleCreate}
              loading={updateLoading}
              className="p-button-sm font-light p-button-success mt-4 w-full"
            />
            )}
        </Card>
      </div>
    </div>
  )
}

export default Timer
