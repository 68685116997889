import React, { useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom'
import SearchField from '@components/display/Form/SearchField'
import { usePendingUsers } from './onboardUsersHooks'

function Header({ setSearchTerm, isLoading }) {
  const [name, setName] = useState('')

  const handleSearchRequest = debounce((value) => (setSearchTerm(value)), 500)

  const handleSearch = (value) => {
    setName(value)
    handleSearchRequest(value)
  }

  return (
    <div className="flex flex-row align-items-center">
      <span className="mt-2 md:mt-0">
        <SearchField
          searchTerm={name}
          setSearchTerm={handleSearch}
          placeholder="Search by name"
          isLoading={isLoading}
        />
      </span>
    </div>
  )
}

function OnboardUsers({ organizationId, useOnboardUsersHeader }) {
  const { setHeader } = useOnboardUsersHeader()
  const [searchTerm, setSearchTerm] = useState('')
  const { isLoading, data: pendingUsers } = usePendingUsers({ organizationId, searchTerm })

  const onboardActionTemplate = ({ id }) => (
    <Link to={`/admin/pending_users/${id}`}>
      <Button
        className="p-button-sm p-button-text w-2rem h-2rem p-0"
        icon="pi pi-user-plus"
        data-testid={`pendingUserLink.${id}`}
      />
    </Link>
  )

  useEffect(() => {
    const labelText = 'Pending Users'
    const title = {
      label: (
        <div className="py-3 px-4">
          <span>
            {labelText}
          </span>
        </div>
      ),
    }
    setHeader({
      title,
      breadcrumbs: [
        {
          label: labelText,
          template: <span>{labelText}</span>,
        },
      ],
    })
  }, [])

  return (
    <Card className="m-2">
      <div className="p-border-light">
        <DataTable
          tableStyle={{ marginBottom: '0px' }}
          dataKey="id"
          loading={isLoading && !searchTerm}
          value={pendingUsers}
          header={<Header setSearchTerm={setSearchTerm} isLoading={isLoading} />}
        >
          <Column header="First Name" field="firstName" />
          <Column header="Last Name" field="lastName" />
          <Column header="External Id" field="externalId" />
          <Column header="Admitted At" field="admittedAt" />
          <Column
            header="Onboard"
            body={onboardActionTemplate}
          />
        </DataTable>
      </div>
    </Card>
  )
}

export default OnboardUsers
