import React, { useState, useEffect } from 'react'
import { first } from 'lodash'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { generatePastTwelveMonths } from '@services/utils'

function ExportBillingReportDialog({
  visible, setVisible, handleExport, exportIsLoading,
}) {
  const [months, setMonths] = useState()
  const [selectedMonth, setSelectedMonth] = useState()

  const selectedMonthTemplate = (option) => (
    <>
      <i className="pi pi-calendar mr-2" />
      <span>{option?.label}</span>
    </>
  )

  const handleSubmission = async () => {
    await handleExport(selectedMonth)
    setVisible(false)
  }

  useEffect(() => {
    const pastTwelveMonths = generatePastTwelveMonths()
    setMonths(pastTwelveMonths)
    setSelectedMonth(first(pastTwelveMonths).value)
  }, [])

  return (
    <Dialog
      header="Export Billing Report"
      visible={visible}
      onHide={() => setVisible(false)}
    >
      <div className="flex flex-column gap-3 text-base font-normal mt-2">
        <div className="flex flex-row gap-2 align-items-center mb-2">
          <span className="font-normal">Export month:</span>
          <Dropdown
            className="w-14rem"
            options={months}
            onChange={(e) => setSelectedMonth(e.value)}
            value={selectedMonth}
            valueTemplate={selectedMonthTemplate}
          />
        </div>
        <Button
          className="p-button-sm"
          label="Export"
          onClick={handleSubmission}
          loading={exportIsLoading}
        />
      </div>
    </Dialog>
  )
}

export default ExportBillingReportDialog
