import React from 'react'
import { Message } from 'primereact/message'

function Notifications({ notifications }) {
  return notifications.map((notification, index) => {
    let margin = 'mx-3 mt-3'
    if (index === notifications.length - 1) {
      margin += ' mb-3'
    }
    return (
      <Message
        key={`notification${index}`}
        className={`border-primary justify-content-startcustom-message-success ${margin}`}
        severity="info"
        content={notification}
      />
    )
  })
}

export default Notifications
