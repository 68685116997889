import React, { useState } from 'react'
import { map, sortBy } from 'lodash'
import {
  DoseAttributeRow,
  TextAreaEditor,
  EditButton,
  AdministrationRouteEditor,
  DropdownEditor,
} from '@components/DoseAttributeRow'
import '../../style.scss'
import { csaScheduleText } from '@components/medicines/csaScheduleLabel'
import { administrationRoutes } from '../../../clientDoses/config'
import {
  useReviewableOrderState,
  useReviewableOrderHandlers,
} from '../../../../features/ClientDoses/PharmacyOrders/reviewableOrderState/ReviewableOrderContext'

function DoseFieldGroup({
  compositeOrder = {},
}) {
  const [editAttribute, setEditAttribute] = useState(null)

  const {
    note,
    administrationRoute,
    medicine,
    prescription,
  } = useReviewableOrderState()

  const {
    handleUpdateMedicine,
    handleUpdateAdministrationRoute,
    handleUpdateNote,
  } = useReviewableOrderHandlers()

  const doseAttributes = [
    {
      label: 'Controlled Substance',
      order: 0,
      currentValue: csaScheduleText(medicine.csaSchedule),
      newValue: csaScheduleText(compositeOrder?.medicine.csaSchedule),
      valueChangeHandler: () => (
        handleUpdateMedicine({
          csaSchedule: compositeOrder?.medicine.csaSchedule,
        })
      ),
      display: Boolean(compositeOrder?.medicine.csaSchedule),
      isEditing: editAttribute === 'csaSchedule',
      action: <EditButton
        onClick={editAttribute === 'csaSchedule' ? () => setEditAttribute(null) : () => setEditAttribute('csaSchedule')}
        isEditing={editAttribute === 'csaSchedule'}
        disabled={editAttribute && editAttribute !== 'csaSchedule'}
      />,
      editor: <DropdownEditor
        option={medicine.csaSchedule}
        options={[
          { label: 'Schedule I', value: 1 },
          { label: 'Schedule II', value: 2 },
          { label: 'Schedule III', value: 3 },
          { label: 'Schedule IV', value: 4 },
          { label: 'Schedule V', value: 5 },
        ]}
        setOption={(value) => {
          handleUpdateMedicine({ csaSchedule: value })
          setEditAttribute(null)
        }}
      />,
    },
    {
      label: 'Medicine',
      order: 1,
      currentValue: compositeOrder?.medicine.orderText,
    },
    {
      label: 'Prescription Number',
      order: 2,
      currentValue: prescription?.prescriptionNumber,
    },
    {
      label: 'Notes',
      order: 3,
      currentValue: note,
      newValue: compositeOrder?.note,
      valueChangeHandler: () => (
        handleUpdateNote({
          note: compositeOrder?.note,
        })
      ),
      editor: (
        <TextAreaEditor
          text={note || ''}
          setText={(value) => {
            handleUpdateNote({ note: value })
            setEditAttribute(null)
          }}
        />
      ),
      isEditing: editAttribute === 'note',
      action: (
        <EditButton
          onClick={() => (editAttribute === 'note'
            ? setEditAttribute(null)
            : setEditAttribute('note'))}
          isEditing={editAttribute === 'note'}
        />
      ),
    },
    {
      label: 'Administration Route',
      order: 5,
      newValue: administrationRoutes[compositeOrder?.administrationRoute]?.name,
      currentValue: administrationRoutes[administrationRoute]?.name,
      valueChangeHandler: () => (
        handleUpdateAdministrationRoute({
          administrationRoute: compositeOrder?.administrationRoute,
        })
      ),
      action: (
        <EditButton
          onClick={() => (editAttribute === 'administrationRoute' ? setEditAttribute(null) : setEditAttribute('administrationRoute'))}
          isEditing={editAttribute === 'administrationRoute'}
        />
      ),
      editor: (
        <AdministrationRouteEditor
          administrationRoute={administrationRoute}
          setAdministrationRoute={(value) => {
            handleUpdateAdministrationRoute({ administrationRoute: value })
            setEditAttribute(null)
          }}
        />
      ),
      isEditing: editAttribute === 'administrationRoute',
    },
  ]

  const sortedAttributes = sortBy(doseAttributes.filter(({ display = true }) => display), 'order')

  return (
    <ul className="list-none p-0 m-0 dose-order-fields">
      {map(sortedAttributes, ({
        label, currentValue, newValue, action,
        valueChangeHandler, editor, isEditing,
      }, index) => (
        <DoseAttributeRow
          key={label}
          label={label}
          valueChangeHandler={valueChangeHandler}
          currentValue={currentValue}
          newValue={newValue}
          action={action}
          editor={editor}
          isEditing={isEditing}
          backgroundHighlight={index % 2 === 0}
        />
      ))}
    </ul>
  )
}

export default DoseFieldGroup
