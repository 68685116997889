import { useMutation, useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { get, map } from 'lodash'
import { useMemo } from 'react'
import { displayErrorMessages } from '@services/errorHandler'

export function useIntegrationDataQuery(integrationDataId) {
  return useQuery(
    {
      queryKey: ['integrationData', integrationDataId],
      queryFn: () => ApiClient.get(`/integration_data/${integrationDataId}`),
      enabled: !!integrationDataId,
    },
  )
}

export function useFacilityIntegrationDataQuery(facilityId, status = 'succeeded') {
  const query = useQuery(
    {
      queryKey: ['facilityFailedIntegrationData', facilityId, status],
      queryFn: () => ApiClient.get('/integration_data', { params: { facilityId, status } }),
      enabled: !!facilityId,
    },
  )

  return useMemo(() => ({
    ...query,
    isLoading: query.isLoading,
    data: map(get(query, 'data.integrationData'), (data) => (data)),
  }), [query.data])
}

export function useIntegrationDataFilterCountMutation(messagesRef) {
  return useMutation({
    mutationKey: 'integrationDataSearchCount',
    mutationFn: (searchCriteria) => ApiClient.get('/integration_data/filter_count', { params: searchCriteria }),
    onError: (error) => {
      displayErrorMessages(error, messagesRef)
    },
  })
}

export function useIntegrationDataRetryFailedMutation(messagesRef) {
  return useMutation({
    mutationKey: 'integrationDataRetryFailed',
    mutationFn: (body) => ApiClient.post('/integration_data/retry_failed', body),
    onError: (error) => {
      displayErrorMessages(error, messagesRef)
    },
  })
}

export function useIntegrationDataBulkIgnoreMutation(messagesRef) {
  return useMutation({
    mutationKey: 'integrationDataBulkIgnore',
    mutationFn: (body) => ApiClient.post('/integration_data/bulk_ignore', body),
    onError: (error) => {
      displayErrorMessages(error, messagesRef)
    },
  })
}

export function useIntegrationDataFacilityExternalIdCountQuery(
  facilityExternalId,
  pharmacyExternalId,
  messagesRef,
) {
  return useQuery({
    queryKey: ['integrationDataFacilityExternalIdCount', facilityExternalId, pharmacyExternalId],
    queryFn: () => ApiClient.get('/integration_data/facility_stats', { params: { facilityExternalId, pharmacyExternalId } }),
    enabled: !!facilityExternalId && !!pharmacyExternalId,
    onError: (error) => {
      displayErrorMessages(error, messagesRef)
    },
  })
}
