import React, { useState } from 'react'
import { Button } from 'primereact/button'

function EmptyAreaTemplate({
  rowData, indexOfDay, status, onClick,
}) {
  const [icon, setIcon] = useState('pi pi-minus')

  const handleMouseEnter = () => {
    setIcon('pi pi-plus-circle')
  }

  const handleMouseLeave = () => {
    setIcon('pi pi-minus')
  }

  return (
    <div className="flex justify-content-center p-0 empty-area">
      <Button
        icon={icon}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="p-0 w-1rem h-1rem border-none bg-transparent text-400"
        rounded
        size="small"
        onClick={(e) => onClick(rowData, indexOfDay, status, e)}
      />
    </div>
  )
}

export default EmptyAreaTemplate
