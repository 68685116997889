import React, { useState } from 'react'
import { Card } from 'primereact/card'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { formatDateTimeLocale } from '@services/utils'
import PatientPausingDialog from '@components/patients/PatientForm/PatientPausingDialog'
import {
  usePatientPauseMutation,
  usePatientPauseUpdateMutation,
  useLeavOfAbsences,
} from '../../features/Patients/patientHooks'

function LOAHistory({
  patient, patientLoading, statusMessage,
}) {
  const [showLeaveOfAbsenceDialog, setShowLeaveOfAbsenceDialog] = useState(false)
  const [leaveOfAbsenceToEdit, setLeaveOfAbsenceToEdit] = useState(null)

  const query = useLeavOfAbsences(patient?.id)
  const { leaveOfAbsences = [] } = query?.data || {}

  const {
    mutateAsync: createLOA,
    isLoading: isCreatingLOA,
  } = usePatientPauseMutation(patient?.id, statusMessage)

  const {
    mutateAsync: updateLOA,
    isLoading: isUpdatingLOA,
  } = usePatientPauseUpdateMutation(statusMessage)

  const handleCreateLOA = async ({ startDate, endDate, note }) => {
    if (!startDate) {
      statusMessage.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Start Date is required',
      })
      return
    }
    const payload = {
      patientId: patient.id,
      startAt: startDate,
      endAt: endDate,
      note,
    }
    await createLOA(payload, {
      onSuccess: () => {
        setShowLeaveOfAbsenceDialog(false)
      },
    })
  }

  const handleUpdateLOA = async ({ startDate, endDate, note }) => {
    const payload = {
      id: leaveOfAbsenceToEdit.id,
      startAt: startDate,
      endAt: endDate,
      note,
    }
    await updateLOA(payload, {
      onSuccess: () => {
        setShowLeaveOfAbsenceDialog(false)
        setLeaveOfAbsenceToEdit(null)
      },
    })
  }
  const handleEdit = (LOA) => {
    setShowLeaveOfAbsenceDialog(true)
    setLeaveOfAbsenceToEdit(LOA)
  }

  const handleHide = () => {
    setShowLeaveOfAbsenceDialog(false)
    setLeaveOfAbsenceToEdit(null)
  }

  const renderEditButton = (LOA) => (
    <Button
      icon="pi pi-pencil"
      className="p-button-outlined p-button-text text-xs p-1"
      label="Edit"
      type="button"
      onClick={() => handleEdit(LOA)}
    />
  )

  return (
    <Card data-test="loa-history">
      <div className="flex flex-row justify-content-between align-items-center mb-2 text-lg">
        <span>Leave of Absence</span>
        <Button
          label="Add LOA"
          className="p-button-sm"
          type="button"
          icon="pi pi-plus"
          onClick={() => setShowLeaveOfAbsenceDialog(true)}
        />
      </div>
      {
        showLeaveOfAbsenceDialog && (
          <PatientPausingDialog
            patient={patient}
            visible={showLeaveOfAbsenceDialog}
            onHide={handleHide}
            onConfirm={leaveOfAbsenceToEdit ? handleUpdateLOA : handleCreateLOA}
            leaveOfAbsenceToEdit={leaveOfAbsenceToEdit}
            statusMessage={statusMessage}
          />
        )
      }
      <DataTable
        dataKey="id"
        className="fadein"
        loading={patientLoading || isCreatingLOA || isUpdatingLOA}
        sortField="startAt"
        sortOrder={1}
        sortMode="single"
        value={leaveOfAbsences}
      >
        <Column
          className="w-3"
          header="Start Date"
          field="startAt"
          body={({ startAt }) => formatDateTimeLocale(startAt, patient.timezone)}
        />
        <Column
          className="w-3"
          header="End Date"
          field="endAt"
          body={({ endAt }) => formatDateTimeLocale(endAt, patient.timezone)}
        />
        <Column className="w-4" header="Note" field="note" />
        <Column className="w-2" body={renderEditButton} />
      </DataTable>
    </Card>
  )
}

export default LOAHistory
