import moment from 'moment'

// eslint-disable-next-line import/prefer-default-export
export const generateDateColumns = (
  dateRange,
  marApprovals,
  bodyTemplate,
  headerTemplate,
  width,
) => {
  const dateColumns = []

  const startDate = moment(dateRange.startDate)
  const dayCount = dateRange.endDate.diff(dateRange.startDate, 'days') + 1

  let currentDate = startDate.clone()
  for (let dayIndex = 0; dayIndex < dayCount; dayIndex += 1) {
    const currentDateClone = currentDate.clone()
    const formattedDate = moment(currentDate).format(dayCount > 7 ? 'D' : 'MMM Do')
    const dayMarApprovals = marApprovals
      .filter((mar) => mar.days.includes(currentDateClone.format('YYYY-MM-DD')))
    dateColumns.push({
      header: headerTemplate(currentDateClone, formattedDate, dayMarApprovals),
      field: `consumptions.${currentDate.format('MMM-Do').toLowerCase()}`,
      body: (rowData) => bodyTemplate(rowData, dayIndex),
      style: { minWidth: width, width, maxWidth: width },
      align: 'center',
    })
    currentDate = currentDate.add(1, 'days')
  }
  return dateColumns
}
