/**
 * This is the source of truth for the different types of dose conditions (a.k.a. dose criteria)
 * supported by the frontend. This configuration is used by several DoseCondition React components
 * to render the UI for each condition type in a generic and consistent way.
 * Each condition type is an object with the following properties:
 * - code: The code used by the backend and our APIs.
 * - displayText: The human-readable name that will be shown to the user.
 * - exampleNote: The placeholder text that will be shown as an example of a criteria note.
 * - metrics: The human-readable, measurement metrics that are recorded for the condition type.
 * - units: The human-readable, measurement units that are used for the condition type.
 */
export const conditionTypes = [
  {
    code: 'BLOOD_GLUCOSE',
    displayText: 'Blood Glucose',
    exampleNote: 'Example: Do not administer when blood glucose is below X mg/dL.',
    metrics: ['blood glucose'],
    units: ['mg/dL'],
  },
  {
    code: 'BLOOD_OXYGEN',
    displayText: 'Blood Oxygen',
    exampleNote: 'Example: Do not administer when blood oxygen saturation is below X%.',
    metrics: ['blood oxygen'],
    units: ['%'],
  },
  {
    code: 'BLOOD_PRESSURE',
    displayText: 'Blood Pressure',
    exampleNote: 'Example: Do not administer med if systolic > X mmHg.',
    metrics: ['systolic', 'diastolic'],
    units: ['mmHg'],
  },
  {
    code: 'HEIGHT',
    displayText: 'Height',
    exampleNote: 'Example: Do not administer med if height > X ft Y in.',
    metrics: ['height'],
    units: ['cm', 'in'],
  },
  {
    code: 'PULSE',
    displayText: 'Pulse',
    exampleNote: 'Example: Do not administer med if pulse > X bpm.',
    metrics: ['pulse'],
    units: ['bpm'],
  },
  {
    code: 'TEMPERATURE',
    displayText: 'Temperature',
    exampleNote: 'Example: Do not administer med if temperature > X °F.',
    metrics: ['temperature'],
    units: ['°F', '°C'],
  },
  {
    code: 'WEIGHT',
    displayText: 'Weight',
    exampleNote: 'Example: Do not administer med if weight > X lb.',
    metrics: ['weight'],
    units: ['lb', 'kg'],
  },
]

export default conditionTypes
