import { useState, useEffect, useMemo } from 'react'
import {
  get, map, split, filter,
} from 'lodash'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { RRule } from 'rrule'
import { downloadFileFromBase64Response } from '@services/utils'
import { dateFromHoursMinutes } from '@services/utils/time'
import { errorMessageFromError, formErrorsFromError } from '../../services/errorHandler'

const formatSchedules = (scheduleData) => map(scheduleData, (schedule) => {
  const eventTime = get(schedule, 'eventTime')
  let rrule
  if (schedule.rrule) {
    rrule = new RRule(RRule.parseString(schedule.rrule))
  }
  return {
    ...schedule,
    time: dateFromHoursMinutes(schedule.timeHours, schedule.timeMinutes),
    text: rrule ? rrule.toText() : '',
    eventTime: eventTime ? {
      ...eventTime,
      startTime: dateFromHoursMinutes(
        eventTime.startTimeHours,
        eventTime.startTimeMinutes,
      ),
      endTime: dateFromHoursMinutes(
        eventTime.endTimeHours,
        eventTime.endTimeMinutes,
      ),
    } : null,
  }
})

export function useGenerateConsumptions(doseId, statusMessage) {
  return useMutation({
    mutationKey: ['generateConsumptions'],
    mutationFn: () => ApiClient.post(`/doses/${doseId}/generate_consumptions`),
    onSuccess: () => {
      statusMessage.current.show([
        { severity: 'success', summary: 'Consumptions Generated!' },
      ])
    },
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to generate consumptions at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })
}

export function useDestroyDoseSchedule(statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['destroySchedules'],
    mutationFn: ({ doseId, scheduleId }) => ApiClient.delete(`/doses/${doseId}/schedules/${scheduleId}`),
    onSuccess: () => {
      statusMessage.current.show([
        { severity: 'warn', summary: 'Schedule Deleted!' },
      ])
      queryClient.invalidateQueries('patientDose')
      queryClient.invalidateQueries('doseSchedule')
    },
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to delete schedule at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })
}

export function useDoseSchedules(doseId) {
  const query = useQuery({ queryKey: ['doseSchedule', doseId], queryFn: () => ApiClient.get(`doses/${doseId}/schedules`), enabled: !!doseId })

  return useMemo(() => {
    if (!query.data) return query
    const schedules = formatSchedules(query.data.schedules)
    return {
      ...query,
      data: schedules,
    }
  }, [query.data])
}

export function useCreateDoseSchedule(statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['createDoseSchedule'],
    mutationFn: ({ doseId, schedule }) => ApiClient.post(`/doses/${doseId}/schedules`, {
      schedule: {
        ...schedule,
        // The rrule.js library converts rrules to string
        //  in format "RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=WE,FR"

        // The below ling extracts the rule itself, omitting the "RRULE:"
        //  so that is is compatible with API validations"
        rrule: filter(split(schedule.rrule, ':'), (rule) => rule !== 'RRULE').join(';'),
      },
    }),
    onSuccess: () => {
      statusMessage.current.show([
        { severity: 'success', summary: 'Schedule Saved!' },
      ])
      queryClient.invalidateQueries('patientDose')
      queryClient.invalidateQueries('doseSchedule')
    },
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to save schedule at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })
}

export function useUpdateDoseSchedule(statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['updateDoseSchedule'],
    mutationFn: ({ doseId, schedule }) => ApiClient.put(`/doses/${doseId}/schedules/${schedule.id}`, {
      schedule: {
        ...schedule,
        // The rrule.js library converts rrules to string
        //  in format "RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=WE,FR"

        // The below ling extracts the rule itself, omitting the "RRULE:"
        //  so that is is compatiable with API validations
        rrule: filter(split(schedule.rrule, ':'), (rule) => rule !== 'RRULE').join(';'),
      },
    }),
    onSuccess: () => {
      statusMessage.current.show([
        { severity: 'success', summary: 'Schedule Saved!' },
      ])
      queryClient.invalidateQueries('patientDose')
      queryClient.invalidateQueries('doseSchedule')
    },
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to save schedule at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })
}

export function useUpsertDose({
  patientId, statusMessage, isUpdate = false, successMessage,
}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['dose', patientId],
    mutationFn: (dose) => {
      let doseParams = {}
      if (isUpdate) {
        doseParams = {
          id: dose.id,
          note: dose.notes,
          prn: dose.prn,
          startAt: dose.startAt,
          endAt: dose.endAt,
          impactCheckTime: dose.impactCheckTime,
          prnPermissionRequired: dose.prnPermissionRequired,
          prnReasonRequired: dose.prnReasonRequired,
          activatedAt: dose.activatedAt,
          expiration: dose.expiration,
          administrationAmount: dose.administrationAmount,
          administrationRoute: dose.administrationRoute,
          requireInjectionSite: dose.requireInjectionSite,
          doseFillsAttributes: dose.doseFills,
          purposeAttributes: dose.purpose,
          physicianName: dose.physicianName,
          physicianPhone: dose.physicianPhone,
          pharmacyName: dose.pharmacyName,
          pharmacyPhone: dose.pharmacyPhone,
          walkInRx: dose.walkInRx,
          hrstPurpose: dose.hrstPurpose,
          hrstFrequency: dose.hrstFrequency,
          hrstOtherPurpose: dose.hrstOtherPurpose,
          therapSettings: dose.therapSettings,
        }

        if (dose.medicine) {
          doseParams.medicineAttributes = {
            id: dose.medicine.id,
            localCsaSchedule: dose.medicine.csaSchedule,
          }
        }

        // It only makes sense to update injection fields if the medicine is Injectable
        if (get(dose, 'injectable')) {
          doseParams.requireInjectionSite = dose.requireInjectionSite
        }
      } else {
        doseParams = {
          id: dose.id,
          medicineId: dose.medicineId,
          administrationAmount: dose.doseAmount,
          administrationRoute: dose.administrationRoute,
          requireInjectionSite: dose.requireInjectionSite,
          packaging: dose.medicinePackaging,
          startAt: dose.startAtDate,
          endAt: dose.endAtDate,
          expiration: dose.expirationDate,
          prn: dose.prn,
          impactCheckTime: dose.impactCheckTime,
          prnPermissionRequired: dose.prnPermissionRequired,
          prnReasonRequired: dose.prnReasonRequired,
          note: dose.notes,
          activatedAt: new Date(),
          refillsRemaining: dose.refillsRemaining,
          walkInRx: dose.walkInRx,
        }

        if (dose?.purpose) {
          doseParams.purposeAttributes = { text: dose.purpose }
        }

        if (dose?.expirationDate && dose?.dosesInFill) {
          doseParams.doseFillsAttributes = [{
            expiresAt: dose.expirationDate,
            dosesInFill: dose.dosesInFill,
          }]
        }

        if (get(dose, 'pharmacyInfo')) {
          doseParams.pharmacyName = dose.pharmacyInfo.name
          doseParams.pharmacyPhone = dose.pharmacyInfo.phone
        }

        if (get(dose, 'physicianInfo')) {
          doseParams.physicianName = dose.physicianInfo.name
          doseParams.physicianPhone = dose.physicianInfo.phone
        }
      }

      if (dose.id) {
        return ApiClient.patch(`/patients/${patientId}/doses/${dose.id}`, doseParams)
      }
      return ApiClient.post(`/patients/${patientId}/doses`, doseParams)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries('patientDose')
      await queryClient.invalidateQueries('doseSchedule')
      statusMessage.current.clear()
      const message = successMessage || 'Dose Saved!'
      statusMessage.current.show([
        { severity: 'success', summary: message },
      ])
    },
  })
}

export function useCreateDoseSig({
  doseId, statusMessage,
}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['createDoseSig', doseId],
    mutationFn: (doseSig) => {
      const doseSigParams = {
        doseId: doseSig.doseId,
        administrationAmount: doseSig.administrationAmount,
        endAt: doseSig.endAt,
        endAtWall: doseSig.endAtWall,
        note: doseSig.note,
        prn: doseSig.prn,
        startAt: doseSig.startAt,
        startAtWall: doseSig.startAtWall,
        units: doseSig.units,
      }

      return ApiClient.post('/dose_sigs', doseSigParams)
    },
    onSuccess: () => {
      queryClient.invalidateQueries('patientDose')
      statusMessage.current.clear()
      statusMessage.current.show([
        { severity: 'success', summary: 'Dose Sig Created!' },
      ])
    },
  })
}

export function useUpdateDoseSig({
  doseSigId, statusMessage,
}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['updateDoseSig', doseSigId],
    mutationFn: (doseSig) => {
      const doseSigParams = {
        administrationAmount: doseSig.administrationAmount,
        endAt: doseSig.endAt,
        endAtWall: doseSig.endAtWall,
        note: doseSig.note,
        prn: doseSig.prn,
        startAt: doseSig.startAt,
        startAtWall: doseSig.startAtWall,
        units: doseSig.units,
      }

      return ApiClient.patch(`/dose_sigs/${doseSig.id}`, doseSigParams)
    },
    onSuccess: () => {
      queryClient.invalidateQueries('patientDose')
      queryClient.invalidateQueries('doseSchedule')
      statusMessage.current.clear()
      statusMessage.current.show([
        { severity: 'success', summary: 'Dose Sig Saved!' },
      ])
    },
  })
}

export function useSoftDeleteDoseSig({
  doseSigId, statusMessage,
}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['softDeleteDoseSig', doseSigId],
    mutationFn: () => ApiClient.delete(`/dose_sigs/${doseSigId}`),
    onSuccess: () => {
      queryClient.invalidateQueries('patientDose')
      queryClient.invalidateQueries('doseSchedule')
      statusMessage.current.clear()
      statusMessage.current.show([
        { severity: 'warn', summary: 'Dose Sig Deleted!' },
      ])
    },
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to delete the dose sig at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })
}

export function useEndDoseMutation(clientId, statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['endDose', clientId],
    mutationFn: ({ id }) => ApiClient.post(`/patients/${clientId}/doses/${id}/end`),
    onSuccess: () => {
      queryClient.invalidateQueries('patientDose')
    },
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to end dose at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })
}

export function useRestartDoseMutation(clientId, statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['restartDose', clientId],
    mutationFn: ({ id, endAt }) => ApiClient.post(`/patients/${clientId}/doses/${id}/restart`, {
      endAt,
    }),
    onSuccess: () => {
      queryClient.invalidateQueries('patientDose')
    },
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to restart dose at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })
}

export function usePauseDoseMutation(clientId, statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['pauseDose', clientId],
    mutationFn: ({ id, note }) => ApiClient.post(`/patients/${clientId}/doses/${id}/pause`, {
      note,
    }),
    onSuccess: () => {
      queryClient.invalidateQueries('patientDose')
    },
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to pause dose at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })
}

export function useUnpauseDoseMutation(clientId, statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['unpauseDose', clientId],
    mutationFn: (id) => ApiClient.post(`/patients/${clientId}/doses/${id}/unpause`),
    onSuccess: () => {
      queryClient.invalidateQueries('patientDose')
    },
    onError: (error) => {
      statusMessage.current.show([
        errorMessageFromError(error, 'Unable to unpause dose at this time.'),
        ...formErrorsFromError(error),
      ])
    },
  })
}

export function useCreateMedicine(statusMessage) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['medicine'],
    mutationFn: async (medicine) => ApiClient.post('/medicines', { medicine }),
    onSuccess: () => {
      statusMessage.current.show([
        { severity: 'success', summary: 'Medicine Saved!' },
      ])
      queryClient.invalidateQueries('medicine')
      queryClient.invalidateQueries('patientDose')
    },
    onError: () => {
      statusMessage.current.show([
        { severity: 'danger', summary: 'Cannot save medicine at this time.' },
      ])
    },
  })
}

export function usePatientDose({ clientId, doseId }) {
  const query = useQuery({ queryKey: ['patientDose', clientId, doseId], queryFn: () => ApiClient.get(`/patients/${clientId}/doses/${doseId}`), enabled: !!doseId })

  return useMemo(() => {
    if (query.data) {
      const schedules = map(query.data.schedules, (schedule) => {
        const eventTime = get(schedule, 'eventTime')
        return {
          ...schedule,
          time: dateFromHoursMinutes(schedule.timeHours, schedule.timeMinutes),
          eventTime: eventTime ? {
            ...eventTime,
            startTime: dateFromHoursMinutes(
              eventTime.startTimeHours,
              eventTime.startTimeMinutes,
            ),
            endTime: dateFromHoursMinutes(
              eventTime.endTimeHours,
              eventTime.endTimeMinutes,
            ),
          } : null,
        }
      })

      return {
        ...query,
        data: {
          ...query.data,
          schedules,
        },
      }
    }
    return query
  }, [query.data])
}

export function useRxTermSearch(term) {
  const [results, setResults] = useState([])
  const query = useQuery({ queryKey: ['rxTermSearch', term], queryFn: () => ApiClient.get(`/rx_terms?term=${term}`), enabled: !!term })

  useEffect(() => {
    if (query.data) {
      setResults(
        query.data.rxTerms.map((rxTerm) => rxTerm),
      )
    }
  }, [query.data])

  return [results, query]
}

export function useClientDoses(clientId, { includeAll } = { includeAll: true }) {
  const query = useQuery({
    queryKey: ['patientDoses', clientId, includeAll],
    queryFn: () => {
      const params = {}
      if (includeAll) {
        params.includes = 'all'
      }
      return ApiClient.get(`/patients/${clientId}/doses`, { params })
    },
  })

  return useMemo(() => (
    {
      ...query,
      data: map(query.data, (data) => ({
        ...data,
        schedules: formatSchedules(data.schedules),
      })),
    }
  ), [query.data])
}

export function usePatientTherapDoses(patientId) {
  const query = useQuery(
    {
      queryKey: ['patientTherapDoses', patientId],
      queryFn: () => ApiClient.get(`/patients/${patientId}/doses/therap`),
      enabled: !!patientId,
    },
  )

  return useMemo(() => (
    {
      ...query,
      data: map(query.data, (data) => ({
        ...data,
        schedules: formatSchedules(data.schedules),
      })),
    }
  ), [query.data])
}

export function usePatientPrnDoses(clientId, dateRange) {
  const query = useQuery({
    queryKey: ['patientDoses', clientId, dateRange],
    queryFn: () => {
      const params = {
        startDate: dateRange.startDate.toISOString(),
        endDate: dateRange.endDate.toISOString(),
      }
      return ApiClient.get(`/patients/${clientId}/doses/patient_prn_history`, { params })
    },
  })

  return useMemo(() => (
    {
      ...query,
      data: map(get(query, 'data'), (consumption) => (consumption)),
    }
  ), [query, dateRange])
}

export function useExportMAR(statusMessage, doseId) {
  return useMutation({
    mutationKey: ['pdfExportMAR'],
    mutationFn: async ({ year, month }) => {
      const response = await ApiClient.get(`/doses/${doseId}/export_mar.pdf?month=${month}&year=${year}`)
      downloadFileFromBase64Response(response)
    },
    onError: (error) => {
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })
}
