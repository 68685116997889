import { useMemo } from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import {
  get, map, startCase, camelCase,
} from 'lodash'
import {
  errorMessageFromError,
} from '@services/errorHandler'
import ApiClient from '@services/ApiClient'
import { dateFromHoursMinutes } from '@services/utils/time'

const titleCase = (name) => startCase(camelCase(name))

export function useOnboardPendingUser(statusMessage) {
  return useMutation({
    mutationKey: ['useOnboardPendingUser'],
    mutationFn: (pendingUser) => ApiClient.post(`/pending_users/${pendingUser.id}/onboard`, { pendingUser }),
    onError: (error) => {
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })
}

export function usePendingUsers({ organizationId, searchTerm }) {
  const query = useQuery({
    queryKey: ['pendingUsers', organizationId, searchTerm],
    queryFn: () => {
      if (searchTerm) {
        return ApiClient.get(`/organizations/${organizationId}/pending_users?search_term=${searchTerm}`)
      }
      return ApiClient.get(`/organizations/${organizationId}/pending_users`)
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query.data, 'pendingUsers'), (user) => ({
      ...user,
      firstName: titleCase(user.firstName),
      lastName: titleCase(user.lastName),
    })),
  }), [query.data])
}

export function usePendingUser({ pendingUserId }) {
  const query = useQuery({ queryKey: ['pendingUser', pendingUserId], queryFn: () => ApiClient.get(`/pending_users/${pendingUserId}`) })

  return useMemo(() => {
    if (query.data) {
      const calculateSchedules = (schedules) => map(schedules, (schedule) => {
        const eventTime = get(schedule, 'eventTime')
        return {
          ...schedule,
          time: dateFromHoursMinutes(schedule.timeHours, schedule.timeMinutes),
          eventTime: eventTime ? {
            ...eventTime,
            startTime: dateFromHoursMinutes(
              eventTime.startTimeHours,
              eventTime.startTimeMinutes,
            ),
            endTime: dateFromHoursMinutes(
              eventTime.endTimeHours,
              eventTime.endTimeMinutes,
            ),
          } : null,
        }
      })

      const { firstName, lastName, doses } = query.data

      return {
        ...query,
        data: {
          ...query.data,
          firstName: titleCase(firstName),
          lastName: titleCase(lastName),
          doses: map(doses, (dose) => (
            {
              ...dose,
              schedules: calculateSchedules(dose.schedules),
            }
          )),
        },
      }
    }
    return query
  }, [query.data])
}

export function usePatientsAvailableToOnboard({ organizationId, pendingUserId, statusMessage }) {
  const query = useQuery({
    queryKey: ['patients', organizationId, pendingUserId],
    queryFn: () => {
      let params = {
        pending_user_id: pendingUserId,
      }

      if (organizationId) {
        params = {
          ...params,
          organization_id: organizationId,
        }
      }

      return ApiClient.get('/patients/available_to_onboard', { params })
    },
    onError: (error) => {
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.patients'), (patient) => (patient)),
  }), [query])
}
