import React from 'react'
import { map, isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import { Divider } from 'primereact/divider'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { calculateServicesTimeSpent } from '../../features/CCM/billableSessionUtils'
import { dateTemplate } from '../../services/utils'

const timeTemplate = (datestring) => {
  const datetime = new Date(datestring)
  return datetime.toLocaleTimeString('en-us', { hour: 'numeric', minute: 'numeric', hour12: true })
}

function SessionCard({ patientId, session, currentUserCCMEligible }) {
  const { medicalProvider, medicalService, billableServiceNotes } = session
  const startAt = session.startAt && new Date(session.startAt)
  const endAt = session.endAt && new Date(session.endAt)

  const totals = calculateServicesTimeSpent([session])
  const sessionHours = Math.floor(totals.minutes / 60)
  const sessionMinutes = totals.minutes - (Math.floor(totals.minutes / 60) * 60)
  const sessionSeconds = totals.seconds

  const sessionTime = () => {
    let display = ''
    if (sessionHours) {
      display += `${sessionHours} hours `
    }

    if (sessionMinutes) {
      display += `${sessionMinutes} minutes `
    }

    if (sessionSeconds) {
      display += `${sessionSeconds} seconds `
    }

    return display
  }

  const updateButton = () => {
    const button = (
      <Link to={`/admin/patients/${patientId}/ccm_sessions/${session.id}/update`}>
        <Button
          className="p-button-sm p-button-text p-button-warning px-3 py-1 session-update-button"
          label="Update"
        />
      </Link>
    )

    if (!medicalProvider) {
      return button
    }

    if (!medicalService) {
      return button
    }

    return (
      <Link to={`/admin/patients/${patientId}/ccm_sessions/${session.id}/update`}>
        <Button
          className="p-button-sm p-button-text p-button-success px-3 py-1 session-update-button"
          label="Add Note"
        />
      </Link>
    )
  }

  return (
    <div className="col-12 md:col-4 fadein">
      <Card className="h-full">
        <div className="flex flex-row justify-space-between">
          <div className="flex flex-column gap-3 w-full">
            <div className="flex flex-column gap-2 text-sm text-900">
              <span className="font-bold flex flex-row justify-content-between align-items-center">
                {dateTemplate(startAt || session.date)}
                {currentUserCCMEligible && updateButton()}
              </span>
              <span className="text-xs">
                {startAt && `${timeTemplate(startAt)} to ${timeTemplate(endAt)}`}
                <span className={`text-600 ${startAt ? 'ml-1' : ''}`}>{sessionTime()}</span>
              </span>
            </div>
            <Divider className="my-0" />
            {medicalProvider
              && (
              <div className="flex flex-column gap-2 text-sm text-900">
                <span className="font-semibold">Provider</span>
                <div className="text-xs">
                  <span className="text-900">Name: </span>
                  <span className="text-700">{`${!isEmpty(medicalProvider.title) ? medicalProvider.title : ''} ${medicalProvider.fullName}`}</span>
                </div>
                <div className="text-xs">
                  <span className="text-900">Phone: </span>
                  <span className="text-700">{medicalProvider.phone}</span>
                </div>
                <div className="text-xs">
                  <span className="text-900">Email: </span>
                  <span className="text-700">{medicalProvider.email}</span>
                </div>
                <div className="text-xs">
                  <span className="text-900">NPI: </span>
                  <span className="text-700">{medicalProvider.npi}</span>
                </div>
              </div>
              )}
            {medicalService
              && (
              <div className="flex flex-column gap-2 text-sm text-900">
                <span className="font-semibold">Service</span>
                <div className="text-xs">
                  <span className="text-900">ICD Code: </span>
                  <span className="text-700">{medicalService.icdCode}</span>
                </div>
                <div className="text-xs">
                  <span className="text-900">Description: </span>
                  <span className="text-700">{medicalService.description}</span>
                </div>
              </div>
              )}
            {!isEmpty(billableServiceNotes)
              && (
              <div className="flex flex-column gap-2 text-sm text-900">
                <span className="font-semibold">Notes</span>
                {
                  map(billableServiceNotes, ({ id, text }) => (
                    <div className="text-xs" key={id}>
                      <span className="text-700">{text}</span>
                    </div>
                  ))
                }
              </div>
              )}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default SessionCard
