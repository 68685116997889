import React from 'react'
import { Divider } from 'primereact/divider'
import StatusOverlayContent from '../../StatusOverlayContent'

function PRNStatusOverlay({ timezone, data = {}, handleEdit }) {
  const combinedData = Object.values(data).flat()
  return (
    <div className="w-22rem p-1 pb-0">
      {combinedData.map((item, index) => (
        <React.Fragment key={`${item.status}-${item.confirmedAt}-${index}`}>
          <StatusOverlayContent timezone={timezone} item={item} handleEdit={handleEdit} />
          {index < combinedData.length - 1 && <Divider className="my-4" />}
        </React.Fragment>
      ))}
    </div>
  )
}

export default React.memo(PRNStatusOverlay)
