import React, { useEffect, useState } from 'react'
import { map, isEmpty } from 'lodash'
import { Card } from 'primereact/card'
import { classNames } from 'primereact/utils'
import { fuzzySearch } from '@services/utils/search'
import { useMedicalProviders } from '../../features/CCM/ccmHooks'

function SkipCard({ setProvider }) {
  const [highlight, setHighlight] = useState(false)

  const handleSelection = () => {
    setHighlight(true)
    setTimeout(() => {
      setProvider(null)
      setHighlight(false)
    }, 300)
  }

  return (
    <div className="col-12 md:col-4 xl:col-3">
      <Card
        className={classNames('w-full h-full provider', { highlight })}
        onClick={handleSelection}
      >
        <div className="flex flex-row justify-space-between">
          <div className="flex flex-column gap-3">
            <div className="text-sm text-900">Skip</div>
            <div className="text-xs">
              <span className="text-700">*Provider can be added later</span>
            </div>
          </div>
          <i className="pi pi-forward" />
        </div>
      </Card>
    </div>
  )
}

function Provider({
  selected, name, npi, phone, id, setProvider,
}) {
  const [highlight, setHighlight] = useState(selected)

  const handleSelection = () => {
    setHighlight(true)
    setTimeout(() => {
      setProvider({
        name,
        npi,
        phone,
        id,
      })
      setHighlight(false)
    }, 300)
  }

  return (
    <div className="col-12 md:col-4 xl:col-3">
      <Card
        className={classNames('w-full h-full provider', { highlight })}
        onClick={handleSelection}
      >
        <div className="flex flex-row justify-space-between">
          <div className="flex flex-column gap-3">
            <div className="text-sm text-900">{name}</div>
            <div className="text-xs">
              <span className="text-900">Phone: </span>
              <span className="text-700">{phone}</span>
            </div>
            <div className="text-xs">
              <span className="text-900">NPI: </span>
              <span className="text-700">{npi}</span>
            </div>
          </div>
          <i className="pi pi-check-square" />
        </div>
      </Card>
    </div>
  )
}

function Providers({
  selectedProvider,
  setProvider,
  searchTerm,
  setPlaceholder,
}) {
  const [filteredProviders, setFilteredProviders] = useState([])
  const { data: providers, isLoading } = useMedicalProviders()

  useEffect(() => {
    setPlaceholder('Search by name or NPI')
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (searchTerm) {
      setFilteredProviders(fuzzySearch(providers, ['fullName', 'npi'], searchTerm), 0.4)
    } else {
      setFilteredProviders(providers)
    }
  }, [providers, searchTerm])

  if (isLoading) {
    return 'Loading...'
  }

  return (
    <div className="providers flex flex-row flex-wrap">
      {map(filteredProviders, ({
        fullName, title, id, npi, phone,
      }) => (
        <Provider
          key={id}
          selected={id === selectedProvider?.id}
          id={id}
          name={`${!isEmpty(title) ? title : ''} ${fullName}`}
          phone={phone}
          npi={npi}
          setProvider={setProvider}
        />
      ))}
      <SkipCard setProvider={setProvider} />
    </div>
  )
}

export default Providers
