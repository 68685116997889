import moment from 'moment'
import React, { useState } from 'react'

function EmptyStateBody({ rowData, currentCellDate, onClick }) {
  const [mouseEnter, setMouseEnter] = useState(false)
  if (currentCellDate > moment()) return null
  return (
    <span
      className="flex justify-content-center align-content-center cursor-pointer text-400"
      onMouseEnter={() => setMouseEnter(true)}
      onMouseLeave={() => setMouseEnter(false)}
      onClick={() => onClick(rowData, currentCellDate)}
    >
      {mouseEnter ? <i className="pi pi-plus-circle" /> : <i className="pi pi-minus" />}
    </span>
  )
}

export default EmptyStateBody
