import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import Box from '@components/display/Box'
import Block from '@components/display/Block'
import { useCurrentOrganization } from '@components/App'
import BillableSessionsOverview from './BillableSessionsOverview'
import { usePatientsBillableSessions } from '../../features/CCM/ccmHooks'
import Exports from './Exports'
import NotificationTestButton from '../display/UserDevices/NotificationTestButton'
import AdherenceDashboard from './AdherenceDashboard/AdherenceDashboard'
import './AdherenceDashboard/AdherenceDashboard.scss'
import MedicationsDueNotification from './MedicationsDueNotification'
import MatchNotification from './MatchNotification'

function PatientDashboard({
  patientId,
  patient,
  handleSetHeader,
  isCurrentUserAdmin,
  showCCMResources,
  maxDataViewHeight,
  rootPath,
}) {
  const organization = useCurrentOrganization()
  const [primaryAction, setPrimaryAction] = useState(null)

  const {
    data: sessionsByPatient,
    isLoading: sessionsLoading,
  } = usePatientsBillableSessions({ patientIds: [patientId], ccmEnabled: showCCMResources })

  const matchNotificationTemplate = () => (
    <MatchNotification
      rootPath={rootPath}
      patientId={patientId}
      organization={organization}
    />
  )

  const medicationsDueTemplate = () => (
    <MedicationsDueNotification patient={patient} />
  )

  useEffect(() => {
    if (patient && primaryAction) {
      const notifications = []

      if (patient.matchingUsers.length > 0) {
        notifications.push(matchNotificationTemplate)
      }

      if (patient.allowedConsumptionsCount > 0) {
        notifications.push(medicationsDueTemplate)
      }
      handleSetHeader({
        primaryAction,
        notifications,
      })
    }
  }, [primaryAction, patient])

  const setupPrimaryAction = (exportAction) => {
    const actionPrimary = (
      <div className="flex flex-row gap-2">
        <NotificationTestButton userId={patientId} />
        {exportAction}
      </div>
    )

    setPrimaryAction(actionPrimary)
  }

  return (
    <>
      <Exports
        patientId={patientId}
        isCurrentUserAdmin={isCurrentUserAdmin}
        exportsMenuSetupCallBack={setupPrimaryAction}
      />
      <Box>
        {showCCMResources
          && (
            <Block col={6} xl={4}>
              <BillableSessionsOverview
                isLoading={sessionsLoading}
                standardCharges={get(sessionsByPatient, `${patientId}.standardCharges`, {})}
                complexCharges={get(sessionsByPatient, `${patientId}.complexCharges`, {})}
              />
            </Block>
          )}
      </Box>
      <Block col={12}>
        {!organization.ccmEnabled
          && (
          <div className="flex flex-column gap-4">
            <AdherenceDashboard patientId={patientId} title="Adherence" maxDataViewHeight={maxDataViewHeight} />
          </div>
          )}
      </Block>
    </>
  )
}

export default PatientDashboard
