import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import PharmacyOrdersTable from '../Table/PharmacyOrdersTable'
import { Section } from './SharedComponents'

function SourceOrdersSection({ reviewableOrder }) {
  const [isDetailsDialogVisible, setIsDetailsDialogVisible] = useState(false)

  const compositeOrderDetailsButton = () => (
    <Button
      onClick={() => setIsDetailsDialogVisible(true)}
      label="Details"
      className="p-button-primary"
    />
  )

  const helpText = 'The actual pharmacy orders that were combined to create the reviewable order.'

  return (
    <Section header="Source Orders" helpText={helpText}>
      <Dialog
        header="Order"
        visible={isDetailsDialogVisible}
        onHide={() => setIsDetailsDialogVisible(false)}
        style={{ width: '50vw' }}
        dismissableMask
      >
        <div className="flex flex-column gap-1">
          <pre>{JSON.stringify(reviewableOrder, null, 2)}</pre>
        </div>
      </Dialog>
      <PharmacyOrdersTable
        pharmacyOrders={reviewableOrder?.sourceOrders}
        isLoading={false}
        showAcknowledge={false}
        actions={[compositeOrderDetailsButton()]}
      />
    </Section>
  )
}

export default SourceOrdersSection
