import React, { useRef, useState } from 'react'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast'
import { useCurrentOrganization } from '@components/App'
import { usePrescriptions } from '@hooks/prescriptions'
import { useReportSentryError } from '@hooks/useReportSentryError'
import { momentFormats } from '@services/utils/moment'
import UnlinkPrescriptionDialog from './UnlinkPrescriptionDialog'

function timeTemplate(field, timezone = 'America/New_York', placeholder = '') {
  return function tableCell(rowData) {
    const value = rowData[field]
    return (
      <div>
        { value ? moment(value).tz(timezone).format(momentFormats.datetime_12) : placeholder }
      </div>
    )
  }
}

function PrescriptionsTable({ model, id, allowUnlink = false }) {
  const { timezone } = useCurrentOrganization()
  const messagesRef = useRef()
  const [selectedPrescription, setSelectedPrescription] = useState(null)
  const [showDialog, setShowDialog] = useState(false)
  const { data: prescriptions, error, isLoading } = usePrescriptions(model, id)
  const reportSentryError = useReportSentryError([model, id])

  if (error) {
    reportSentryError(`Error getting prescriptions for ${model} ${id}: ${JSON.stringify(error)}`)
    return (<div>Error getting prescriptions.</div>)
  }

  const actionsTemplate = (rowData) => {
    if (!allowUnlink && !rowData.doseId) {
      return null
    }

    return (
      <Button
        icon="fa-solid fa-unlink"
        className="p-button-text p-button-sm"
        onClick={() => {
          setSelectedPrescription(rowData)
          setShowDialog(true)
        }}
      />
    )
  }

  return (
    <>
      <Toast ref={messagesRef} />
      <DataTable
        dataKey="id"
        emptyMessage="No prescriptions found"
        loading={isLoading}
        removableSort
        value={prescriptions}
      >
        <Column
          field="id"
          header="ID"
        />
        <Column
          field="prescriptionNumber"
          header="Prescription Number"
        />
        <Column
          field="refillsRemaining"
          header="Refills Remaining"
        />
        <Column
          field="active"
          header="Active"
        />
        <Column
          body={timeTemplate('createdAt', timezone)}
          field="createdAt"
          header="Created"
          sortable
        />
        <Column
          body={timeTemplate('updatedAt', timezone)}
          field="updatedAt"
          header="Last Updated"
          sortable
        />
        <Column
          body={timeTemplate('discontinuedAt', timezone)}
          field="discontinuedAt"
          header="Discontinued"
          sortable
        />
        <Column
          body={timeTemplate('deletedAt', timezone)}
          field="deletedAt"
          header="Deleted"
          sortable
        />
        <Column
          body={actionsTemplate}
          field="actions"
        />
      </DataTable>
      <UnlinkPrescriptionDialog
        isVisible={showDialog}
        prescriptionId={selectedPrescription?.id}
        messagesRef={messagesRef}
        onHide={() => setShowDialog(false)}
      />
    </>
  )
}

export default PrescriptionsTable
