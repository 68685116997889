import React from 'react'
import { Card } from 'primereact/card'
import DoseCalendar from '@components/clientDoses/Schedule/DoseCalendar'
import {
  useUpsertDose,
  useCreateDoseSchedule,
  useUpdateDoseSchedule,
  useDestroyDoseSchedule,
} from '../clientDosesHooks'

function ClientDoseSchedule({
  clientId, scheduleStatusMessage, dose,
}) {
  const {
    mutateAsync: destroySchedule,
  } = useDestroyDoseSchedule(scheduleStatusMessage)

  const {
    mutateAsync: createSchedule,
  } = useCreateDoseSchedule(scheduleStatusMessage)

  const {
    mutateAsync: updateSchedule,
  } = useUpdateDoseSchedule(scheduleStatusMessage)

  const {
    mutateAsync: upsertDose,
    isLoading: upsertDoseLoading,
  } = useUpsertDose({
    patientId: clientId,
    statusMessage: scheduleStatusMessage,
    isUpdate: true,
  })

  const handleSetPrn = async (prn) => upsertDose({ id: dose.id, prn, schedules: [] })

  return (
    <div className="col-12">
      <Card className="h-full">
        <DoseCalendar
          clientId={clientId}
          schedules={dose.schedules}
          dose={dose}
          handleSetPrn={handleSetPrn}
          handleSetPrnLoading={upsertDoseLoading}
          handleCreateSchedule={createSchedule}
          handleUpdateSchedule={updateSchedule}
          handleDestroySchedule={destroySchedule}
          showAddTimeControls={false}
        />
      </Card>
    </div>
  )
}

export default ClientDoseSchedule
