import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton'
import { Toast } from 'primereact/toast'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { Divider } from 'primereact/divider'
import AttributeCard, { Attribute } from '@components/display/AttributeCard'
import UserProfileCardTitle from '@components/user/UserProfileCardTitle'
import { dateTemplate } from '@services/utils'
import { useCurrentOrganization } from '@components/App'
import ConsumptionsChart from '../display/Consumptions/ConsumptionsChart'
import ConsumptionsTable from '../display/Consumptions/ConsumptionsTable'
import {
  useCaregiverConsumptionHistory,
  useSetTemporaryPassword,
  useClearPassword,
  useSetUsername,
} from '../../features/Caregivers/caregiversHooks'
import './caregivers.scss'
import UsernameInput from './UsernameInput'
import PasswordInput from './PasswordInput'
import NotificationTestButton from '../display/UserDevices/NotificationTestButton'

function Profile({
  caregiver, isLoading, shouldShowCredentials,
}) {
  const title = { body: (<UserProfileCardTitle user={caregiver} />) }
  const navigate = useNavigate()
  const statusMessage = useRef(null)
  const {
    email, initials, upn, phone, createdAt, timezone, customerId,
  } = caregiver || {}

  const editClick = () => {
    navigate(`/admin/caregivers/${caregiver.id}/update`)
  }

  const action = { label: 'Edit', command: editClick }

  const valueTemplate = (value) => (
    isLoading ? <Skeleton className="inline-flex" width="5rem" height="1rem" /> : value
  )

  const {
    mutateAsync: setUsername,
  } = useSetUsername(statusMessage)

  const {
    mutateAsync: saveTemporaryPassword,
  } = useSetTemporaryPassword(statusMessage)

  const {
    mutateAsync: clearPassword,
  } = useClearPassword(statusMessage)

  const handleClearPassword = (caregiverId) => {
    clearPassword({ caregiverId })
  }

  const handleEditUsername = (caregiverId, username) => {
    setUsername({ caregiverId, username })
  }
  const handleSetTemporaryPassword = (caregiverId, newPassword) => {
    saveTemporaryPassword({ caregiverId, temporaryPassword: newPassword })
  }

  const usernameTemplate = () => (
    <UsernameInput
      caregiver={caregiver}
      onSubmit={(newUsername) => {
        if (caregiver.username !== newUsername) {
          handleEditUsername(caregiver.id, newUsername)
        }
      }}
    />
  )

  const passwordTemplate = () => (
    <PasswordInput
      caregiver={caregiver}
      onSubmit={(newPassword) => {
        if (caregiver.temporaryPassword === newPassword) return
        handleSetTemporaryPassword(caregiver.id, newPassword)
      }}
      onClear={() => handleClearPassword(caregiver.id)}
    />
  )

  return (
    <div id="caregiver-dashboard-profile">
      <ConfirmDialog />
      <Toast ref={statusMessage} />
      <AttributeCard title={title} className="h-full" action={action}>
        {initials
          && (
            <Attribute
              label="Initials:"
              value={valueTemplate(initials || 'Empty')}
            />
          )}
        {email
          && (
            <Attribute
              label="Email:"
              value={valueTemplate(email || 'Empty')}
            />
          )}
        {upn
          && (
            <Attribute
              label="UPN:"
              value={valueTemplate(upn || 'Empty')}
            />
          )}
        {phone
          && (
            <Attribute
              label="Phone:"
              value={valueTemplate(phone || 'Empty')}
            />
          )}
        {customerId
          && (
            <Attribute
              label="Custom ID:"
              value={valueTemplate(customerId || 'Empty')}
            />
          )}
        <Attribute
          label="Timezone:"
          value={valueTemplate(timezone || 'Empty')}
        />
        <Attribute
          label="Created:"
          value={valueTemplate(dateTemplate(createdAt))}
        />
        {shouldShowCredentials
          && (
            <>
              <div className="flex gap-2 align-items-center">
                <span className="text-lg mt-3">
                  <i className="pi pi-lock mr-2" />
                  Credentials
                </span>
              </div>
              <Divider className="p-0 my-0" />
              <Attribute
                label="Username:"
                value={valueTemplate(usernameTemplate())}
              />
              <Attribute
                label="Password:"
                value={valueTemplate(passwordTemplate())}
              />
            </>
          )}
      </AttributeCard>
    </div>
  )
}

function CaregiverDashboard({
  caregiverId, caregiver, caregiverLoading, handleSetHeader, maxDataViewHeight,
}) {
  const organization = useCurrentOrganization()
  const {
    data: consumptions,
    isLoading: consumptionsLoading,
  } = useCaregiverConsumptionHistory(caregiverId)

  const [chartHeight, setChartHeight] = useState(0)

  const primaryAction = (
    <NotificationTestButton userId={caregiverId} />
  )

  useEffect(() => {
    if (caregiver && !chartHeight) {
      const profileCard = document.getElementById('caregiver-dashboard-profile')
      const profileCardHeight = profileCard.offsetHeight
      setChartHeight(profileCardHeight - 94)
    }

    handleSetHeader({ primaryAction })
  }, [caregiver])

  return (
    <div className="flex flex-row flex-wrap">
      <div className={!organization.ccmEnabled ? 'md:col-12 lg:col-5' : 'col-12'}>
        <Profile
          caregiver={caregiver}
          isLoading={caregiverLoading}
          shouldShowCredentials={organization.usernamePasswordAuthEnabled}
        />
      </div>
      {
        !organization.ccmEnabled && (
          <>
            <div className="md:col-12 lg:col-7">
              <ConsumptionsChart
                title={`${organization.patientLabelPlural}' Medication Adherence`}
                consumptions={consumptions}
                isLoading={consumptionsLoading}
                height={chartHeight}
              />
            </div>
            <div className="col-12">
              <ConsumptionsTable
                title={`${organization.patientLabelPlural}' Consumptions`}
                consumptions={consumptions}
                isLoading={consumptionsLoading}
                maxDataViewHeight={maxDataViewHeight}
                userId={caregiverId}
                userRole="caregiver"
                patientLabel={organization.patientLabelSingular}
                showPatientName
              />
            </div>
          </>
        )
      }
    </div>
  )
}

export default CaregiverDashboard
