import { useMemo } from 'react'
import {
  useQuery, useQueries, useMutation, useQueryClient,
} from '@tanstack/react-query'
import {
  map, every, flatMap, get, reduce,
} from 'lodash'
import { downloadFileFromResponse, downloadFileFromBase64Response } from '@services/utils'

import ApiClient from '@services/ApiClient'

export function useExportBillingReport() {
  const defaultStartDate = useMemo(() => {
    const date = new Date()
    date.setDate(1)
    date.setHours(0, 0, 0)
    return date
  }, [])

  const mutation = useMutation({
    mutationKey: ['exportUserConsumptions'],
    mutationFn: async ({ selectedMonth }) => {
      const startAt = selectedMonth ? new Date(selectedMonth) : defaultStartDate
      const response = await ApiClient.get(`/billable_service_sessions/export?start_at=${startAt}`)
      downloadFileFromResponse(response)
    },
    onError: () => {},
  })
  return mutation
}

export function useExportClinicalReport() {
  const defaultStartDate = useMemo(() => {
    const date = new Date()
    date.setDate(1)
    date.setHours(0, 0, 0)
    return date
  }, [])

  const mutation = useMutation({
    mutationKey: ['pdfExportCCMReport'],
    mutationFn: async ({ selectedMonth, patientId }) => {
      const startAt = selectedMonth ? new Date(selectedMonth) : defaultStartDate
      let url = `/billable_service_sessions/export_pdf?start_at=${startAt}`
      if (patientId) {
        url = `${url}&patient_id=${patientId}`
      }
      const response = await ApiClient.get(url)
      downloadFileFromBase64Response(response)
    },
  })
  return mutation
}

export function usePatients(organizationId) {
  const query = useQuery({ queryKey: ['ccmPatients', organizationId], queryFn: () => ApiClient.get('/patients/ccm_eligible') })

  return {
    ...query,
    data: get(query.data, 'patients'),
  }
}

export function useMedicalServices() {
  const query = useQuery({ queryKey: ['medicalServices'], queryFn: () => ApiClient.get('/medical_services ') })

  return useMemo(() => ({
    ...query,
    data: map(query.data, (service) => service),
  }), [query.data])
}

export function useMedicalProviders() {
  const query = useQuery({ queryKey: ['medicalProviders'], queryFn: () => ApiClient.get('/medical_providers') })

  return useMemo(() => ({
    ...query,
    data: map(query.data, (provider) => provider),
  }), [query.data])
}

export function usePatientsBillableSessions({ patientIds = [], ccmEnabled }) {
  const queries = useQueries({
    queries: patientIds.map((patientId) => ({
      queryKey: ['patientBillableSession', patientId],
      queryFn: () => ApiClient.get(`/patients/${patientId}/billable_service_sessions`),
      enabled: !!patientId && ccmEnabled,
    })),
  })

  return useMemo(() => {
    let result = { isLoading: true, data: {} }
    if (every(queries, ({ status }) => status === 'success')) {
      const data = reduce(flatMap(queries, 'data'), (sessions, collection) => {
        const newSessions = sessions
        newSessions[get(collection, 'patient.id')] = collection
        return newSessions
      }, {})

      result = { isLoading: false, data }
    }
    return result
  }, [queries])
}

export function usePatientBillableSessions(patientId, selectedMonth) {
  const defaultStartDate = useMemo(() => {
    const date = new Date()
    date.setDate(1)
    date.setHours(0, 0, 0)
    return date
  }, [])

  const startAt = selectedMonth ? new Date(selectedMonth) : defaultStartDate
  const query = useQuery({ queryKey: ['patientBillableSession', patientId, startAt], queryFn: () => ApiClient.get(`/patients/${patientId}/billable_service_sessions?start_at=${startAt}`), enabled: !!patientId })

  return useMemo(() => ({
    ...query,
    data: get(query, 'data.billableServiceSessions'),
  }), [query])
}

export function useCreateBillableSession() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['createBillableSession'],
    mutationFn: (session) => ApiClient.post('/billable_service_sessions', session),
    onSuccess: () => {
      queryClient.invalidateQueries('ccmPatients', 'ccmBillableSessions')
    },
    onError: () => {

    },
  })
}

export function useUpdateBillableSession() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['updateBillableSession'],
    mutationFn: (session) => ApiClient.patch(`/billable_service_sessions/${session.id}`, session),
    onSuccess: () => {
      queryClient.invalidateQueries('ccmPatients', 'ccmBillableSessions')
    },
    onError: () => {

    },
  })
}

export function useCreateSessionNote() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['createBillableSessionNote'],
    mutationFn: (session) => ApiClient.post(`/billable_service_sessions/${session.id}/add_note`, session),
    onSuccess: () => {
      queryClient.invalidateQueries('ccmPatients', 'ccmBillableSessions')
    },
    onError: () => {

    },
  })
}
