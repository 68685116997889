import { useMemo } from 'react'
import { get, map } from 'lodash'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

export const usePrescriptions = (model, id) => {
  // TODO: patient_id, pending_user_id, pharmacy_id, prescription_number
  const path = `/prescriptions?dose_id=${id}`

  const query = useQuery({
    queryKey: ['prescriptions', path],
    queryFn: () => ApiClient.get(path),
    enabled: !!id,
  })

  return useMemo(() => ({
    ...query,
    data: map(get(query, 'data.prescriptions', []), (prescription) => (prescription)),
  }), [query])
}

export default usePrescriptions
