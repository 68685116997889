import React, { memo, useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import * as Sentry from '@sentry/react'
import { Button } from 'primereact/button'
import { momentFormats } from '@services/utils/moment'
import ScheduledConsumptions from './ScheduledDoses/ScheduledConsumptions'
import { usePatientConsumptions, usePatientMARApprovals } from '../../../features/Patients/patientHooks'
import {
  getFormattedDoseConsumptions,
  unknownStatusConsumptions,
} from './adherenceDashboardUtils'
import Menu from './AdherenceMenu'
import PRNConsumption from './PrnDoses/PRNConsumption'
import MarApprovalDialog from './MARApproval/MarApprovalDialog'
import DateNavigator from '../../display/DateNavigator/DateNavigator'
import useDateNavigatorUtils from '../../display/DateNavigator/dateNavigatorUtils'

function Title({ title }) {
  return (
    <div className="flex flex-row justify-content-between align-items-center">
      <span className="text-lg">
        <i className="pi pi-list mr-2" />
        {title}
      </span>
    </div>
  )
}

function TypeNavigator({ granularity, setApprovalDialogVisible }) {
  const currentUser = useCurrentUser()
  return (
    <div className="flex flex-row gap-3 align-items-center w-full">
      <Menu />
      <div className="flex-grow-1" />
      {
    currentUser.role === 'site_admin' && granularity === 'Monthly' && (
      <Button
        label="Approve"
        className="p-button-primary"
        onClick={() => setApprovalDialogVisible(true)}
      />
    )
  }
    </div>
  )
}

const AdherenceDashboard = memo(({ patientId, title, maxDataViewHeight }) => {
  const [approvalDialogVisible, setApprovalDialogVisible] = useState(false)
  const currentOrganization = useCurrentOrganization()

  const {
    onPrevious, onNext, onGranularityChange, dateRange, setDateRange,
    granularity, nextButtonDisable, startDate, endDate, totalDays,
  } = useDateNavigatorUtils(currentOrganization)

  const {
    data: consumptions,
    isLoading: doseConsumptionsLoading,
  } = usePatientConsumptions(patientId, dateRange, { excludePRN: true })

  const {
    data: marApprovals,
    isLoading: marApprovalsLoading,
  } = usePatientMARApprovals({
    patientId,
    dayMin: startDate.format(momentFormats.date),
    dayMax: endDate.format(momentFormats.date),
  })

  const formattedConsumptions = getFormattedDoseConsumptions({
    consumptions,
    timezone: currentOrganization.timezone,
    startDate,
    totalDays,
  })

  useEffect(() => {
    const result = unknownStatusConsumptions(consumptions)
    if (result.unknownStatus.length === 0) return

    Sentry.captureMessage('Unknown status found in consumptions', {
      extra: result,
    })
  }, [patientId, consumptions.length])

  return (
    <Card className="h-full adherence-dashboard">
      <MarApprovalDialog
        visible={approvalDialogVisible}
        onHide={() => setApprovalDialogVisible(false)}
        patientId={patientId}
        currentStart={startDate}
        currentEnd={endDate}
        existingMarApprovals={marApprovals}
        timezone={currentOrganization.timezone}
      />
      <div className="flex flex-column gap-3 text-base text-900">
        <Title title={title} />
        <Divider className="my-0" />
        <DateNavigator
          dateRange={dateRange}
          onPrevious={onPrevious}
          onNext={onNext}
          granularity={granularity}
          setDateRange={setDateRange}
          marApprovals={marApprovals}
          onGranularityChange={onGranularityChange}
          nextButtonDisable={nextButtonDisable}
        />
        <Divider className="my-0" />
        <TypeNavigator
          granularity={granularity}
          setApprovalDialogVisible={setApprovalDialogVisible}
        />
        <Routes>
          <Route
            index
            element={(
              <Navigate
                to={currentOrganization.ekitsEnabled ? 'prn' : 'schedule'}
                replace
              />
            )}
          />
          <Route
            path="schedule"
            element={(
              <ScheduledConsumptions
                isLoading={doseConsumptionsLoading}
                data={formattedConsumptions}
                dateRange={dateRange}
                timezone={currentOrganization.timezone}
                patientId={patientId}
                marApprovals={marApprovals}
                isLoadingMarApprovals={marApprovalsLoading}
                startDate={startDate}
              />
            )}
          />
          <Route
            path="prn"
            element={(
              <PRNConsumption
                clientId={patientId}
                dateRange={dateRange}
                timezone={currentOrganization.timezone}
                maxDataViewHeight={maxDataViewHeight}
                marApprovals={marApprovals}
                isLoadingPrnDose={doseConsumptionsLoading}
              />
            )}
          />
        </Routes>
      </div>
    </Card>
  )
})

export default AdherenceDashboard
