import React, { useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import moment from 'moment'
import { Messages } from 'primereact/messages'
import { useCurrentOrganization } from '@components/App'
import { useExportMAR } from '../../features/Patients/patientHooks'
import { generateAllMonths, generateYears } from '../../services/utils'

function ExportPatientMARDialog({ patientId, isVisible, setIsVisible }) {
  const organization = useCurrentOrganization()
  const messages = useRef()
  const {
    mutateAsync: exportPatientMAR,
    isLoading: isExporting,
  } = useExportMAR(messages, patientId)

  const [selectedYear, setSelectedYear] = useState(moment().year())
  const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1)
  const years = generateYears(2)
  const months = generateAllMonths()

  const handleExportPatientMAR = async () => {
    exportPatientMAR({ year: selectedYear, month: selectedMonth }, {
      onSuccess: () => {
        setIsVisible(false)
      },
    })
  }

  return (
    <Dialog
      header={`Export ${organization.patientLabelSingular} MAR`}
      visible={isVisible}
      onHide={() => setIsVisible(false)}
    >
      <Messages ref={messages} />
      <div className="flex flex-column text-base font-normal mt-2">
        <div className="flex flex-row gap-2 align-items-center mb-2">
          <span className="font-normal">Export from:</span>
          <Dropdown
            value={selectedMonth}
            options={months}
            onChange={(e) => setSelectedMonth(e.value)}
            placeholder="Select a Month"
            className="flex-grow-1"
          />
          <Dropdown
            value={selectedYear}
            options={years}
            onChange={(e) => setSelectedYear(e.value)}
            placeholder="Select a Year"
            className="flex-grow-1"
          />
        </div>
        <Button
          className="p-button-sm p-button-outlined"
          label="Export"
          onClick={() => handleExportPatientMAR()}
          loading={isExporting}
        />
      </div>
    </Dialog>
  )
}

export default ExportPatientMARDialog
