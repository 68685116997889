import { useMemo } from 'react'
import { map, get } from 'lodash'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import {
  errorMessageFromError,
} from '@services/errorHandler'

export function usePermissionRequests(dateRange, statusMessage) {
  const query = useQuery({
    queryKey: ['permission_requests', dateRange],
    queryFn: () => {
      const [startAt, endAt] = dateRange
      const params = { startDate: startAt, endDate: endAt }
      return ApiClient.get('/permission_requests', { params })
    },
    onError: (error) => {
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })

  return useMemo(
    () => ({
      ...query,
      data: map(get(query, 'data'), (permissionRequests) => (permissionRequests)),
    }),
    [query],
  )
}

export function approvePermissionRequestMutation(permissionRequestId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => ApiClient.post(`/permission_requests/${permissionRequestId}/approve`),
    onSuccess: () => {
      queryClient.invalidateQueries('permission_requests')
    },
    onError: (error) => {
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })
}

export function rejectPermissionRequestMutation(permissionRequestId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => ApiClient.post(`/permission_requests/${permissionRequestId}/reject`),
    onSuccess: () => {
      queryClient.invalidateQueries('permission_requests')
    },
    onError: (error) => {
      if (!get(statusMessage, 'current')) return
      statusMessage.current.clear()
      statusMessage.current.show([
        errorMessageFromError(error),
      ])
    },
  })
}
