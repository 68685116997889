import moment from 'moment'

const formatFullDate = (date) => moment(date).format('MMMM Do, YYYY')

const defualtDateFormat = 'YYYY-MM-DD'
const defaultTimeFormat = 'h:mma'
const defaultTimeFormatWithSeconds = 'HH:mm:ss'

export {
  formatFullDate,
  defualtDateFormat,
  defaultTimeFormat,
  defaultTimeFormatWithSeconds,
}
