import React from 'react'
import PharmacyOrdersTable from '@components/pharmacyOrders/Table/PharmacyOrdersTable'
import { usePatientPharmacyOrders } from '../../../features/ClientDoses/PharmacyOrders/pharmacyOrderHooks'

function PatientPharmacyOrders({ patientId }) {
  const {
    data: pharmacyOrders,
    isLoading,
  } = usePatientPharmacyOrders(patientId)

  return (
    <PharmacyOrdersTable
      pharmacyOrders={pharmacyOrders}
      isLoading={isLoading}
    />
  )
}

export default PatientPharmacyOrders
