import React from 'react'
import { Knob } from 'primereact/knob'
import { calculateChargeCodeDisplay } from '../../features/CCM/billableSessionUtils'

function ChargeCodes({ charges, complex }) {
  const { timeSpent, thresholds } = charges
  const chargeCodes = calculateChargeCodeDisplay({ thresholds, complex })

  return chargeCodes.map((charge) => (
    <li className="flex flex-column py-1 gap-2" key={charge.chargeCode}>
      <div className="text-600 text-sm font-medium flex flex-row justify-content-between">
        CPT #
        {charge.chargeCode}
        <span className="text-xs flex flex-row align-items-center">
          {charge.billable
            && <i className="pi pi-check-square mr-2" style={{ color: '#4cd07d' }} />}
          {!charge.billable
            && <i className="pi pi-stop mr-2" />}
          Billable
        </span>
      </div>
      <div className="text-900 font-base text-sm flex flex-row align-items-center">
        <Knob
          className="mr-2"
          valueColor={charge.color}
          value={timeSpent.minutes}
          max={charge.nextThreshold || timeSpent.minutes}
          size={35}
          readOnly
        />
        {!charge.billableLimitReached
          && (
          <>
            <span className="font-semibold mr-1">{`${charge.remaining} minutes`}</span>
            <span>{charge.text}</span>
          </>
          )}
        {charge.billableLimitReached
          && <span className="mr-1">{charge.text}</span>}
      </div>
    </li>
  ))
}

export default ChargeCodes
