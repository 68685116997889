import React from 'react'
import {
  isEqual,
} from 'lodash'
import { classNames } from 'primereact/utils'
import { Checkbox } from 'primereact/checkbox'
import './style.scss'

function DoseAttributeRow({
  label,
  currentValue,
  newValue,
  action,
  backgroundHighlight,
  valueChangeHandler,
  editor,
  isEditing,
}) {
  const className = classNames('flex flex-row align-items-center px-2', {
    'surface-ground': backgroundHighlight,
    'py-2': action,
    'py-3': !action,
  })

  const doseHasDifferentValueFromOrder = newValue && !isEqual(currentValue, newValue)

  const valueContentTemplate = () => {
    if (isEditing) {
      return editor
    }

    if (doseHasDifferentValueFromOrder) {
      return (
        <div className="flex flex-column gap-3 py-2">
          <div className="flex flex-row gap-2 align-items-center">
            <Checkbox
              inputId={`currentValue:${label}`}
              name={`currentValue:${label}`}
              value={currentValue}
              readOnly
              checked
            />
            <label htmlFor={`currentValue:${label}`} className="flex flex-row gap-2 flex-grow-1 text-900 font-medium">
              <span>Dose Value:</span>
              <span>{currentValue}</span>
            </label>
          </div>
          <div className="flex flex-row gap-2 align-items-center">
            <Checkbox
              inputId={`newValue:${label}`}
              name={`newValue:${label}`}
              value={newValue}
              onChange={valueChangeHandler}
              checked={false}
            />
            <label htmlFor={`newValue:${label}`} className="flex flex-row gap-2">
              <span className="text-600">Pharmacy Order Value:</span>
              <span className="flex flex-grow-1 text-600">
                {newValue}
              </span>
            </label>
          </div>
        </div>
      )
    }

    return (
      <span className="flex flex-grow-1 text-900 font-medium">{currentValue}</span>
    )
  }

  return (
    <li className={className}>
      <span className="text-500 w-3 font-medium">{label}</span>
      <div className="flex flex-grow-1">
        {valueContentTemplate()}
      </div>
      {action && <div>{action}</div>}
    </li>
  )
}

export default DoseAttributeRow
