import React from 'react'
import { Routes, Route } from 'react-router-dom'
import PageLayout, { usePageOutlet } from '@components/display/PageLayout'
import { withApp } from '@components/App'
import UnmappedFacilitiesTable from './UnmappedFacilitiesTable'
import MapFacility from './MapFacility'

function UnmappedFacilities() {
  const useFacilityHeader = () => usePageOutlet()

  return (
    <div className="integrations">
      <Routes>
        <Route path="/admin/integrations/*" element={<PageLayout rootPath="/admin/integrations" rootLabel="Unmapped Facilities" />}>
          <Route
            index
            element={(
              <UnmappedFacilitiesTable
                useFacilityHeader={useFacilityHeader}
              />
            )}
          />
          <Route path="map/:externalId" element={<MapFacility useFacilityHeader={useFacilityHeader} />} />
        </Route>
      </Routes>
    </div>
  )
}

export default withApp(UnmappedFacilities)
