import React from 'react'
import SuperAdminCard from '@components/display/SuperAdminCard'
import DoseFillsTable from '@components/doseFills/DoseFillsTable'

function DoseFills({ doseId }) {
  return (
    <div className="col-12">
      <SuperAdminCard title="Dose Fills">
        <DoseFillsTable model="Dose" id={doseId} status="all" />
      </SuperAdminCard>
    </div>
  )
}

export default DoseFills
