import React from 'react'
import { SelectButton } from 'primereact/selectbutton'
import DateInfoView from '../Calendar/DateInfoView'

function DateNavigator({
  dateRange,
  onPrevious,
  onNext,
  granularity,
  setDateRange,
  marApprovals,
  onGranularityChange,
  nextButtonDisable,
}) {
  const options = ['Daily', 'Weekly', 'Monthly', 'Custom']

  return (
    <div className="flex flex-column gap-3 text-base text-900">
      <div className="flex flex-row justify-content-between align-items-center">
        <DateInfoView
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          marApprovals={marApprovals}
          isCustom={granularity === 'Custom'}
          onCustomStartChange={(date) => setDateRange({ ...dateRange, startDate: date })}
          onCustomEndChange={(date) => setDateRange({ ...dateRange, endDate: date })}
          onPrevious={onPrevious}
          onNext={onNext}
          isNextDisabled={
            nextButtonDisable()
          }
        />
        <SelectButton
          value={granularity}
          onChange={(e) => onGranularityChange(e)}
          options={options}
          allowEmpty={false}
        />
      </div>
    </div>
  )
}

export default DateNavigator
